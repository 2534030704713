//Restituisce il primo ruolo
const getFirstRole = (player) => {
    const listRoles = player.played_positions.split('-');
    if(listRoles.length >= 1)
        return "-" + listRoles[1] + "-";
    else    
        return "";
};

//Cerco i giocatori che hanno il ruolo role, inseendo prima quelli che hanno role come primo ruolo
const setListRoleorderes = (players,role) => {
    const firstRole = players.filter(player => getFirstRole(player) === role);
    return firstRole;
};

const setListRole = (players, role) => {   
        return players.filter(player => player.played_positions.includes(role));
};

export default (players,modulo) => {    
    //Inizializzo l'elenco per ruolo
    const listDR = setListRole(players,'-DR-');
    const listDC = setListRole(players,'-DC-');
    const listDL = setListRole(players,'-DL-');
    const listMR = setListRole(players,'-MR-');
    const listMC = setListRole(players,'-MC-');
    const listDMC = setListRole(players,'-DMC-');
    const listML = setListRole(players,'-ML-');
    const listAMR = setListRole(players,'-AMR-');
    const listAMC = setListRole(players,'-AMC-');
    const listAML = setListRole(players,'-AML-');
    const listFW = setListRole(players,'-FW-');

    //Conteggio numero giocatori per posizione
    const dr = modulo[1] > 3 ? 1 : 0;
    const dl = modulo[1] > 3 ? 1 : 0;
    const dc = modulo[1] - dr - dl;
    const cr = modulo[2] > 3 ? 1 : 0;
    const cl = modulo[2] > 3 ? 1 : 0;
    const cc = modulo[2] - cr - cl;
    const cor = modulo[3] > 2 ? 1 : 0;
    const col = modulo[3] > 2 ? 1 : 0;
    const coc = modulo[3] - cor - col;
    const att = modulo[4];

    //Selezione dei giocatori    
    let list = []; //Utilizzato per le estrazioni 
    let formazione = []; //formazione finale

    //Portiere
    const resGK = players.filter(player => player.position_text === 'Goalkeeper')[0];
    formazione = [resGK];

    //Difensori
    let resDL = dl == 0 ? [] :
        players.filter(player => !formazione.includes(player) && player.position_text === 'Defender' &&
            (player.played_positions.includes('-DL-') || player.played_positions.includes('-ML-')))
            .filter((p,index) => index < dl);      
    formazione = [...formazione,...resDL];

    let resDC = dc == 0 ? [] :
        players.filter(player => !formazione.includes(player) &&  player.position_text === 'Defender' &&
            (player.played_positions.includes('-DC-') || player.played_positions.includes('-MC-')))
            .filter((p,index) => index < dc);
    formazione = [...formazione,...resDC];

    let resDR = dr == 0 ? [] :
        players.filter(player => !formazione.includes(player) && player.position_text === 'Defender' &&
            (player.played_positions.includes('-DR-') || player.played_positions.includes('-MR-')))
            .filter((p,index) => index < dr);
    formazione = [...formazione,...resDR];

    //Centrocampisti
    let resCL = cl == 0 ? [] : 
        players.filter(player => !formazione.includes(player) && player.position_text === 'Midfielder' && 
            !player.played_positions.includes('-FW-') &&         
            (player.played_positions.includes('-DL-') || player.played_positions.includes('-ML-')))
            .filter((p,index) => index < cl);
    formazione = [...formazione,...resCL];

    let resCC = cc == 0 ? [] :
        players.filter(player => !formazione.includes(player) && player.position_text === 'Midfielder' && 
            !player.played_positions.includes('-FW-') && 
            (player.played_positions.includes('-DC-') || player.played_positions.includes('-MC-') || 
            player.played_positions.includes('-DMC-')))
            .filter((p,index) => index < cc);
    formazione = [...formazione,...resCC];

    let resCR = cr == 0 ? [] :
        players.filter(player => !formazione.includes(player) && player.position_text === 'Midfielder' &&      
            !player.played_positions.includes('-FW-') &&        
            (player.played_positions.includes('-DR-') || player.played_positions.includes('-MR-')))
            .filter((p,index) => index < cr);
    formazione = [...formazione,...resCR];

    //Trequartisti
    let resCOL = col == 0 ? []:
        players.filter(player => !formazione.includes(player) && 
            (player.played_positions.includes('-AML-') ||  
            (player.played_positions.includes('-FW-') && player.played_positions.includes('-ML-'))))
            .filter((p,index) => index < col);
    formazione = [...formazione,...resCOL];

    let resCOC = coc == 0 ? [] :
        players.filter(player => !formazione.includes(player) && 
            (player.played_positions.includes('-AMC-') || player.played_positions.includes('-AMR-') 
            || player.played_positions.includes('-AML-') || 
            (player.played_positions.includes('-FW-') && player.played_positions.includes('-MC-'))))
            .filter((p,index) => index < coc);
    formazione = [...formazione,...resCOC];

    let resCOR = cor == 0 ? []:
        players.filter(player => !formazione.includes(player) && 
            (player.played_positions.includes('-AMR-') || 
            (player.played_positions.includes('-FW-') && player.played_positions.includes('-MR-'))))
            .filter((p,index) => index < cor);
    formazione = [...formazione,...resCOR];

     //Attaccanti
    let resATT = att == 0 ? []:
        players.filter(player => !formazione.includes(player) && player.position_text === 'Forward' &&        
            (player.played_positions.includes('-FW-') || player.played_positions.includes('-AMR-') ||
            player.played_positions.includes('-AMC-') || player.played_positions.includes('-AML-') ||
            player.played_positions == ""))
            .filter((p,index) => index < att);
    formazione = [...formazione,...resATT];

    //Gestione del numero di giorcatori per ruolo
    if(resATT.length < att){
        list = [...listFW.filter(player => !formazione.includes(player) && !resATT.includes(player)),
            ...listAMC.filter(player => !formazione.includes(player) && !resATT.includes(player)),
            ...listAML.filter(player => !formazione.includes(player) && !resATT.includes(player)),
            ...listAMR.filter(player => !formazione.includes(player) && !resATT.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resATT.includes(player) && player.position_text === 'Forward')
        ];
        resATT = [...resATT,...list.filter((p,index) => index < att - resATT.length)];
        formazione = [...formazione,...resATT];
    }    

    if(resCOL.length < col){
        list = [...listAML.filter(player => !formazione.includes(player) && !resCOL.includes(player)),
            ...listML.filter(player => !formazione.includes(player) && !resCOL.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resCOL.includes(player) && player.position_text === 'Forward')
        ];
        resCOL = [...resCOL,...list.filter((p,index) => index < col - resCOL.length)];
        formazione = [...formazione,...resCOL];
    }

    if(resCOC.length < coc){
        list = [...listAMC.filter(player => !formazione.includes(player) && !resCOC.includes(player)),
            ...listMC.filter(player => !formazione.includes(player) && !resCOC.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resCOC.includes(player) && player.position_text === 'Forward')
        ];
        resCOC = [...resCOC,...list.filter((p,index) => index < coc - resCOC.length)];
        formazione = [...formazione,...resCOC];
    }

    if(resCOR.length < cor){
        list = [...listAMR.filter(player => !formazione.includes(player) && !resCOR.includes(player)),
            ...listAML.filter(player => !formazione.includes(player) && !resCOR.includes(player)),
            ...listMR.filter(player => !formazione.includes(player) && !resCOR.includes(player)),
            ...listML.filter(player => !formazione.includes(player) && !resCOR.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resCOR.includes(player) && player.position_text === 'Forward')
        ];
        resCOR = [...resCOR,...list.filter((p,index) => index < cor - resCOR.length)];
        formazione = [...formazione,...resCOR];
    }
    
    if(resCL.length < cl){
        list = [...listML.filter(player => !formazione.includes(player) && !resCL.includes(player)),            
            ...listDL.filter(player => !formazione.includes(player) && !resCL.includes(player)),
            ...listAML.filter(player => !formazione.includes(player) && !resCL.includes(player)),
            ...listDMC.filter(player => !formazione.includes(player) && !resCL.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resCL.includes(player) && player.position_text === 'Midfielder')
        ];
        resCL = [...resCL,...list.filter((p,index) => index < cl - resCL.length)];
        formazione = [...formazione,...resCL];
    }

    if(resCC.length < cc){
        list = [...listMC.filter(player => !formazione.includes(player) && !resCC.includes(player)),
            ...listAMC.filter(player => !formazione.includes(player) && !resCC.includes(player)),
            ...listDMC.filter(player => !formazione.includes(player) && !resCC.includes(player)),    
            ...listAML.filter(player => !formazione.includes(player) && !resCC.includes(player)),                
            ...players.filter(player => !formazione.includes(player) && !resCC.includes(player) && player.position_text === 'Midfielder')
        ];
        resCC = [...resCC,...list.filter((p,index) => index < cc - resCC.length)];
        formazione = [...formazione,...resCC];
    }

    if(resCR.length < cr){
        list = [...listMR.filter(player => !formazione.includes(player) && !resCR.includes(player)),
            ...listDR.filter(player => !formazione.includes(player) && !resCR.includes(player)),
            ...listAMR.filter(player => !formazione.includes(player) && !resCR.includes(player)),
            ...listML.filter(player => !formazione.includes(player) && !resCR.includes(player)),
            ...listDMC.filter(player => !formazione.includes(player) && !resCR.includes(player)),
            ...listAML.filter(player => !formazione.includes(player) && !resCR.includes(player)),
            ...listAMC.filter(player => !formazione.includes(player) && !resCR.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resCR.includes(player) && player.position_text === 'Midfielder')
        ];
        resCR = [...resCR,...list.filter((p,index) => index < cr - resCR.length)];
        formazione = [...formazione,...resCR];
    }

    if(resDL.length < dl){
        list = [...listDL.filter(player => !formazione.includes(player) && !resDL.includes(player)),
            ...listML.filter(player => !formazione.includes(player) && !resDL.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resDL.includes(player) && player.position_text === 'Defender')
        ];
        resDL = [...resDL,...list.filter((p,index) => index < dl - resDL.length)];
        formazione = [...formazione,...resDL];
    }

    if(resDC.length < dc){
        list = [...listDC.filter(player => !formazione.includes(player) && !resDC.includes(player)),
            ...listDMC.filter(player => !formazione.includes(player) && !resDC.includes(player)),
            ...listDL.filter(player => !formazione.includes(player) && !resDC.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resDC.includes(player) && player.position_text === 'Defender')
        ];
        resDC = [...resDC,...list.filter((p,index) => index < dc - resDC.length)];
        formazione = [...formazione,...resDC];
    }

    if(resDR.length < dr){
        list = [...listDR.filter(player => !formazione.includes(player) && !resDR.includes(player)),
            ...listMR.filter(player => !formazione.includes(player) && !resDR.includes(player)),
            ...listDL.filter(player => !formazione.includes(player) && !resDR.includes(player)),
            ...listDC.filter(player => !formazione.includes(player) && !resDR.includes(player)),
            ...listDMC.filter(player => !formazione.includes(player) && !resDR.includes(player)),
            ...players.filter(player => !formazione.includes(player) && !resDR.includes(player) && player.position_text === 'Defender')
        ];
        resDR = [...resDR,...list.filter((p,index) => index < dr - resDR.length)];        
    }
    
    //Se ci sono 3 difensori centrali posiziono in centro l'eventuale giocatore che è solo difensore centrale
    if(resDC.length > 2){
        list = resDC.filter(player => player.played_positions === '-DC-');
        //Caso in cui c'è almeno un centrocampista centrale puro
        if(list.length > 0){
            const dcc = list[0];
            const dcx = resDC.filter(player => player.player_id !== dcc.player_id);
            resDC = [dcx[0],dcc,dcx[1]];        
        }
    }

    //Se ci sono 3 centrocampisti centrali posiziono in centro l'eventuale giocatore che è solo centrocampista centrale
    if(resCC.length > 2){
        list = resCC.filter(player => player.played_positions === '-MC-');
        //Caso in cui c'è almeno un centrocampista centrale puro
        if(list.length > 0){
            const ccc = list[0];
            const ccx = resCC.filter(player => player.player_id !== ccc.player_id);
            resCC = [ccx[0],ccc,ccx[1]];
        }
    }
    
    //se ci sono più attaccanti riordino le posizioni
    if(att > 1){
        const attLeft = resATT.filter(player => player.played_positions.includes('-AML-'));
        const attCenter = resATT.filter(player => !attLeft.includes(player) && (
            player.played_positions.includes('-AMC-') || player.played_positions.includes('-FW-')));
        const attRight = resATT.filter(player => !attLeft.includes(player) && !attCenter.includes(player) && 
            player.played_positions.includes('-AMR-'));
        resATT = [...attLeft,...attCenter,...attRight,
            ...resATT.filter(player => !attLeft.includes(player) && !attCenter.includes(player) && !attRight.includes(player))];
    }

    //Se ci sono 3 attaccanti posiziono in centro l'eventuale attaccante  che è solo attaccante centrale
    if(resATT.length > 2){
        list = resATT.filter(player => player.played_positions === '-FW-');
        //Caso in cui c'è almeno un centrocampista centrale puro
        if(list.length > 0){
            const acc = list[0];
            const acx = resATT.filter(player => player.player_id !== acc.player_id);
            resATT = [acx[0],acc,acx[1]];
        }
    }
    
    //Ricreo la formazione con l'ordine giusto dei ruoli
    formazione = [];
    formazione = [resGK,...resDL,...resDC,...resDR,...resCL,...resCC,...resCR,...resCOL,...resCOC,...resCOR,...resATT];

    return formazione;
};