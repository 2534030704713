import React, {useEffect, useState, useMemo, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { Avatar, Stack, Skeleton, CircularProgress, Box } from '@mui/material';

const SkeletonProfile = props => {
    const theme = useTheme();

    return(
        <Stack
            direction="column"
            justifyContent="center" 
            alignItems="center" 
            spacing={0}
            sx={{width:'100%'}}
        >
            {/* Nome e ruolo */}
            <Box
                sx={{
                    width:'100%',
                    height:'37px',
                    backgroundColor:theme.palette.custom.black,                    
                    padding:{xs:'5px 10px',md:'5px 0 5px 190px',sm:'5px 40px'}
                }}
            >
            </Box>
            {/* Foto, Info, Team e Valore */}
            <Stack 
                direction='row'
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{
                    width:'100%',
                    padding:{xs:'10px 10px 10px 10px',md:'10px 190px',sm:'10px 40px'}
                }}
            >
                <Avatar sx={{backgroundColor:theme.palette.custom.grey,width:'110px',height:'110px'}} />
                <Box sx={{width:'70%',textAlign:'center'}}>
                    <CircularProgress size={130} sx={{color:theme.palette.primary.main}} />
                </Box>
            </Stack>
        </Stack>
    );
};

export default SkeletonProfile;