import React, {useEffect, useState} from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import {Stack, Box, Typography, Button, Popover, Avatar} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ClearIcon from '@mui/icons-material/Clear';

const MessageLogin = props => {
    const { data, color, methodClose } = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const message = parse(t("messages.viewdata",{data: data}));   
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);
    
    useEffect(() => {
        setAnchorEl(null);
    }, []);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if(methodClose)
            methodClose();
    };
    
    
    return(
        <Stack 
            direction="column"
            sx={{
                background:'#f4f4f4',
                padding:'5px 10px',
                borderRadius:'20px',
                maxWidth:'fit-content',
                margin:'10px 0 0 0 !important',
                border:'1px solid #000'
            }}
        >            
            <Typography 
                variant='textRound' 
                onClick={handleOpen}
                sx={{
                    color: color ? color : '#000',
                    fontSize:{xs:'14px',md:'13px',sm:'14px'},
                    cursor:'pointer'
                }}
                >
                {message}
            </Typography>
            <Popover                
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={0.5}
                    sx={{padding:'10px',maxWidth:'380px'}}
                >                    
                    <Typography
                        variant='textRound'
                        sx={{fontWeight:'600',fontSize:{xs:'18px',md:'18px',sm:'18px'}}}
                    >
                        {parse(t("messages.infovalueview"))}
                    </Typography>
                    <Stack 
                        direction="row" 
                        justifyContent="flex-start" 
                        alignItems="center" 
                        spacing={1.5}
                        sx={{marginTop:'10px !important'}}
                    >
                        <Avatar><PersonIcon /></Avatar>
                        <Typography
                            variant='textRound'
                            sx={{fontWeight:'400',fontSize:{xs:'16px',md:'16px',sm:'16px'}}}
                        >
                            {parse(t("messages.infovalueviewhow"))}
                        </Typography>
                    </Stack>                    
                    <Stack 
                        direction="row" 
                        justifyContent="flex-start" 
                        alignItems="center" 
                        spacing={4}
                        sx={{marginTop:'20px !important',width:'100%'}}
                    >
                        <Box sx={{width:'50% !important',textAlign:'center'}}>
                            <Button 
                                variant='textAction' 
                                onClick={handleClose} 
                                href='#top'
                                startIcon={<PersonIcon sx={{color:theme.palette.primary.main}} />}
                                sx={{color:theme.palette.primary.main}}
                            >
                                {parse(t("messages.gotousermenu"))}
                            </Button>
                        </Box>
                        <Box sx={{textAlign:'center'}}>
                            <Button 
                                variant='textAction' 
                                onClick={handleClose}
                                startIcon={<ClearIcon />}
                            >
                                {parse(t("messages.close"))}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </Popover>     
        </Stack>
    );
};

export default MessageLogin;
