import React, {useState} from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { FormControl,InputLabel,MenuItem,Chip,Select,OutlinedInput,Box,Stack,Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';

const SelectTournament = props => {
    const {teams,selectedTournament,handleFilterClose,applyFilter} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const listTournament =  teams && teams.length > 0 ? 
        [...new Set(teams.map((team,index) => team.tournament_name))].sort() : [];
    const [listTournamentSelected,setListTournamentSelected] = useState(selectedTournament ? selectedTournament : []);
    
    const handleChangeTournament = (event) => {
        const {target: {value}} = event;
        setListTournamentSelected(typeof value === 'string' ? value.split(',') : value);
    };

    const handleFilter = (event) => {       
        applyFilter(listTournamentSelected);
    };

    return(
        <Stack 
            direction="column" 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={1}
            sx={{padding:'20px'}}
        >
            <FormControl 
                sx={{
                    width:300,
                    "& .MuiFormLabel-root": {
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        color: theme.palette.custom.blueIcon + " !important"
                    },
                    "& .css-86q9nw-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        borderColor: theme.palette.custom.blueIcon + " !important"
                    }
                }}
            >
                <InputLabel id="players-find-tournament">{t("global.torneo")}</InputLabel>
                <Select
                    labelId='players-find-tournament'
                    id='players-find-select-tournament'                                       
                    value={listTournamentSelected}
                    onChange={handleChangeTournament}
                    input={<OutlinedInput id="select-find-tournament" label={t("global.torneo")} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip 
                                key={value} 
                                label={value} 
                                sx={{
                                    fontFamily: ['"Poppins", sans-serif'].join(","),
                                    background:'none',
                                    color:theme.palette.custom.blueIcon,
                                    fontSize:'16px'
                                }} 
                            />
                        ))}
                        </Box>
                    )} 
                >                    
                    {listTournament.map((tournament) => (
                        <MenuItem key={tournament} value={tournament} sx={{fontFamily: ['"Poppins", sans-serif'].join(",")}}>
                            {tournament}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Stack 
                direction="row" 
                justifyContent="center" 
                alignItems="center" 
                spacing={7}
                sx={{width:'100%',marginTop:'20px !important'}}
            >                                                
                <Button 
                    variant='action' 
                    onClick={handleFilter}
                    endIcon={
                        <TuneIcon 
                            sx={{
                                color:listTournamentSelected.length == 0 ? 
                                    theme.palette.custom.gery : theme.palette.custom.white                                
                            }} 
                        />
                    }
                    disabled={listTournamentSelected.length == 0 }
                    sx={{
                        backgroundColor:listTournamentSelected.length == 0 ?
                            theme.palette.custom.grey : theme.palette.custom.yellowDark
                    }}
                >
                    {t("global.apply")}
                </Button>
                <Button 
                    variant='secondary' 
                    onClick={handleFilterClose}
                    endIcon={<CloseIcon sx={{color:theme.palette.custom.orange}} />}
                >
                    {t("global.close")}
                </Button>
            </Stack>
        </Stack>
    )
};

export default SelectTournament;