import React, {useEffect, useState, useMemo, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Link, Box, Typography, Stack, Avatar, Tooltip, Button, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import getRuolo from '../../components/PlayerRole/getRuolo';
import getColor from '../../components/PlayerRole/getColor';
import kriptPlayerId from '../Utility/kriptPlayerId';
import kriptTeamId from '../Utility/kriptTeamId';

const PLayerBox = props => {
    const {player} = props;
    const {t, i18n} = useTranslation('common');
    const theme = useTheme();    
    const user = useStoreState(state => state.user.user);    
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const playerName = player.first_name + " " + player.last_name;    
    const playerUrl = '/player/' + kriptPlayerId(player.player_id) + '/' + playerName;
    const teamUrl = '/team/' + kriptTeamId(player.team_id) + '/' + player.team_name;
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/" + player.player_id + ".jpg";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/noImg.jpg";
    const teamImgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/" + player.team_id + ".png";
    const teamNoImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/noImg.png";
    const ruolo = getRuolo(player);
    const color = getColor(ruolo);
    const ruoloLetter = t("roles." + ruolo).substring(0,1);
    
    return(
        <Stack 
            direction='column'
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={0}
            sx={{
                width:'100%',
                maxWidth:'450px',
                padding:{xs:'10px 8px',md:'10px',sm:'10px'},
                borderWidth:'1px',
                borderStyle:'solid',
                borderColor:theme.palette.custom.grey,
                borderRadius:'10px',
                backgroundColor:theme.palette.custom.white,            
            }}
        >
            <Stack 
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                sx={{width:'100%'}}
            >
                {/* Foto */}           
                <Stack
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="flex-end"
                    spacing={0}
                    sx={{}}
                >
                    <Tooltip title={t("global.gotocard")}>
                        <Box
                            component={Link}
                            href={playerUrl}
                            sx={{
                                borderStyle:'solid',
                                borderWidth:'2px',
                                borderColor:color,
                                display:'block',                            
                                borderRadius:'100%',
                                padding:'3px'                            
                            }}
                        >                    
                            <Avatar 
                                alt={playerName} 
                                src={imgPath}
                                onError={(e)=>{e.target.onError=null; e.target.src=noImg}}                            
                                sx={{
                                    borderStyle:'solid',
                                    borderWidth:'3px',
                                    borderColor:color,
                                    width:{xs:'70px',md:'70px',sm:'70px'},
                                    height:{xs:'70px',md:'70px',sm:'70px'}
                                }}
                            />                   
                        </Box>
                    </Tooltip>
                    <Tooltip title={t('roles.' + ruolo)}>
                        <Avatar 
                            aria-label={playerName}
                            sx={{backgroundColor:color,marginTop:'-30px',width:'30px',height:'30px'}} 
                        >
                            {ruoloLetter.substring(0,1)}
                        </Avatar>
                    </Tooltip>
                </Stack>

                {/* Nome, team, ruolo */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                    sx={{
                        width:'100%',                        
                        padding:'0 5px 0 8px'
                    }}
                >
                    <Tooltip title={t("global.gotocard")}>
                        <Link href={playerUrl} sx={{textDecoration:'none'}}>
                            <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                        >
                                <Typography
                                    variant='playerName'
                                    sx={{
                                        fontWeight:'400',
                                        fontSize:{xs:'18px',md:'18px',sm:'20px'}
                                    }}
                                >
                                    {player.first_name}
                                </Typography>
                                <Typography
                                    variant='playerName'
                                    sx={{
                                        fontWeight:'600',
                                        fontSize:{xs:'18px',md:'22px',sm:'20px'}
                                    }}
                                >
                                    {player.last_name}
                                </Typography>
                            </Stack>
                        </Link>    
                    </Tooltip>               
                    {/* Team */}
                    <Tooltip title={t("global.gototeamcard")}>
                        <Link href={teamUrl} sx={{textDecoration:'none'}}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>                        
                                <Avatar 
                                    alt={player.team_name} 
                                    src={teamImgPath}
                                    onError={(e)=>{e.target.onError=null; e.target.src=teamNoImg}}
                                    sx={{
                                        borderStyle:'solid',
                                        borderWidth:'0px',
                                        borderColor:theme.palette.custom.white,
                                        width:{xs:'26px',md:'26px',sm:'26px'},
                                        height:{xs:'26px',md:'26px',sm:'26px'}
                                    }}
                                />
                                <Typography 
                                    variant='playerName'
                                    sx={{
                                        fontWeight:'600',
                                        fontSize:{xs:'16px',md:'16px',sm:'16px'}
                                    }}
                                >
                                    {player.team_name}
                                </Typography>                        
                            </Stack>
                        </Link>
                    </Tooltip>
                </Stack>

                {/* Valore */}
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    sx={{
                        minWidth:'50px'
                    }}
                >
                    <Typography
                        variant='playerAvatarText'
                        sx={{
                            fontWeight:'600',
                            fontSize:{xs:'14px',sm:'16px',sm:'14px'},
                            marginTop:'-3px'
                        }}
                    >
                        {t('global.value')}
                    </Typography>
                    {userLogged && (
                    <Typography
                        variant='playerAvatarText'
                        sx={{
                            fontWeight:'600',
                            fontSize:{xs:'20px',md:'22px',sm:'20px'},
                            marginTop:'-3px',
                            color:theme.palette.custom.blueIcon
                        }}
                    >
                        {player.player_value}
                    </Typography> 
                    )}
                    {!userLogged && (
                        <Tooltip title={t("messages.infovalueview")}>
                            <HelpIcon sx={{color:theme.palette.primary.main,fontSize:'36px',marginTop:'-5px'}} />
                        </Tooltip>
                    )}
                    <Typography
                        variant='playerAvatarText'
                        sx={{
                            fontWeight:'600',
                            fontSize:{xs:'14px',sm:'16px',sm:'14px'},
                            marginTop:'-1px'
                        }}
                    >
                        Mio €
                    </Typography>
                </Stack>

                {/* IconButton */}
                <Tooltip title={t("global.gotocard")}>
                    <IconButton
                        href={playerUrl}
                    >
                        <DoubleArrowIcon sx={{color:theme.palette.primary.main, fontSize:'24px'}} />
                    </IconButton>
                </Tooltip>
            </Stack>            
        </Stack>    
    );
};

export default PLayerBox;