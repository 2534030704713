import utils from "../utils";

export default email => {

    const requestOptions = {
        method: 'POST',
        body:  JSON.stringify(email),
        headers: utils.createHeaders()
    };

    return utils.fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/recoverPassword`, requestOptions)
        .then(response => response.json());    
};
