import React from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Stack, Box } from '@mui/material';
import PlayerPin from '../PlayerPin/index.js';
import getRuolo from '../PlayerRole/getRuolo.js';
import getColor from '../PlayerRole/getColor.js';
import './styles.css';

const FieldVeritcalHigh = props => {
    const {players, modulo} = props;
    const theme = useTheme();
    const {t} = useTranslation('common');

    const fieldImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/field_veritcal.png";
    const playerGk = players.filter((player,index) => index >= 0 && index < modulo[0]);
    const playerDef = players.filter((player,index) => index >= modulo[0] 
        && index < modulo[0] + modulo[1]);
    const playerCc = players.filter((player,index) => index >= modulo[0] + modulo[1] 
        && index < modulo[0] + modulo[1] + modulo[2]);
    const playerCo =  players.filter((player,index) => index >= modulo[0] + modulo[1] + modulo[2] 
        && index < modulo[0] + modulo[1] + modulo[2] + modulo[3]);
    const playerAtt = players.filter((player,index) => index >= modulo[0] + modulo[1] + modulo[2] + modulo[3]
        && index < modulo[0] + modulo[1] + modulo[2] + modulo[3] + modulo[4]);

    const heightAt = playerCo.length > 0 ? '120px' : '160px';
    const heightCo = playerCo.length > 0 ? '100px' : '35px';
    const heightCc = playerCo.length > 0 ? '140px' : '140px';
    const heightDe = playerCo.length > 0 ? '150px' : '190px';
    const heightGk = playerCo.length > 0 ? '100px' : '60px';

    const heightAtSm = playerCo.length > 0 ? '110px' : '160px';
    const heightCoSm = playerCo.length > 0 ? '90px' : '20px';
    const heightCcSm = playerCo.length > 0 ? '150px' : '160px';
    const heightDeSm = playerCo.length > 0 ? '180px' : '180px';
    const heigthGkSm = playerCo.length > 0 ? '60px' : '60px';

    const heightAtXs = playerCo.length > 0 ? '120px' : '160px';
    const heightCoXs = playerCo.length > 0 ? '80px' : '15px';
    const heightCcXs = playerCo.length > 0 ? '150px' : '160px';
    const heightDeXs = playerCo.length > 0 ? '160px' : '140px';
    const heigthGkXs = playerCo.length > 0 ? '40px' : '100px';

    return(        
        <Box 
            className='bgField'
            sx={{
                backgroundImage: `url(${fieldImg})`,
                width:'100%',
                height:{xs:'600px',md:'600px',sm:'600px'},               
            }} 
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                sx={{height:{xs:heightAtXs,md:heightAt,sm:heightAtSm}}}
            >
                {playerAtt.map((player) => (
                    <PlayerPin                         
                        key={player.player_id} 
                        player={player} 
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Forward").substring(0,1)}
                        avatarType="bigAvatar"
                    />                        
                ))}
            </Stack>                
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{xs:3,md:3,sm:2}}
                sx={{height:{xs:`${heightCoXs}`,md:heightCo,sm:heightCoSm}}}
            >
                {playerCo.map((player) => (
                    <PlayerPin 
                        key={player.player_id} 
                        player={player}
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Attackingmidfielder").substring(0,1)}
                        avatarType="bigAvatar"
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{
                    xs:playerCc.length > 3 ? 1 : 3,
                    md:playerCc.length > 3 ? 2 : 3,
                    sm:playerCc.length > 3 ? 2 : 3
                }}
                sx={{height:{xs:`${heightCcXs}`,md:heightCc,sm:heightCcSm}}}
            >
                {playerCc.map((player) => (
                    <PlayerPin
                        key={player.player_id} 
                        player={player}
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Midfielder").substring(0,1)}
                        avatarType="bigAvatar"
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{
                    xs:playerDef.length > 4 ? 2 : 3,
                    md:playerDef.length > 4 ? 2 : 4,
                    sm:playerDef.length > 4 ? 2 : 4
                }}
                sx={{height:{xs:`${heightDeXs}`,md:heightDe,sm:heightDeSm}}}
            >
                {playerDef.map((player) => (
                    <PlayerPin 
                        key={player.player_id} 
                        player={player} 
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Defender").substring(0,1)}
                        avatarType="bigAvatar"
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{height:{xs:heigthGkXs,md:heightGk,sm:heigthGkSm}}}
            >
                {playerGk.map((player) => (
                    <PlayerPin 
                        key={player.player_id} 
                        player={player} 
                        color={theme.palette.custom.gk}
                        letter={t("roles.Goalkeeper").substring(0,1)}
                        avatarType="bigAvatar"
                    />
                ))}
            </Stack>
        </Box>        
    );
};

export default FieldVeritcalHigh;
