import utils from "../utils";

export default ({token,sub,userInfo}) => {
    const headers = {
        'Content-Type': 'application/json',        
        Authorization: `Bearer ${token}`
    };
    const requestOptions = {
		method: 'PATCH',
		headers: headers,
		body: JSON.stringify(userInfo)		
	};
    return fetch(`${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users/${sub}`,requestOptions).then(response =>{
        if (response.status < 200 || response.status > 300) {       
            return Promise.reject(utils.createError(response));
        }
        return response.json();
    });
};