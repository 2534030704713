import { useEffect } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, CircularProgress } from "@mui/material";

const Authorize = props => {    
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');    
    const user = useStoreState(state => state.user.user);
    const lastToken = useStoreState(state => state.auth.lastToken);
    const requestToken = useStoreActions(dispatch => dispatch.auth.requestToken);    
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const redirect_wrong =  process.env.REACT_APP_PUBLIC_URL;
    const redirect_user_nologged = process.env.REACT_APP_PUBLIC_URL + '/emailverify?success=&code=';
    const redirect_user_logged = process.env.REACT_APP_PUBLIC_URL + '/account';
    
    const goToPage = _ => {
        window.location.href = redirect_user_logged;
    };

    useEffect(() => {
        const localState = window.localStorage.getItem('state');
        //Se lo state non corrisponde a quello ritornato redirigo all'home
        if(localState != state)
            window.location.href = redirect_wrong;        
        //Recupero l'access token (se non è già presente)
        else if(!user.token || user.token == '')
            requestToken(code);
        else if(user.token && user.token != '' && user.idToken && user.idToken != '')
            window.location.href = redirect_user_logged;
    },[]);

    useEffect(() => {
        //Se ho ottenuto il token redirigo l'utente (lastToken serve per essere sicuri che è stato salvato il token)
        if(user.token && user.token != '' && user.token == lastToken){
            //Attendo un seconso prima di redirigere per permettere il salvataggio in cache
            setTimeout(() => {
                goToPage();    
            }, 1000);
            
        }
    },[user,lastToken]);

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            sx={{width:'100%',minHeight:'100vh'}}
        >
            <CircularProgress sx={{color:theme.palette.primary.main}} size="80px" />
        </Stack>
    );
};

export default Authorize;