import kriptKyes from "./kriptKyes";

export default playerId => {
    //Chiave di criptazione
    const key = Math.floor(Math.random() * 8) + 1;
    //Id trasformato in array
    const idArray = playerId.toString().split('');
    //Cripto il playerId
    const kriptIdArray = idArray.map((n,index) => kriptKyes[Number(n) + key]);
    kriptIdArray.splice(2,0,key.toString());
    const kriptArray = kriptIdArray.toString().replaceAll(',','');

    return kriptArray;
};