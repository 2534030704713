import utils from "../utils";

export default token => {    
    const headers = {
        'Content-Type': 'application/json',        
        Authorization: `Bearer ${token}`
    };
    const requestOptions = {
		method: 'GET',
		headers: headers
    }

    return fetch(`${process.env.REACT_APP_AUTH_DOMAIN}/userinfo`,requestOptions).then(response =>{
        if (response.status < 200 || response.status > 300) {
       //     window.location.href = process.env.REACT_APP_PUBLIC_URL;
            return Promise.reject(utils.createError(response));
        }
        return response.json();
    });
};