import utils from "../utils";

export default ({token,userId}) => {    
    const headers = {
        'Content-Type': 'application/json',        
        Authorization: `Bearer ${token}`
    };    
    const urlencoded = new URLSearchParams({        
        user_id: userId
    });
    const requestOptions = {
		method: 'POST',
		headers: headers,
		body: JSON.stringify({user_id:userId})		
	};

    return fetch(`${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/jobs/verification-email`,requestOptions).then(response =>{
        if (response.status < 200 || response.status > 300) {       
            return Promise.reject(utils.createError(response));
        }
        return response.json();
    });
};