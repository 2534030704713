import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { FormControl,InputLabel,MenuItem,Chip,Select,OutlinedInput,Box,Stack,Button} from '@mui/material';

const SelectTeam = props => {
    const {listTournament} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const playerSummary = useStoreState(state => state.players.playerSummary.players);         
    const selectedTournament = useStoreState(state => state.players.findPlayer.selectedTournament);
    const setSelctedTournament = useStoreActions(dispatch => dispatch.players.findPlayer.setSelctedTournament);
    const selectedTeam = useStoreState(state => state.players.findPlayer.selectedTeam);
    const setSelectedTeam = useStoreActions(dispatch => dispatch.players.findPlayer.setSelectedTeam);
    const [listTeam,setListTeam] = useState([]);    
    const [disabledTeam,setDisabledTeam] = useState(!selectedTournament);

    //Se c'è un torneo selezionato imposto la lista delle squadre
    useEffect(() => {
        if(selectedTournament.length > 0)
            setListTeam(
                [...new Set(
                    playerSummary.filter((player) => player.tournament_name === selectedTournament[0]).
                        map((player,index) => player.team_name)
                )].sort()
            );
    },[]);

    const handleChangeTournament = (event) => {
        const {target: {value}} = event;
        setSelectedTeam([])
        setDisabledTeam(!value || value == '');
        setSelctedTournament(typeof value === 'string' && value != '' ? value.split(',') : value);
        setListTeam(
            [...new Set(
                playerSummary.filter((player) => value.includes(player.tournament_name)).map((player,index) => player.team_name)
            )].sort()
        );
    };

    const handleChangeTeam = (event) => {
        const {target: {value}} = event;
        setSelectedTeam(typeof value === 'string' && value != '' ? value.split(',') : value);
    };

    return(
        <Stack 
            direction="column" 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={1}
            sx={{padding:'15px 0 0 0'}}
        >
            <FormControl 
                sx={{
                    width:{xs:300,md:300,sm:220,lg:300},
                    "& .MuiFormLabel-root": {
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        color: theme.palette.custom.blueIcon + " !important"
                    },
                    "& .css-86q9nw-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        borderColor: theme.palette.custom.blueIcon + " !important"
                    }
                }}
            >
                <InputLabel id="players-find-tournament">{t("global.torneo")}</InputLabel>
                <Select
                    labelId='players-find-tournament'
                    id='players-find-select-tournament'                           
                    value={selectedTournament}
                    onChange={handleChangeTournament}
                    input={<OutlinedInput id="select-find-tournament" label={t("global.torneo")} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip 
                                key={value} 
                                label={value} 
                                sx={{
                                    fontFamily: ['"Poppins", sans-serif'].join(","),
                                    background:'none',
                                    color:theme.palette.custom.blueIcon,
                                    fontSize:'16px'
                                }} 
                            />
                        ))}
                        </Box>
                    )} 
                >
                    <MenuItem key={0} value={''} sx={{fontFamily: ['"Poppins", sans-serif'].join(","),fontStyle:'italic'}}>
                        {t('global.tutti')}
                    </MenuItem>
                    {listTournament.map((tournament) => (
                        <MenuItem key={tournament} value={tournament} sx={{fontFamily: ['"Poppins", sans-serif'].join(",")}}>
                            {tournament}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl 
                sx={{
                    width:{xs:300,md:300,sm:220,lg:300},
                    "& .MuiFormLabel-root": {
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        color: disabledTeam ? 
                            theme.palette.custom.grey + " !important" : theme.palette.custom.blueIcon + " !important"
                    },
                    "& .css-86q9nw-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        borderColor: theme.palette.custom.blueIcon + " !important"
                    }
                }}
            >
                <InputLabel id="players-find-team">{t("global.team")}</InputLabel>
                <Select
                    labelId='players-find-team'
                    id='player-find-select-team'       
                    disabled={disabledTeam}             
                    value={selectedTeam}
                    onChange={handleChangeTeam}
                    input={<OutlinedInput id="select-find-team" label={t("global.team")} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip 
                                key={value} 
                                label={value} 
                                sx={{
                                    fontFamily: ['"Poppins", sans-serif'].join(","),
                                    background:'none',
                                    color:theme.palette.custom.blueIcon,
                                    fontSize:'16px'
                                }} 
                            />
                        ))}
                        </Box>
                    )} 
                >       
                    {selectedTournament.length > 0 && (
                        <MenuItem key={0} value={''} sx={{fontFamily: ['"Poppins", sans-serif'].join(","),fontStyle:'italic'}}>
                            {t('global.tutte')}
                        </MenuItem>
                    )}
                    {listTeam.map((team) => (
                        <MenuItem key={team} value={team} sx={{fontFamily: ['"Poppins", sans-serif'].join(",")}}>{team}</MenuItem>
                    ))}
                </Select>
            </FormControl>            
        </Stack>
    );
};

export default SelectTeam;