import login from './login';
import register from './register';
import confirmEmail from './confirmEmail';
import resendEmailConfirm from './resendEmailConfirm';
import recoverPassword from './recoverPassword';
import changePassword from './changePassword';
import updateUser from './updateUser';
import requestToken from './requestToken';
import refreshToken from './refreshToken';
import userinfo from './userinfo';
import requestManagementToken from './requestManagementToken';
import sendEmailVerifiy from './sendEmailVerifiy';
import userinfoUpdate from './userinfoUpdate';

export default {
    login,
    register,
    confirmEmail,
    resendEmailConfirm,
    recoverPassword,
    changePassword,
    updateUser,
    requestToken,
    refreshToken,
    userinfo,
    requestManagementToken,
    sendEmailVerifiy,
    userinfoUpdate
};