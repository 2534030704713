import React, {useEffect, useState, useMemo, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Box, Typography, Button, IconButton, Avatar, Link, Toolbar, Tooltip } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import PLayerBox from '../../components/PlayerBox';
import kriptPlayerId from '../../components/Utility/kriptPlayerId';
import kriptTeamId from '../../components/Utility/kriptTeamId';

const HeroSection = props => {
    const {playerList, teamList} = props;
    const {t, i18n} = useTranslation('common');
    const theme = useTheme();
    const bgImg1 = process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar_hero_new.webp";
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/noImg.jpg";
    const teamImgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/";
    const teamNoImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/noImg.png";

    const inBox = (value,index,array) => {       
        return index >= 0 && index <= 2;
    }

    const inMix = (value,index,array) => {       
        return index >= 3 && index <= 7;
    }

    const playersBox = playerList.filter(inBox); 
    const playersMix = playerList.filter(inMix);


    const handleClick = event => {
        window.location.href = '/players';
    };

    return (
        <Stack 
            direction="column" 
            justifyContent="center" 
            alignItems="center" 
            spacing={0} 
            sx={{
                width:'100%',
                padding:{xs:'0 0px',md:'0 0px',sm:'0 0px'}        
            }}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}                
                sx={{
                    backgroundImage: `url(${bgImg1})`,
                    backgroundSize:'cover',
                    backgroundAttachment:{xs:'inherit',md:'fixed',sm:'inherit'},
                    backgroundPosition:{xs:'top',md:'top',sm:'top'},
                    backgroundRepeat:'no-repeat',                    
                    width:'100%',
                    height:{xs:'300px',md:'550px',sm:'350px'}
                }}
            >
                
            </Stack>
            {/* Cerchio */}
            <Box 
                sx={{
                    margin:'0 auto !important',
                    width:{xs:'100px',md:'140px',sm:'100px'},
                    height:{xs:'100px',md:'140px',sm:'100px'},
                    backgroundColor:theme.palette.custom.body,
                    borderRadius:'100%',
                    padding:{xs:'10px !important',md:'10px !important',sm:'10px !important'},
                    marginTop:{xs:'-40px !important',md:'-70px !important',sm:'-40px !important'},
                    zIndex:'2'
                }}
            >        
                <Stack 
                    direction='column'
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}                                        
                    sx={{
                        margin:'0 auto !important',
                        width:{xs:'80px',md:'120px',sm:'80px'},
                        height:{xs:'80px',md:'120px',sm:'80px'},
                        backgroundColor:theme.palette.primary.main,
                        borderRadius:'100%'
                    }}
                >   <Tooltip title={t("pagine.home.sezione1.action")}>
                        <IconButton onClick={handleClick}>
                            <PersonSearchIcon 
                                sx={{
                                    color:theme.palette.custom.black,
                                    fontSize:{xs:'50px',md:'70px',sm:'50px'},
                                }} 
                            />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>

            {/* Testo e Players Box */}
            <Stack 
                direction={{xs:'column',md:'row',sm:'row'}}
                justifyContent="center"
                alignItems="flex-start"
                spacing={{xs:4,md:2,sm:2}}
                sx={{
                    width:'100%',
                    marginTop:{xs:'0 !important',md:'-30px !important',sm:'0 !important'},
                    padding:{xs:'0 20px !important',md:'0 50px !important',sm:'0 40px !important',xl:'0 400px !important'}
                }}
            >
                {/* Testo e call to action */}
                <Stack 
                direction="column" 
                justifyContent="flex-start" 
                alignItems="flex-start" 
                spacing={{xs:1,md:4,sm:1}}
                sx={{width:{xs:'100%',md:'50%',sm:'55%'}}}
                >
                    <Typography variant='titleHero'>{parse(t("pagine.home.sezione1.title"))}</Typography>
                    <Typography variant='subtitleHero' sx={{marginTop:'20px !important'}}>
                        {parse(t("pagine.home.sezione1.subtitle"))}
                    </Typography>
                    <Button 
                        variant='textRound'
                        sx={{marginTop:'30px !important'}}
                        endIcon={<DoubleArrowIcon />}
                        href='/players'
                    >
                        {t("pagine.home.sezione1.action")}
                    </Button>
                </Stack>               

                {/* Players Box */}
                <Stack 
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={1}
                    sx={{width:{xs:'100%',md:'50%',sm:'45%'},zIndex:'1'}}
                >
                    {playersBox.map((player,index) => (
                        <PLayerBox player={player} key={index} />
                    ))}
                </Stack>
            </Stack>

            {/* Testo aggiuntivo */}
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{
                    width:'100%',
                    marginTop:{xs:'40px',md:'-60px',sm:'40px'},
                    paddingLeft:{xs:'40px',md:'0',sm:'40px'}
                }}
            >
                <Stack 
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={{xs:2,md:1,sm:1}}
                    sx={{
                        width:{xs:'100%',md:'400px',sm:'100%'},
                        marginLeft:{xs:'0',md:'-150px',sm:'0'}
                    }}
                >
                    <Typography 
                        variant='text'
                        sx={{
                            color:theme.palette.custom.black,
                            fontWeight:'500'
                        }}
                    >
                        {parse(t('pagine.home.sezione1.textadded'))}
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
                        {playersMix.map((player,index) => (
                            <Tooltip title={player.first_name + ' ' + player.last_name} key={index}>
                                <Avatar                                     
                                    alt={player.last_name} 
                                    src={imgPath + player.player_id + '.jpg'}
                                    onError={(e)=>{e.target.onError=null; e.target.src=noImg}}
                                    sx={{
                                        borderStyle:'solid',
                                        borderWidth:'3px',
                                        borderColor:theme.palette.custom.blueIcon,
                                        width:'50px',
                                        height:'50px',
                                        marginLeft:'-10px'
                                    }}
                                />
                            </Tooltip>  
                        ))}
                        <Tooltip title={t("pagine.home.sezione1.action")}>
                            <IconButton 
                                href='/players'
                                sx={{
                                    backgroundColor:theme.palette.primary.main,
                                    borderRadius:'100%',
                                    width:'50px',
                                    height:'50px',
                                    marginLeft:'-10px',
                                    "&:hover":{
                                        backgroundColor:theme.palette.custom.black,
                                    }
                                }}
                            >
                                <LinearScaleIcon 
                                    sx={{                                    
                                        color:theme.palette.custom.white,
                                        fontSize:'24px'
                                }}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default HeroSection;