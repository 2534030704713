import utils from "../utils";

export default confirmurl => {

    const requestOptions = {
        method: 'GET',        
        headers: utils.createHeaders()
    };

    return utils.fetch(`${confirmurl}`, requestOptions)
        .then(response => response.json());    
};
