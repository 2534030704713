import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { FormGroup, FormControl, FormControlLabel, Switch, Typography, Stack, Button, CircularProgress} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import SelectRole from './SelectRole';
import SelectTeam from './SelectTeam';
import SelectNation from './SelectNation';

const Filters = props => {
    const {listTournament,listNations,applyFilters,handleFilterClose} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');    
    const loading = useStoreState(state => state.players.findPlayer.loading);     

    const handleFilters = () => {
        applyFilters();
        handleFilterClose();
    };

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            sx={{padding:'10px'}}
        >
            <Stack
                direction={{xs:'column',md:'row',sm:'row',lg:'row'}}
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={{xs:3,md:3,sm:1,lg:3}}
                sx={{}}
            >
                {/* Ruolo */}
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Typography 
                        variant='text' 
                        sx={{
                            width:'100%',
                            borderStyle:'solid',
                            borderWidth:'0 0 1px 0',
                            borderColor: theme.palette.custom.orange
                        }}
                    > 
                        {t("global.role")}
                    </Typography>
                    <SelectRole />
                </Stack>

                {/* Squadra */}
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Typography 
                        variant='text' 
                        sx={{
                            width:'100%',
                            borderStyle:'solid',
                            borderWidth:'0 0 1px 0',
                            borderColor: theme.palette.custom.orange
                        }}
                    > 
                        {t("global.torneo") + "/" + t("global.team")}
                    </Typography>
                    <SelectTeam                         
                        listTournament={listTournament}                        
                    />
                </Stack>   

                {/* Nationalità */}   
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Typography 
                        variant='text' 
                        sx={{
                            width:'100%',
                            borderStyle:'solid',
                            borderWidth:'0 0 1px 0',
                            borderColor: theme.palette.custom.orange
                        }}
                    > 
                        {t("global.nationality")}
                    </Typography>
                    <SelectNation
                        listNations={listNations}                        
                    />
                </Stack>
            </Stack>
            {/* Pulsanti */}
            <Stack 
                direction="row" 
                justifyContent="center" 
                alignItems="center" 
                spacing={{xs:2,md:3,sm:2,lg:4}}
                sx={{width:'100%',marginTop:'20px !important'}}
            >                                       
                <Button 
                    variant='action' 
                    onClick={handleFilters}
                    endIcon={<TuneIcon />}                    
                >
                    {t("global.apply")}
                </Button>                  
                <Button 
                    variant='secondary' 
                    onClick={handleFilterClose}
                    disabled={loading}
                    endIcon={<CloseIcon sx={{color:theme.palette.custom.orange}} />}
                >
                    {t("global.close")}
                </Button>
            </Stack>
        </Stack>
    );
};

export default Filters;