import { createStore, persist, action } from 'easy-peasy';
import services from '../services';
import user from './user';
import players from './players';
import teams from './teams';
import top11 from './top11';
import auth from './auth';

const model = {
    user,
    players,
    teams,
    top11,
    auth
};

export default createStore(
    persist(model,{
        storage: 'localStorage'
    }),
    {
		injections: {
			services
		}
	}
);