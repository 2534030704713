import utils from "../utils";

export default tournament => {
    const requestOptions = {
        method: 'GET',
        headers: utils.createHeaders()
    };
    
    return utils.fetch(`${process.env.REACT_APP_API_ENDPOINT}/players/getbytournament?t=${tournament}`, requestOptions)
        .then(response => response.json());
};