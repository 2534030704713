import React, {useEffect, useState} from 'react';
import { useTheme } from "@mui/material/styles";
import { Box, Avatar } from '@mui/material';

const PlayerPhoto = props => {
    const {player, color} = props;
    const theme = useTheme();
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/noImg.jpg";
    
    return(
        <Box
            sx={{
                borderStyle:'solid',
                borderWidth:'2px',
                borderColor:color,
                display:'block',                            
                borderRadius:'100%',
                padding:'3px'                            
            }}
        >
            <Avatar 
                alt={player.first_name + " " + player.last_name} 
                src={imgPath + player.player_id + ".jpg"}
                onError={(e)=>{e.target.onError=null; e.target.src=noImg}}
                sx={{
                    borderStyle:'solid',
                    borderWidth:'3px',
                    borderColor:color,
                    width:{xs:'110px',md:'120px',sm:'110px'},
                    height:{xs:'110px',md:'120px',sm:'110px'}                    
                }}
            />
        </Box>
    );
};

export default PlayerPhoto;