import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Typography, Avatar, Button, CircularProgress } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const EmailVerified = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const loading = useStoreState(state => state.auth.loading);
    const setUserVerified = useStoreActions(dispatch => dispatch.user.setUserVerified);
    const managementToken = useStoreState(state => state.auth.managementToken);    
    const sendEmailVerify = useStoreActions(dispatch => dispatch.auth.sendEmailVerify);
    const sendEmailMessage = useStoreState(state => state.auth.sendEmailMessage);
    const setSendEmailMessage = useStoreActions(dispatch => dispatch.auth.setSendEmailMessage);
    const [searchParams, setSearchParams] = useSearchParams();
    const successVerifed = searchParams.get('success');
    const code = searchParams.get('code');    
    const userVerified = user != null && user.emailVerified;
    const userLogged = user != null && user.emailVerified && user.logged;

    useEffect(() => {
        setSendEmailMessage('');
        //Se ho completato la procedura di verifica dell'email oppure l'utente non esiste più redirigo
        if((user !== null && user.emailVerified && user.logged && user.token !== '') || user == null){
    //        window.location.href = redirect_user_logged;    
        }
        //Se arrivo dal click dell'utente sull'email di conferma
        else if(successVerifed == 'true' && code == 'success'){
            setUserVerified(true);
        }        
    },[]);

    const resend = _ => {
        sendEmailVerify(user.sub);
    };

    return (
        <Stack 
            direction="column" 
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            sx={{width:'100%'}}
        >
            {/** Titolo pagina */}
            <Stack 
                direction="row" 
                justifyContent="flex-start" 
                alignItems="center" 
                spacing={1}
                sx={{
                    width:'100%',
                    backgroundColor:theme.palette.custom.black,
                    color:theme.palette.custom.white,
                    padding:{xs:'10px 20px', md:'10px 190px',sm:'10px 40px',tb:'10px 40px'}
                }}
            >
                    <Avatar sx={{backgroundColor:theme.palette.primary.main}} ><MarkEmailReadIcon /></Avatar>
                <Typography variant='pageTitle'>{t('formlogin.emailverifing')}</Typography>
            </Stack>  
            {/* Messaggio */}
            <Stack 
                direction="column" 
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{width:'100%',padding:{xs:'20px',md:'30px 190px',sm:'20px 40px'},minHeight:'100vh'}}
            > 
                <Typography variant='titleSection'>
                    {
                        ((successVerifed == 'true' && code == 'success') || (successVerifed != 'true' && userVerified)) ? 
                            t('formlogin.verifiedemail') :
                            successVerifed != 'true' && !userVerified ? t('formlogin.unVerifiedemail') : ''
                    }
                </Typography>
                <Typography variant='text' sx={{textAlign:'center'}}>
                {
                    (successVerifed == '' && code == '' && !userLogged && !userVerified && user.token) ? 
                        parse(t('formlogin.registredemail')) :
                        (successVerifed == 'true' && code == 'success') ? t('formlogin.accountverified') :
                            (successVerifed != 'true' && userVerified && !userLogged) ? t('formlogin.alreadyverifyuser') :
                                (successVerifed != 'true' && userVerified && userLogged) ? t('formlogin.verifyusererror') :
                                    (successVerifed != '' && successVerifed != 'true' && !userVerified) ? t('formlogin.accountcreatederror') : ''
                }
                </Typography>      
                {/* Reinvio email */}
                {((successVerifed != '' && successVerifed != 'true' && !userVerified) ||
                    (successVerifed == '' && code == '' && !userLogged && !userVerified && user.token)) && (
                <Stack 
                    direction="column" 
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{width:'100%'}}
                >     
                    {loading && (<CircularProgress size={16} sx={{color:theme.palette.primary.main}} />)}            
                    {!loading && (
                    <Button                      
                        variant='action'
                        onClick={resend}
                        endIcon={<MarkEmailReadIcon />}
                    >
                        {t('formlogin.resendemailconfirm')}
                    </Button>
                    )}
                    <Typography variant='text' sx={{textAlign:'center'}}>
                        {t(sendEmailMessage)}
                    </Typography>
                </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default EmailVerified;