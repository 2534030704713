import { SHA256 } from "crypto-js";

const generateStateCode = length => {
	let text = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
	for (let i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
};

export default _ => {
    const stateCode = generateStateCode(128);
    return stateCode;
};