import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme, styled } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Box, Button, Typography, CircularProgress, LinearProgress, linearProgressClasses } from '@mui/material';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import MessageLogin from '../MessageLogin';
import PopoverDesc from '../PopoverDesc';
import LinearProgressValue from '../LinearProgressValue';

const PlayerCalcData = props => {
    const {userLogged} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loadingCalcData = useStoreState(state => state.players.getPlayerCalcData.loadingCalcData);
    const player = useStoreState(state => state.players.getPlayerCalcData.playerCalcData);
    const playersLimits = useStoreState(state => state.players.playerSummary.playersLimits);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    //Gestisco il caso in cui il profilo del giocatore non è più attivo
    if(!player)
        return;
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const valueLimitsMax = playersLimits.filter(val => val.type === "max")[0];
    const valueLimitsMin = playersLimits.filter(val => val.type === "min")[0];

    const rows = [
        {
            "parameter":t("coefficients.ableshot"),
            "value":userLogged && player ? player.able_shot : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.able_shot : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.able_shot : 0,
            "perc":playersLimits.length > 0 ? 
                (player.able_shot - valueLimitsMin.able_shot) / (valueLimitsMax.able_shot - valueLimitsMin.able_shot) : '-' 
        },
        {
            "parameter":t("coefficients.accurancygoalbyshots"),
            "value":userLogged && player ? player.accuracy_goal_by_shots : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.accuracy_goal_by_shots : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.accuracy_goal_by_shots : 0,
            "perc":playersLimits.length > 0 ? 
                (player.accuracy_goal_by_shots - valueLimitsMin.accuracy_goal_by_shots) / (valueLimitsMax.accuracy_goal_by_shots - valueLimitsMin.accuracy_goal_by_shots) : '-' 
        },
        {
            "parameter":t("coefficients.passtogoal"),
            "value":userLogged && player ? player.pass_to_goal : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.pass_to_goal : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.pass_to_goal : 0,
            "perc":playersLimits.length > 0 ? 
                (player.pass_to_goal - valueLimitsMin.pass_to_goal) / (valueLimitsMax.pass_to_goal - valueLimitsMin.pass_to_goal) : '-' 
        },
        {
            "parameter":t("coefficients.ablegoalbyfreekick"),
            "value":userLogged && player ? player.able_goal_by_free_kick : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.able_goal_by_free_kick : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.able_goal_by_free_kick : 0,
            "perc":playersLimits.length > 0 ? 
                (player.able_goal_by_free_kick - valueLimitsMin.able_goal_by_free_kick) / (valueLimitsMax.able_goal_by_free_kick - valueLimitsMin.able_goal_by_free_kick) : '-' 
        },
        {
            "parameter":t("coefficients.ablegoalbypenalty"),
            "value":userLogged && player ? player.able_goal_by_penalty : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.able_goal_by_penalty : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.able_goal_by_penalty : 0,
            "perc":playersLimits.length > 0 ? 
                (player.able_goal_by_penalty - valueLimitsMin.able_goal_by_penalty) / (valueLimitsMax.able_goal_by_penalty - valueLimitsMin.able_goal_by_penalty) : '-' 
        },
        {
            "parameter":t("coefficients.accurancypass"),
            "value":userLogged && player ? player.accurancy_pass : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.accurancy_pass : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.accurancy_pass : 0,
            "perc":playersLimits.length > 0 ? 
                (player.accurancy_pass - valueLimitsMin.accurancy_pass) / (valueLimitsMax.accurancy_pass - valueLimitsMin.accurancy_pass) : '-' 
        },
        {
            "parameter":t("coefficients.ablekeepball"),
            "value":userLogged && player ? player.able_keep_ball : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.able_keep_ball : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.able_keep_ball : 0,
            "perc":playersLimits.length > 0 ? 
                (player.able_keep_ball - valueLimitsMin.able_keep_ball) / (valueLimitsMax.able_keep_ball - valueLimitsMin.able_keep_ball) : '-' 
        },
        {
            "parameter":t("coefficients.ablebreakgame"),
            "value":userLogged && player ? player.able_break_game : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.able_break_game : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.able_break_game : 0,
            "perc":playersLimits.length > 0 ? 
                (player.able_break_game - valueLimitsMin.able_break_game) / (valueLimitsMax.able_break_game - valueLimitsMin.able_break_game) : '-' 
        },
        {
            "parameter":t("coefficients.abletackle"),
            "value":userLogged && player ? player.able_tackle : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.able_tackle : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.able_tackle : 0,
            "perc":playersLimits.length > 0 ? 
                (player.able_tackle - valueLimitsMin.able_tackle) / (valueLimitsMax.able_tackle - valueLimitsMin.able_tackle) : '-' 
        },
        {
            "parameter":t("coefficients.ablesave"),
            "value":userLogged && player ? player.able_save : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.able_save : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.able_save : 0,
            "perc":playersLimits.length > 0 ? 
                (player.able_save - valueLimitsMin.able_save) / (valueLimitsMax.able_save - valueLimitsMin.able_save) : '-' 
        },
        {
            "parameter":t("coefficients.aggression"),
            "value":userLogged && player ? player.aggression : 0,
            "max":playersLimits.length > 0 ? valueLimitsMax.aggression : 0,
            "min":playersLimits.length > 0 ? valueLimitsMin.aggression : 0,
            "perc":playersLimits.length > 0 ? 
                (player.aggression - valueLimitsMin.aggression) / (valueLimitsMax.aggression - valueLimitsMin.aggression) : '-' 
        }
    ];

    return(
        <Box sx={{width:'100%'}}>
            <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="center">
                <AlignVerticalCenterIcon sx={{color:theme.palette.primary.main,fontSize:'30px'}} />
                <Box>
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'22px',md:'22px',sm:'22px'},
                            color:theme.palette.custom.black,
                            fontWeight:'600'                   
                        }}
                    >
                        {t("global.calcdata")}
                    </Typography>                        
                </Box>
            </Stack>
            <Box sx={{width:'100%'}}>
                <Button 
                    variant='textLink'
                    onClick={handleClick}
                    sx={{fontSize:{xs:'15px',md:'15px',sm:'15px'}}}
                >
                    {parse(t("global.calcdatawhat"))}
                </Button>
                <PopoverDesc 
                    open={open} 
                    anchorEl={anchorEl} 
                    handleClose={handleClose} 
                    icon={<AlignVerticalCenterIcon sx={{fontSize:'30px !important'}} />}
                    title={"calcdata"}
                    what={"calcdatawhat"}
                    desc={"calcdatadesc"}
                />
            </Box>
            {loadingCalcData && (
                <Box sx={{padding:'10px auto',textAlign:'center'}}>
                    <CircularProgress sx={{color:theme.palette.primary.main}} />
                </Box>
            )}
            {!userLogged && (
                <MessageLogin data={t("messages.values")} color={theme.palette.custom.black} />
            )}
            {!loadingCalcData && userLogged && (
                <Stack 
                    direction="column" 
                    justifyContent="fles-start" 
                    alignItems="fles-start"
                    spacing={1}
                    sx={{
                        marginTop:'10px',
                        fontFamily: ['"Montserrat Alternates",sans-serif'].join(','),
                        fontSize:'14px',
                        width:'100%'     
                    }}
                >
                    {rows.map((row,index) => (
                        <Stack
                            key={index}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                            sx={{
                                width:'100%',
                                padding:'7px 0',
                                margin:'8px 0 0 0px !important',
                                borderStyle: 'solid',
                                borderColor: '#ddd',
                                borderWidth: index % 2 === 0 ? '0' : '1px',
                                //borderBottom:'1px solid #ddd',
                                background:index % 2 === 0 ? theme.palette.custom.white : theme.palette.custom.water
                            }}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"                                
                                sx={{
                                    width:'100%',                                                                        
                                    fontWeight:'600',
                                    marginBottom:'-8px'                                    
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'80%',
                                        paddingLeft:'5px',
                                        fontSize:{xs:'17px',md:'16px',sm:'17px'},
                                        '&::first-letter':{
                                            textTransform:'uppercase'
                                        }
                                    }}
                                >
                                    {row.parameter}
                                </Box>
                                <Box
                                    sx={{
                                        width:'20%',
                                        color:row.value > 0 ? theme.palette.custom.green :
                                            row.value < 0 ? theme.palette.custom.red : theme.palette.custom.black,                                    
                                        textAlign:'right',
                                        paddingRight:'5px',
                                        fontSize:{xs:'18px',md:'18px',sm:'18px'}                                               
                                    }}
                                >
                                    {row.value}
                                </Box>
                            </Stack>     
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                sx={{width:'100%',padding:'0 5px'}}
                            >
                                <LinearProgressValue                                    
                                    //value={row.value != 0 ? parseFloat(row.value * 100 / row.max).toFixed(2) : 0}
                                    value={row.perc !== '-' ? parseFloat(row.perc * 100).toFixed(0)  : row.perc}
                                    max={row.max}
                                    min={row.min}
                                    viewLimits={true}
                                />
                                <Typography 
                                    variant='textBase'
                                    sx={{
                                        color:theme.palette.primary.main,
                                        fontWeight:'600',
                                        fontSize:{xs:'15px',md:'15px',sm:'15px'},
                                        marginTop:'-18px'
                                        
                                    }}
                                >
                                    {row.perc !== '-' ? '(' + parseFloat(row.perc * 100).toFixed(0) + '%)' : row.perc}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            )}            
        </Box>
    );
};

export default PlayerCalcData;