import { action, thunk } from 'easy-peasy';
import utils from '../utils';
import { act } from 'react';

export default {
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    loadingFindTour: false,
    setLoadingFindTour: action((state, payload) => {
        state.loadingFindTour = payload;
    }),
    players: [],    
    setPlayers: action((state,payload) => {
        state.players = [];
        payload.map((player) => {
            //players = utils.decryptResponse(player);
            state.players.push(utils.decryptResponse(player));
        });   
    }),
    playersTournament: [],
    setPlayersTournament:  action((state,payload) => {
        state.playersTournament = [];
        payload.map((player) => {
            state.playersTournament.push(utils.decryptResponse(player));
        }) 
    }),
    playersFiltered: [],
    setPlayersFiltered: action((state, payload) => {
        state.playersFiltered = payload;
        state.count = state.playersFiltered.length;
    }),
    page: 1,
    setPage: action((state, payload) => {
        state.page = payload;
    }),
    text: '',
    setText: action((state, payload) => {
        state.text = payload;
    }),
    roleList: ['Goalkeeper','Defender','Midfielder','Attackingmidfielder','Forward'],
    selectedRole: ['Goalkeeper','Defender','Midfielder','Attackingmidfielder','Forward'],
    setSelectedRole: action((state, payload) => {
        state.selectedRole = payload;
    }),
    selectedTournament: [],
    setSelctedTournament: action((state, payload) => {
        state.selectedTournament = payload;
    }),
    selectedTeam: [],
    setSelectedTeam: action((state, payload) => {
        state.selectedTeam = payload;
    }),
    selectedNation: [],
    setSelectedNation: action((state, payload) => {
        state.selectedNation = payload;
    }),
    selectedPlayer: null,
    setSelectedPlayer: action((state, payload) => {
        state.selectedPlayer = payload;
    }),
    count: null,
    setCount: action((state, payload) => {
        state.count = payload;
    }),
    lastSearchParams: null,
    setLastSearchParams: action((state, payload) => {
        state.lastSearchParams = payload;
    }),
    resetFilter: action((state,payload) => {
        state.selectedTournament = [];
        state.selectedTeam = [];
        state.selectedNation = [];
        state.selectedRole = ['Goalkeeper','Defender','Midfielder','Attackingmidfielder','Forward'];
        state.text = '';
        state.selectedPlayer = null;
        state.page = 1;
        state.playersFiltered = payload;      
        state.count = payload.length;
    }),
    findPlayersByTeam: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.players.findPlayersByteam(payload)
            .then(data => {
                actions.setPlayers(data);
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    findPlayersByTournament: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        //Recupera tutti i giorcatori di un torneo tramite l'id del torneo
        const { services } = injections;
        const {tournament,limit} = payload;
        actions.setLoading(true);
        services.players.findPlayersBytournament(payload)
            .then(data => {
                actions.setPlayersTournament(data);
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    findPlayersByFilters: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        //Recupera tutti i giorcatori di un torneo tramite l'id del torneo
        const { services } = injections;
        const {tournament,team,nation,selectedRole,text,player} = payload;
        const filters = {
            tournament:tournament ? tournament : "",
            team: team ? team : "",
            nation: nation ? nation : "",            
        };
        actions.setLoading(true);
        services.players.findPlyaresByfilters(filters)
            .then(data => {
                const playersList = [];
                data.map((player) => {playersList.push(utils.decryptResponse(player));})
                //Se non c'è un giocatore selezionato dall'autocomplete filtro per testo
                if(!player)
                    actions.setPlayersFiltered(utils.filterByText(utils.filterByRoles(playersList,selectedRole),text));
                //Se c'è un giocatore selezionato dall'autocomplete filtro per giocatore specifico
                else
                    actions.setPlayersFiltered(utils.filterByRoles(playersList.filter(p => p.player_id == player),selectedRole));
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    findPlayersByParams: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const {tournament,team,searchParams} = payload;
        getStoreState().players.findPlayer.selectedTournament = tournament;
        getStoreState().players.findPlayer.selectedTeam = team;
        getStoreState().players.findPlayer.selectedNation = [];
        getStoreState().players.findPlayer.selectedRole = getStoreState().players.findPlayer.roleList;
        getStoreState().players.findPlayer.text = '';
        getStoreState().players.findPlayer.selectedPlayer = null;
        getStoreState().players.findPlayer.page = 1;
        actions.setLastSearchParams(searchParams); //Registro il searchParams per evitare la doppia chiamata

        actions.findPlayersByFilters({
            "tournament":tournament,
            "team":team,
            "nation":[],
            "selectedRole":getStoreState().players.findPlayer.roleList,
            "text":"",
            "player":null
        });           
    }),
    loadAllPlayers: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        getStoreActions().players.playerSummary.retrieveAllPlayerSummary();
        actions.setLoading(false);
    })
}