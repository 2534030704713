import React, { useEffect, Fragment, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Box, Typography, Button, Avatar } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import HeroSection from './HeroSection';
import FieldVeritcal from '../../components/FieldVertical';
import formazioneByModulo from '../../components/Utility/formazioneByModulo';

const Home = _ => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const players = useStoreState(state => state.players.playerSummary.playersLight);  
    const retrievePlayerSummary = useStoreActions(dispatch => dispatch.players.playerSummary.retrievePlayerSummary);    
    const playersByTeam = useStoreState(state => state.players.findPlayer.players);
    const findPlayersByTeam = useStoreActions(dispatch => dispatch.players.findPlayer.findPlayersByTeam);    
    const bgImg1 = process.env.REACT_APP_PUBLIC_URL + "/assets/images/calciatore_esultanza.jpg";
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/noImg.png";
    const [modulo, setModulo] = useState([1,4,2,3,1]);
    const [playerList,setPlayerList] = useState([]);
    const [formazione, setFormazione] = useState([]);  
    const [team, setTeam] = useState(null);
    const teamIdList = [
        {id:87,name:'Juventus',region:'Italia',tournament:'Serie A',modulo:[1,4,2,3,1]},
        {id:80,name:'AC Milan',region:'Italia',tournament:'Serie A',modulo:[1,4,2,3,1]},
        {id:75,name:'Inter',region:'Italia',tournament:'Serie A',modulo:[1,3,5,0,2]},
        {id:300,name:'Atalanta',region:'Italia',tournament:'Serie A',modulo:[1,3,4,2,1]},
        {id:276,name:'Napoli',region:'Italia',tournament:'Serie A',modulo:[1,4,3,0,3]},
        {id:84,name:'Roma',region:'Italia',tournament:'Serie A',modulo:[1,3,4,2,1]},
        {id:77,name:'Lazio',region:'Italia',tournament:'Serie A',modulo:[1,4,3,0,3]}
    ];     
    
    const indexRandom = Math.floor(Math.random() * 30);

    const inRandom = (value,index,array) => {       
        return index >= indexRandom && index <= indexRandom + 9;
    }
    
    useEffect(() => {
            retrievePlayerSummary(40);            
            const indexTeam = Math.floor(Math.random() * 6);
            
            setTeam(teamIdList[indexTeam]);
            findPlayersByTeam(teamIdList[indexTeam].id);
            setModulo(teamIdList[indexTeam].modulo);
        },[]
    );

    useEffect(() => {
        if(players.length > 0)
            setPlayerList(players.filter(inRandom));
    },[players])

    useEffect(() => {
        if(playersByTeam && playersByTeam.length > 0)
            setFormazione(formazioneByModulo(playersByTeam,modulo));
    },[playersByTeam]);

    return(
        <Fragment>
           <HeroSection playerList={playerList} teamList={teamIdList} />
            <Stack 
                direction="column" 
                justifyContent="center" 
                alignItems="center" 
                spacing={2}
                sx={{
                    width:'100%',
                    padding:{xs:'20px 20px 0 20px',md:'50px 90px 0 90px',sm:'20px 40px 0 40px',xl:'20px 400px'}
                }}
            >                                           
                {/* Quotazioni aggiornate */}  
                <Stack 
                    direction={{xs:'column-reverse',md:'row',sm:'row'}}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        width:'100%',
                        marginTop:{xs:'30px !important',md:'0px !important',sm:'30px !important'}                    
                    }}
                >
                    <Box sx={{width:{xs:'100%',md:'50%',sm:'40%'}}}>
                        <Box 
                            component="img" 
                            src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar_valore_calciatori.jpg"} 
                            sx={{maxWidth:{xs:'350px',md:'500px',sm:'300px'}}}
                        />
                    </Box>
                    <Stack 
                        direction="column" 
                        justifyContent="flex-start" 
                        alignItems="flex-start" 
                        spacing={1}
                        sx={{width:{xs:'100%',md:'50%',sm:'60%'},padding:{xs:'0',md:'0 50px 0 0',sm:'0 10px 0 30px'}}}
                    >
                        <Typography variant='sectionTitle' sx={{fontSize:{xs:'18px',md:'22px',sm:'18px'}}}>
                            {parse(t("pagine.home.sezione2.subtitle"))}
                        </Typography>
                        <Typography variant='sectionTitle'>{parse(t("pagine.home.sezione2.title"))}</Typography>
                        <Typography variant='text'>{parse(t("pagine.home.sezione2.text1"))}</Typography>                    
                        <Button 
                            variant='textRound'
                            sx={{marginTop:'20px !important'}}
                            endIcon={<DoubleArrowIcon />}
                            href='/detail'
                        >
                            {t("pagine.home.sezione2.action")}
                        </Button>
                    </Stack>                
                </Stack>           
            </Stack>

            {/* Immagine scroll */}
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}                
                className='bgFixed'
                sx={{
                    backgroundImage: `url(${bgImg1})`,  
                    backgroundAttachment:{xs:'inherit',md:'fixed',sm:'inherit'},
                    padding:{xs:'80px 20px',md:'110px 120px',sm:'90px 70px',tb:'90px 90px',xl:'110px 400px'},
                    width:'100%'
                }} 
            >
                <Typography 
                    variant='sectionTitle'
                    sx={{
                        color:theme.palette.primary.main,
                        fontSize:{xs:'18px',md:'22px',sm:'18px'}
                    }}
                >
                    PlayerStar
                </Typography>
                <Typography 
                    variant='titleHero'
                    sx={{color:theme.palette.custom.white,marginTop:'10px'}}
                >
                    {parse(t("pagine.home.sezione3.title"))}
                </Typography>
                <Box sx={{marginTop:'20px !important',width:{xs:'95%',md:'50%',sm:'70%'}}}>
                    <Typography variant='subtitleHero' sx={{marginTop:'20px !important',color:theme.palette.custom.white}}>
                        {parse(t("pagine.home.sezione3.subtitle"))}
                    </Typography>
                </Box>
                <Button 
                    variant='textRound'
                    sx={{marginTop:'30px !important'}}
                    endIcon={<DoubleArrowIcon />}
                    href='/detail'
                >
                    {t("pagine.home.sezione3.action")}
                </Button>
            </Stack>

            {/* Quanto vale la tua squadre */}
            <Stack 
                direction={{xs:'column',md:'row',sm:'row'}}
                justifyContent="center" 
                alignItems="center" 
                spacing={2}
                sx={{
                    width:'100%',
                    padding:{xs:'40px 0px 60px 0px',md:'70px 90px 90px 120px',sm:'50px 0px 60px 40px',xl:'90px 400px'}
                }}
            > 
                 <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{
                        width:{xs:'100%',md:'50%',sm:'45%'},
                        padding:{xs:'0 20px 20px 20px',md:'0',sm:'0'}
                    }}
                >
                    <Typography variant='sectionTitle' sx={{fontSize:{xs:'18px',md:'22px',sm:'18px'}}}>
                        {parse(t("pagine.home.sezione4.subtitle"))}
                    </Typography>
                    <Typography variant='sectionTitle'>{parse(t("pagine.home.sezione4.title"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.home.sezione4.text1"))}</Typography> 
                    <Button 
                        variant='textRound'
                        sx={{marginTop:'30px !important'}}
                        endIcon={<DoubleArrowIcon />}
                        href='/teams'
                    >
                        {t("pagine.home.sezione4.action")}
                    </Button>                      
                </Stack>
               
                <Stack 
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    sx={{width:{xs:'100%',md:'50%',sm:'65%'}}}
                >                    
                    {/* Nome team */}
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Avatar 
                            alt={"teamInfo.name"} 
                            src={team ? imgPath + team.id + ".png" : noImg}
                            onError={(e)=>{e.target.onError=null; e.target.src=noImg}}
                            sx={{
                                borderStyle:'solid',
                                borderWidth:'0px',
                                borderColor:theme.palette.custom.white,
                                width:{xs:'36px',md:'36px',sm:'34px'},
                                height:{xs:'36px',md:'36px',sm:'34px'}
                            }}
                        />
                        <Stack 
                            direction="column" 
                            justifyContent="flex-start" 
                            alignItems="flex-start"
                            spacing={0}                        
                        >
                            <Typography 
                                variant='titleSection'
                                sx={{
                                    fontSize:{xs:'18px',md:'20px',sm:'18px'},
                                    fontWeight:'600',
                                    color:theme.palette.custom.black,
                                }}
                            >
                                {team ? team.name : ''}
                            </Typography>
                            <Typography 
                                variant='titleSection'
                                sx={{
                                    fontSize:{xs:'14px',md:'16px',sm:'14px'},
                                    fontWeight:'400',
                                    color:theme.palette.custom.black
                                }}
                            >
                                {team ? team.tournament + " (" + team.region + ")" : ''}
                            </Typography> 
                        </Stack>
                    </Stack>
                    
                    {/* Messaggio click sui giocatori */}
                    <Box sx={{
                        textAlign:'center',
                        margin:{xs:'10px 0 15px 0 !important',md:'10px 0 !important',sm:'5px 0 !important'}
                     }}
                    >
                        <Typography
                            variant='text'
                            sx={{fontSize:'16px !important',color:theme.palette.custom.black}}
                        >
                            {t('global.verticalfieldclick')}
                        </Typography>
                    </Box>
                    {/* Formazione */}                    
                    <FieldVeritcal players={formazione} modulo={modulo} />  
                </Stack>
            </Stack>
    </Fragment>        
    );
};

export default Home;