import React from 'react';
import { useStoreState } from 'easy-peasy';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import getRoutes from './routes';
import Navbar from './components/Navbar/index';
import Footer from './components/Footer';

const App = _ => {
  const viewSite = useStoreState(state => state.user.viewSite);
  const routes = getRoutes(viewSite);

  return (
    <Router>
      {viewSite && (<Navbar />)}
      <Routes>
        {routes.map((route,i) => (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            element={route.component}
          />
        ))}        
      </Routes>
      {viewSite && (<Footer />)}
    </Router>
  );
}

export default App;
