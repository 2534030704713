import utils from "../utils";

export default ({tournament,limit}) => {
    const requestOptions = {
        method: 'GET',
        headers: utils.createHeaders()
    };
    
    return utils.fetch(`${process.env.REACT_APP_API_ENDPOINT}/players/findbytournament?t=${tournament}&l=${limit}`, requestOptions)
        .then(response => response.json());
};