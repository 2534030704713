import { createTheme } from "@mui/material/styles";

let theme = createTheme({
    palette: {
      primary: {
        main: '#e5a705' //'#ffc722'
      },
      secondary: {
        main: '#fdbd00'
      },
      success: {
        main: '#579b24'
      },
      error: {
        main: '#ff3122'
      },
      custom: {
        black: '#000000',
        blackPlayer:'#030A12',
        blue: '#1074C5',
        blueIcon: '#3a6589',
        body: '#fafafa',        
        grey: '#dfdfdf',
        greyhight: '#cac8c8',
        greylight: '#F3F5F7',        
        green: '#47a501', 
        link: '#3a6589',
        linkhover: '#3a6589',       
        yellow: '#ffcb05',
        yellowDark: '#e5a705',
        official: '#1673a1',
        orange: '#ce750f',
        red: '#da1e11',
        subText: '#888888',
        water:'#f1f5ff',        
        white: '#ffffff',
        gk: '#e83c07',
        de: '#e4900d',
        cc: '#07a50e',
        co: '#1073e0',
        at: '#780ba6'
      }
    },
    breakpoints:{
      values: {
        xs: 0,
        sm: 600,
        tb: 1024, //Ipad Pro        
        md: 1120,        
        lg: 1200,
        xl: 2536,
      }
    }
});

theme = createTheme(theme, {
  typography:{
      h3:{
        fontWeight: 600,
        lineHeight: '1em',
        fontFamily: ['"Oswald",sans-serif'].join(','),
        marginBottom: '18px'      
      },
      body:{
        fontWeight: 500,
        fontFamily: ['"Poppins",sans-serif'].join(','),
        fontSize: '18px',
        lineHeight: '1.25em',
        textAlign: 'justify',
        color: '#666'
      }
    },
    components:{
      MuiTypography:{
        variants: [
          {
            props: { variant: 'titleSection'},
            style: {
              fontFamily: ['"Montserrat Alternates",sans-serif'].join(','),                            
              lineHeight: '1em',
              fontSize: '30px',
              fontWeight: '600',
              [theme.breakpoints.down('md')]: {
                fontSize: '26px'
              }           
            }
          },
          {
            props: { variant: 'titleHero'},
            style: {
              fontFamily: ['"Poppins",sans-serif'].join(','),                            
              lineHeight: '1.1em',
              fontSize: '54px',
              fontWeight: '600',
              [theme.breakpoints.down('md')]: {
                fontSize: '32px'
              }           
            }
          },
          {
            props: { variant: 'subtitleHero'},
            style: {
              fontFamily: ['"Poppins",sans-serif'].join(','),                   
              lineHeight: '1.3em',
              fontSize: '24px',
              fontWeight: '400',
              [theme.breakpoints.down('md')]: {
                fontSize: '20px'
              }           
            }
          },
          {
            props: { variant: 'sectionTitle'},
            style: {
              fontFamily: ['"Poppins",sans-serif'].join(','),                            
              lineHeight: '1.1em',
              fontSize: '36px',
              fontWeight: '600',
              [theme.breakpoints.down('md')]: {
                fontSize: '30px'
              }           
            }
          },
          {
            props: { variant: 'pageTitle'},
            style: {
              fontFamily: ['"Montserrat Alternates",sans-serif'].join(','),                            
              lineHeight: '1em',
              fontSize: '26px',
              fontWeight: '600',
              [theme.breakpoints.down('md')]: {
                fontSize: '26px'
              }           
            }
          },
          {
            props: { variant: 'titleAside'},
            style: {
              fontFamily: ['"Poppins",sans-serif'].join(','),                           
              lineHeight: '1em',
              fontSize: '18px',
              fontWeight: '600',
              [theme.breakpoints.down('md')]: {
                fontSize: '16px'
              }           
            }
          },
          {
            props: { variant: 'text'},
            style: {
              fontFamily: ['"Poppins",sans-serif'].join(','),                            
              lineHeight: '1.4em',
              fontSize: '18px',
              fontWeight: '400',
              [theme.breakpoints.down('md')]: {
                fontSize: '18px'
              }           
            }
          },
          {
            props: { variant: 'textSmall'},
            style: {
              fontFamily: ['"Poppins",sans-serif'].join(','),                            
              lineHeight: '1.2em',
              fontSize: '16px',
              fontWeight: '400',
              [theme.breakpoints.down('md')]: {
                fontSize: '16px'
              }           
            }
          },
          {
            props: {variant: 'textRound'},
            style: {
              fontFamily: ['"Montserrat Alternates",sans-serif'].join(','),
              lineHeight: '1.4em',
              fontSize: '18px',
              fontWeight: '400',
              [theme.breakpoints.down('md')]: {
                fontSize: '16px'
              }           
            }
          },
          {
            props: { variant: 'headerSection'},
            style: {
              fontFamily: ['"Poppins",sans-serif'].join(','),
              fontWeight: '500',
              color: '#000',
              fontSize: '18px',
              width:'100%',              
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px'
              }
            }
          },
          {
            props: { variant: "playerName"},
            style: {
              color: theme.palette.custom.black,
              fontFamily: ['"Roboto Condensed",sans-serif'].join(','),                            
              lineHeight: '1em',
              fontWeight: '400',
              fontSize: '22px',
              [theme.breakpoints.down('md')]: {
                fontSize: '18px'
              }
            }
          },                            
          {
            props: {variant: 'cardText'},
            style: {
              color: '#f9f9f9',
              fontFamily: ['"Oswald", sans-serif'].join(','), 
              fontSize: '0.9em',
              lineHeight: '0.8em',
              [theme.breakpoints.down('md')]: {
                fontSize: '0.9em'
              }
            }
          },
          {
            props: {variant: 'playerAvatarText'},
            style: {
              fontFamily: ['"Open Sans",sans-serif'].join(','),
              fontSize: '14px',
              fontWeight: '300',
              lineHeight: '1em',
              textAlign: 'center',
              margin: '5px 0'
            }
          },
          {
            props: {variant: 'textBase'},
            style: {
              fontFamily: ['"Open Sans",sans-serif'].join(','),
              lineHeight: '1em',
              fontWeight: '400',
              fontSize: '1em'
            }
          }          
        ]
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: ['"Poppins", sans-serif'].join(','),
            fontSize: '0.95em',
            backgroundColor: theme.palette.custom.white,
            color: theme.palette.custom.black,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.custom.grey,
            maxWidth: '300px'
          },
          arrow: {
            border:'none',            
            color: theme.palette.custom.grey
          }
        }
      },
      MuiButton:{
        variants: [
          {
            props: { variant: "action"},
            style: {
              fontFamily: ['"Poppins", sans-serif'].join(","),
              textTransform: 'inherit',
              backgroundColor: theme.palette.custom.yellowDark,
              fontWeight: '500',
              fontSize: '16px',
              color: '#fff',
              padding: '8px 16px',
              border:'1px solid #fff',      
              "&:hover": {
                backgroundColor: theme.palette.custom.black,
                color: '#fff'
              }
            }
          },
          {
            props: {variant: "secondary"},
            style: {
              fontFamily: ['"Poppins", sans-serif'].join(","),
              textTransform: 'inherit',
              color: theme.palette.custom.orange,
              backgroundColor: 'none',
              borderWidth: '2px',
              borderColor: theme.palette.custom.yellowDark,
              borderStyle: 'solid',
              fontWeight: '500',
              fontSize: '16px',
              padding: '8px 16px',
              "&:hover":{
                backgroundColor: theme.palette.custom.black,
                borderColor: theme.palette.custom.black,
                color: '#fff'
              }
            }
          },
          {
            props: {variant: "textRound"},
            style: {
              fontFamily: ['"Poppins", sans-serif'].join(","),    
              textTransform: 'inherit',
              lineHeight: '1em',
              fontWeight: '600',
              fontSize: '16px',
              color: theme.palette.custom.white,
              backgroundColor: theme.palette.primary.main,
              borderWidth: '2px',
              borderColor: theme.palette.primary.main,
              borderStyle: 'solid',
              borderRadius: '40px',
              padding: '20px 20px',
              maxWidth:'fit-content',
              "&:hover":{
                backgroundColor: theme.palette.custom.black,
                borderColor: theme.palette.custom.black,
                color: '#fff'
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '16px'
              }  
            }
          },
          {
            props: {variant: "menuItem"},
            style: {
              fontFamily: ['"Poppins", sans-serif'].join(","),
              fontSize: '16px',
              background: 'none',
              fontWeight: '600',
              textTransform:'capitalize',
              color: theme.palette.custom.black,
              "&:hover": {
                color: theme.palette.primary.main,              
                background: 'none'
              }
            }      
          },
          {
            props: { variant: "cardAction"},
            style: {
              fontFamily: ['"Work Sans", sans-serif'].join(","),
              textTransform: 'inherit',
              backgroundColor: '#e81f3e',
              borderRadius: '0',
              fontWeight: '400',              
              color: '#fff',
              padding: '2px 5px 2px 15px',
              position: 'absolute',
              bottom: '0',
              right: '10px',
              overflow: 'hidden',
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: '#000'
              }
            }
          },
          {
            props: { variant: "textAction"},
            style: {
              fontFamily: ['"Poppins", sans-serif'].join(","),
              textTransform: 'uppercase',
              backgroundColor: 'none',
              borderRadius: '0',
              fontWeight: '400',
              padding: '0',
              fontSize: '14px',
              "&:hover": {
                color: theme.palette.custom.yellow,
                background:'none'
              }
            }
          },         
          {
            props: { variant: "textLink"},
            style: {
              fontFamily: ['"Open Sans", sans-serif'].join(","),              
              backgroundColor: 'none',
              borderRadius: '0',
              fontWeight: '600',
              padding: '0',
              fontSize: '14px',
              textTransform:'none',
              color: theme.palette.custom.link,    
              "&:hover": {
                color: theme.palette.custom.linkhover,
                background:'none'
              }
            }
          },
          {
            props: {variant: "iconFull"},
            style: {
              backgroundColor: theme.palette.primary.main, //'#dd4b39',
              color: '#000',
              padding: '0',
              height: '60px',
              "&:hover":{
                backgroundColor: theme.palette.secondary.main, //'#dd4b39'
              }
            }
          }          
        ]
      },
      MuiChip:{
        variants: [
          {
            props: {variant: "boxLight"},
            style: {
              fontFamily: ['"Montserrat Alternates",sans-serif'].join(','),
              background: 'none',
              border: '0',
              fontSize: '18px',
              fontWeight: '600',
              color:theme.palette.custom.blueIcon,
              [theme.breakpoints.down('md')]: {
                fontSize: '16px'
              },
              "& svg":{
                fontSize: '50px',
                color:theme.palette.custom.blueIcon + ' !important'
              }           
            }
          }
        ]
      },
      MuiLink:{
        variants: [          
          {
            props: {variant: "linkBase"},
            style: {
              fontFamily: ['"Oswald", sans-serif'].join(","),
              fontSize: '1em',
              textDecoration: 'none',
              color: theme.palette.custom.orange,
              "&:hover": {
                color: theme.palette.custom.orange
              }
            }
          }
        ]
      },
      MuiCard:{
        variants: [
          {
            props: { variant: "playerCard"},
            style: {
              backgroundColor: '#26272A',
              border:'1px solid #000',
              margin: '0 10px',
              [theme.breakpoints.down('md')]: {
                margin: '0'
              }
            }
          },
          {
            props: { variant: "playerBadge"},
            style: {
              background: '#333000',
              padding: '10px'
            }
          }
        ]
      },
      MuiBox:{
        variants: [          
          {
            props: { variant: "playerPin"},
            style: {
              background: 'transparent',
              padding: '0',
              margin: '0 !important'                
            }
          }
        ]
      },
      MuiDialog:{
        variants: [
          {
            props: {variant: "primary"},
            style: {
              "& .MuiPaper-root":{
                borderWidth: '4px',
                borderStyle: 'solid',
                borderColor: theme.palette.custom.orange
              }
            }
          }
        ]
      },
      MuiAvatar:{
        variants: [
          {
            props: {variant: "playerAvatar"},
            style: {
              width: '32px',
              height: '32px'
            }
          },
          {
            props: {variant: "bigAvatar"},
            style: {
              width: '48px',
              height: '48px'
            }
          },
          {
            props: {variant: "smallAvatar"},
            style: {
              width: '16px',
              height: '16px',
              color: '#fff',
              padding: '1px',
              fontSize: '1em',
              fontWeight: '700'
            }
          }
        ]
      },
      MuiAppBar:{
        variants: [
          {
            props: {variant: "header"},
            style: {
              background: 'none',
              position: 'absolute',
              top: '0px',
              width: '100%',
              zIndex: '10',
              "&::before": {
                content: '""',
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '-1000px',
                zIndex: '10',
                display: 'block'
              }
            }
          },
          {
            props: {variant: "formLoginSign"},
            style: {
              backgroundColor:'#fff',
              color:'#ff0000'
            }
          }
        ]
      },
      MuiTabs:{
        variants:[
          {
            props: {variant: "formLoginSign"},
            style: {
              width: '100%',
              '& .MuiTabs-indicator':{
                color: theme.palette.custom.green
              },
              '& .MuiTabs-indicatorSpan': {
                backgroundColor: theme.palette.custom.black
              }
            }
          }
        ]
      },
      MuiTab:{
        variants:[
          {
            props: {variant: "formLoginSign"},
            style: {
              fontFamily: ['"Open Sans", sans-serif'].join(","),
              backgroundColor: '#D2D8D8',
              fontWeight:'600',
              color: '#809191',
              "&.Mui-selected":{
                backgroundColor: '#fff',
                color: theme.palette.custom.black
              }
            }
          }
        ]
      },
      MuiFormHelperText:{
        variants: [
          {
            props: {variant: "formValidate"},
            style: {
              height: '20px',
              color: theme.palette.error.main,
              textAlign: 'right'
            }
          }
        ]
      },
      MuiInput:{
        variants: [
          {
            props: {variant: "formLogin"},
            style: {
              "& input":{
                fontFamily: ['"Open Sans", sans-serif'].join(","),
                fontSize: '0.85em',                
              }
            }
          },
          {
            props: {variant: "formPage"},
            style: {
              "& input":{
                fontFamily: ['"Open Sans", sans-serif'].join(","),
                fontSize: '1.1em',                
              }
            }
          }
        ]
      },
      MuiFormControlLabel:{
        variants: [
          {
            props: {variant: "formValidate"},
            style: {
              color: '#888',          
              "& span":{
                fontSize: '0.85em'
              },
              "& a":{
                color: theme.palette.custom.orange
              }              
            }
          }
        ]
      },
      MuiList:{
        variants: [
          {
            props: {variant: "popupmenu"},
            style: {             
              "& .MuiListItemButton-root": {
                borderBottom:'1px solid #ccc',
                paddingLeft: 15,
                paddingRight: 15,
              }
            }
          }
        ]
      },
      MuiListItemText:{
        variants: [
          {
            props: {variant: "menuMobileItem"},
            style: {
              fontFamily: ['"Poppins", sans-serif'].join(","),
              fontSize: '18px',             
              fontWeight: '600',
              textTransform:'capitalize',
              color: theme.palette.custom.black              
            }      
          }
        ]
      },
      MuiAutocomplete:{
        variants: [
          {
            props: {variant: "search"},
            style: {
              fontFamily: ['"Poppins", sans-serif'].join(","),
              "& .MuiInputLabel-root":{
                color: theme.palette.custom.blueIcon + " !important"              
              },
              "& .css-eanuct-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                borderColor: theme.palette.custom.blueIcon + " !important"
              },
              "& input":{
                color: theme.palette.custom.blueIcon
              }
            }
          }
        ]
      }
    }
  });

export default theme;