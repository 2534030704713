import React from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack,Typography,Box,Button } from '@mui/material';

const PageNotFound = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const bgImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/404.png";

    return(
        <Stack 
            direction="column" 
            justifyContent="center" 
            alignItems="center" 
            spacing={1}
            sx={{marginTop:{xs:'40px',md:'70px',sm:'40px'}}}
        >            
            <Box
                component="img"                            
                src={bgImg}
                sx={{maxWidth:{xs:'300px',md:'300px',sm:'300px'}}}
            />           
            <Typography 
                variant='titleHero' 
                sx={{
                    fontSize:{xs:'50px',md:'70px',sm:'40px'},
                    color:theme.palette.custom.blueIcon
                }}
            >
                Oops!
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                <Typography 
                    variant='titleHero' 
                    sx={{
                        fontSize:{xs:'36px',md:'70px',sm:'40px'},
                        color:theme.palette.custom.black
                    }}
                >
                    Page <span className='MainColor'>Not</span> Found
                </Typography>                
            </Stack>
            <Typography 
                variant='text'
                sx={{
                    fontSize:{xs:'20px',md:'30px',sm:'20px'},
                    marginTop:'20px !important'
                }}
            >
                {t("pagine.404.pagenotfound")}
            </Typography>
            <Button variant='action' href='/'>{t("pagine.404.gotohome")}</Button>
        </Stack>
    );
};

export default PageNotFound;