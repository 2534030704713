import React from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Stack, Box } from '@mui/material';
import PlayerPin from '../PlayerPin/index.js';
import getRuolo from '../PlayerRole/getRuolo.js';
import getColor from '../PlayerRole/getColor.js';
import './styles.css';

const FieldVeritcal = props => {
    const {players, modulo} = props;
    const theme = useTheme();
    const {t} = useTranslation('common');

    const fieldImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/field_vertucal_prosp.png";
    const playerGk = players.filter((player,index) => index >= 0 && index < modulo[0]);
    const playerDef = players.filter((player,index) => index >= modulo[0] 
        && index < modulo[0] + modulo[1]);
    const playerCc = players.filter((player,index) => index >= modulo[0] + modulo[1] 
        && index < modulo[0] + modulo[1] + modulo[2]);
    const playerCo =  players.filter((player,index) => index >= modulo[0] + modulo[1] + modulo[2] 
        && index < modulo[0] + modulo[1] + modulo[2] + modulo[3]);
    const playerAtt = players.filter((player,index) => index >= modulo[0] + modulo[1] + modulo[2] + modulo[3]
        && index < modulo[0] + modulo[1] + modulo[2] + modulo[3] + modulo[4]);

    const heightCo = playerCo.length > 0 ? '60px' : '15px';
    const heightCc = playerCo.length > 0 ? '60px' : '72px';
    const heightAt = playerCo.length > 0 ? '60px' : '72px';
    const heightDe = playerCo.length > 0 ? '60px' : '72px';
    const heightGk = playerCo.length > 0 ? '60px' : '72px';

    const heightAtXs = playerCo.length > 0 ? '52px' : '62px';
    const heightCoXs = playerCo.length > 0 ? '52px' : '10px';
    const heightCcXs = playerCo.length > 0 ? '52px' : '62px';
    const heightDeXs = playerCo.length > 0 ? '52px' : '62px';
    const heigthGkXs = playerCo.length > 0 ? '52px' : '62px';

    return(        
        <Box 
            className='bgField'
            sx={{
                backgroundImage: `url(${fieldImg})`,
                width:'100%',
                height:{xs:'260px',md:'290px',sm:'260px'}
            }} 
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="baseline"
                spacing={2}
                sx={{
                    height:{xs:heightAtXs,md:`${heightAt}`,sm:heightAtXs},
                    marginTop:{xs:'-20px',md:'-10px',sm:'-10px'}
                }}
            >
                {playerAtt.map((player) => (
                    <PlayerPin 
                        key={player.player_id} 
                        player={player} 
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Forward").substring(0,1)}
                    />                        
                ))}
            </Stack>                
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="baseline"
                spacing={{xs:2,md:3,sm:2}}
                sx={{height:{xs:`${heightCoXs}`,md:`${heightCo}`,sm:heightCoXs}}}
            >
                {playerCo.map((player) => (
                    <PlayerPin 
                        key={player.player_id} 
                        player={player}
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Attackingmidfielder").substring(0,1)}
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="baseline"
                spacing={{xs:1,md:2,sm:1}}
                sx={{height:{xs:`${heightCcXs}`,md:`${heightCc}`,sm:heightCcXs}}}
            >
                {playerCc.map((player) => (
                    <PlayerPin
                        key={player.player_id} 
                        player={player}
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Midfielder").substring(0,1)}
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="baseline"
                spacing={3}
                sx={{height:{xs:`${heightDeXs}`,md:`${heightDe}`,sm:heightDeXs}}}
            >
                {playerDef.map((player) => (
                    <PlayerPin 
                        key={player.player_id} 
                        player={player} 
                        color={getColor(getRuolo(player))}
                        letter={t("roles.Defender").substring(0,1)}
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="baseline"
                spacing={2}
                sx={{height:{xs:heigthGkXs,md:heightGk,sm:heigthGkXs}}}
            >
                {playerGk.map((player) => (
                    <PlayerPin 
                        key={player.player_id} 
                        player={player} 
                        color={theme.palette.custom.gk}
                        letter={t("roles.Goalkeeper").substring(0,1)}
                    />
                ))}
            </Stack>
        </Box>        
    );
};

export default FieldVeritcal;
