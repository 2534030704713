import React, {Fragment, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Flags from 'country-flag-icons/react/3x2';
import { Stack,Typography,CircularProgress,Box,Link,Tooltip,Modal,Switch,IconButton} from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import BallotIcon from '@mui/icons-material/Ballot';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import MessageHeaderLogin from '../../components/MessageHeaderLogin';
import SelectModulo from '../../components/SelectModulo';
import Formazione from './Formazione';
import './styles.css';

const NationLeauge = props => {    
    const {league} = props;
    const Flag = league.regionCode != '' ? Flags[league.regionCode] : null;
    
    return (
        <Tooltip title={league.name}>
            <Link href={'#' + league.name}>
                {league.regionCode == '' && (<StarsIcon sx={{fontSize:'28px'}} />)}
                {league.regionCode != '' && (<Flag className="flagNationLeague" />)}
            </Link>
        </Tooltip>
    );
};

const Top11 = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);    
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const showMessageNotLogged = useStoreState(state => state.user.showMessageNotLogged);
    const loadingTeams = useStoreState(state => state.teams.getTeams.loading);
    const loadingPlayers = useStoreState(state => state.players.playerSummary.loading);    
    const players = useStoreState(state => state.players.playerSummary.players);
    const retrieveAllPlayerSummary = useStoreActions(dispatch => dispatch.players.playerSummary.retrieveAllPlayerSummary);    
    const setCalculatingAll = useStoreActions(dispatch => dispatch.top11.setCalculatingAll); 
    const [openModal, setOpenModal] = useState(!userLogged);
    const [modulo, setModulo] = useState([1,4,2,3,1]);
    const [switchChecked, setSwitchChecked] = useState(false);

    const leagues1 = [
        {id:0,name:'Aggregate',region:t('global.allTournaments'),regionCode:''},
        {id:5,name:'Serie A',region:t('nations.IT'),regionCode:'IT'},
        {id:2,name:'Premier League',region:t('nations.GB'),regionCode:'GB'}       
    ];

    const leagues2 = [
        {id:4,name:'LaLiga',region:t('nations.ES'),regionCode:'ES'},
        {id:3,name:'Bundesliga',region:t('nations.DE'),regionCode:'DE'},
        {id:22,name:'Ligue 1',region:t('nations.FR'),regionCode:'FR'}
    ];

    useEffect(() => {        
        retrieveAllPlayerSummary();
        //Setto il calculating di tutte le leghe a true e ogni componente Top11 setterà il suo calculating a false
        setCalculatingAll(true);
    },[]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const onChangeModulo = (event) => {
        const funArray = num => Number(num);
        const newModulo = Array.from(String(event.target.value),funArray);
        setModulo(newModulo);        
    };

    const handleChangeSwitch = (event) => {
        setSwitchChecked(event.target.checked);
      };

    return (
        <Stack
            direction="column"
            justifyContent="center" 
            alignItems="center" 
            spacing={0}
            sx={{width:'100%'}}
        >
            {/* Modale per utente non loggato */}
            <Modal
                open={openModal && showMessageNotLogged}
                onClose={handleCloseModal}
            >
                <Box><MessageHeaderLogin onClose={handleCloseModal} /></Box>
            </Modal>

            <Stack 
                direction="row" 
                justifyContent="flex-start" 
                alignItems="center" 
                spacing={2}
                sx={{
                    width:'100%',
                    backgroundColor:theme.palette.custom.black,
                    padding:{xs:'10px 10px 10px 10px',md:'10px 120px',sm:'10px 40px',xl:'10px 400px'}
                }}
            >
                <StarsIcon sx={{color:theme.palette.primary.main,fontSize:'32px'}} />
                <Typography 
                    variant='titleSection'
                    sx={{
                        fontSize:{xs:'23px',md:'30px',sm:'22px'},
                        fontWeight:'600',
                        color:theme.palette.custom.white,                                
                    }}
                >
                    Top 11
                </Typography>
            </Stack>

            {(loadingTeams || loadingPlayers) && (
                <Stack 
                    direction="column" 
                    justifyContent="center" 
                    alignItems="center" 
                    spacing={0} 
                    sx={{minHeight:'100vh',width:'100%'}}
                >                
                    <CircularProgress size={130} sx={{color:theme.palette.primary.main}} />
                </Stack>
            )}
            
            {!loadingTeams && !loadingPlayers && players && players.length > 0 && (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                    sx={{
                        width:'100%',
                        padding:{xs:'10px 10px 10px 10px',md:'30px 120px',sm:'10px 40px',xl:'10px 400px'}
                    }}
                >
                    {/* Modulo e Switch */}
                    <Stack 
                        direction="row" 
                        justifyContent="flex-start" 
                        alignItems="center" 
                        spacing={4}
                    >
                        {/* Modulo */}
                        <SelectModulo 
                            modulo={modulo} 
                            onChangeModulo={onChangeModulo} 
                            disabled={!userLogged} 
                            width={{xs:'180px',md:'300px',sm:'250px'}}
                        />

                        {/* Switch */}
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                            <Tooltip title={t('global.field')} >
                                <IconButton onClick={_ => {setSwitchChecked(false);}} disabled={!userLogged}>
                                    <Diversity3Icon 
                                        sx={{
                                            color:userLogged ? theme.palette.custom.green : theme.palette.custom.grey,
                                            fontSize:'30px'
                                        }} 
                                    />
                                </IconButton>
                            </Tooltip>
                            <Switch
                                checked={switchChecked}
                                onChange={handleChangeSwitch}
                                focusVisibleClassName='.Mui-focusVisible'
                                disableRipple
                                disabled={!userLogged}
                                sx={{
                                    width: 42,
                                    height: 22,
                                    padding: 0,
                                    '& .MuiSwitch-switchBase': {
                                        padding: 0,
                                        //margin: 2,
                                        transitionDuration: '300ms',
                                        '&.Mui-checked': {
                                            transform: 'translateX(16px)',
                                            color: '#fff',
                                            '& + .MuiSwitch-track': {
                                                backgroundColor: theme.palette.custom.blueIcon,
                                                opacity: 1,
                                                border: 0,                                        
                                            },
                                            '&.Mui-disabled + .MuiSwitch-track': {
                                                opacity: 0.5,
                                            },
                                        },
                                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                                            color: '#33cf4d',
                                            border: '6px solid #fff',
                                        },
                                        '&.Mui-disabled .MuiSwitch-thumb': {
                                            color: theme.palette.custom.grey
                                        },
                                        '&.Mui-disabled + .MuiSwitch-track': {
                                            opacity: 0.7,
                                            backgroundColor: theme.palette.custom.grey
                                        },
                                        '& .MuiSwitch-thumb': {
                                            boxSizing: 'border-box',
                                            width: 22,
                                            height: 22,
                                        },                                    
                                    },
                                    '& .MuiSwitch-track': {
                                        borderRadius: 26 / 2,
                                        backgroundColor: theme.palette.custom.green,
                                        opacity: 1                                       
                                    },
                                }}
                            />
                            <Tooltip title={t('global.list')} >
                                <IconButton onClick={_ => {setSwitchChecked(true);}} disabled={!userLogged}>
                                    <BallotIcon 
                                        sx={{
                                            color:userLogged ? theme.palette.custom.blueIcon : theme.palette.custom.grey,
                                            fontSize:'30px'
                                        }} 
                                    />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>

                    {/* Link leghe */}
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4} >
                        {[...leagues1,...leagues2].map((league,index) => (                            
                            <NationLeauge league={league} key={index} />                            
                        ))}
                    </Stack>

                    {/* Formazioni */}
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{width:'100%'}}
                    >
                        <Stack 
                            direction={{xs:'column',md:'row',sm:'column'}}
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{width:'100%',maxWidth:{xs:'inherit',md:'inherit',sm:'450px'}}}
                        >                        
                            {leagues1.map((league,index) => (
                            <Fragment key={index}>
                                <a name={league.name} />
                                <Box 
                                    className='wrapperBorder'
                                    sx={{width:'100%',marginTop:'0px !important',padding:'10px 0 !important'}}
                                >                                                                
                                    <Formazione key={index} players={players} league={league} modulo={modulo} field={switchChecked} />                                                                        
                                </Box>
                            </Fragment> 
                            ))}
                        </Stack>
                        <Stack 
                            direction={{xs:'column',md:'row',sm:'column'}}
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{width:'100%',maxWidth:{xs:'inherit',md:'inherit',sm:'450px'}}}
                        >                        
                            {leagues2.map((league,index) => (
                                <Fragment key={index}>
                                    <a name={league.name} />
                                    <Box 
                                        className='wrapperBorder'
                                        sx={{width:'100%'}}
                                    >
                                        <Formazione key={index} players={players} league={league} modulo={modulo} field={switchChecked} />
                                    </Box>
                                </Fragment>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default Top11;