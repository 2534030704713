import { action, thunk } from 'easy-peasy';

export default{   
    calculating:[
        {id:0,calculating:false},
        {id:2,calculating:false},
        {id:3,calculating:false},
        {id:4,calculating:false},
        {id:5,calculating:false},
        {id:22,calculating:false}
    ],
    setCalculating:action((state,payload) => {
        const {id,calculating} = payload;
        state.calculating = state.calculating.map(calc => calc.id == id ? {id:calc.id,calculating:calculating} : calc);
    }),
    setCalculatingAll:action((state,payload) => {        
        state.calculating = state.calculating.map(calc => {return {...calc,calculating:payload};});
    })
};