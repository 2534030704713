import store from "../../store";
import refreshToken from "../auth/refreshToken";

if (window.refreshingToken === undefined) {
	window.refreshingToken = false;
}

const goToLogout = _ => {
    window.location.href = process.env.REACT_APP_PUBLIC_URL + '/logout';
};

const goToPage = _ => {
    window.location.reload();
};

const handle401 = () => {
console.log("handle 401 window.refreshingToken",window.refreshingToken);
   // if (window.refreshingToken === true) return Promise.reject('Waiting for refresh');
   // window.refreshingToken = true;
    const state = store.getState();
    const accessToken = state?.user?.user?.token;    
    if(!accessToken){
        //Se non c'è l'access token redirigo alla pagina di logout per sloggare l'utente
        goToLogout();
       // window.location.href = '/logout';
    }
    else{
        //Se c'è l'access token faccio il refresh
        return refreshToken()
            .then(async refreshTokenResp => {
                //Rimpiazzo i dati del token utente
                store.dispatch({
                    type: '@action.user.replaceToken',
                    payload:{
                        token:refreshTokenResp.access_token,                        
                        idToken:refreshTokenResp.id_token
                    }
                });
                await store.persist.flush();
                //Ricarico la pagina corrente (Attendo un seconso prima di redirigere per permettere il salvataggio in cache)
                setTimeout(() => {
                    goToPage();    
                }, 1000);
       ///         window.location.reload();
            })
            .catch(e => {
                //Se c'è un errore nel refresh del token sloggo l'utente
                console.error('refreshToken logout', e);
           //     window.location.href = '/logout';
                goToLogout();
            });
    }
};

window.handle401 = handle401;
const customFetch = (url, options) => {
    if (options === null) options = {};
    if (options.credentials === null) options.credentials = 'same-origin';
	const abortCtrl = options.abortCtrl ? options.abortCtrl : new AbortController();
	options.signal = abortCtrl.signal;
    if(window.refreshingToken === true){         
        return Promise.reject('Waiting for refresh');
    }
    else {
        return fetch(url, options).then(response => {
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response);
            }
            else if (response.status === 401 || response.status === 403) {
                window.refreshingToken = true;
                return handle401();
            }
            else{
                const error = new Error(response.statusText || response.status);
                error.response = response;
                return Promise.reject(error);
            }
        });
    }
};

export default customFetch;