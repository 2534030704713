import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CircularStats = props => {
    const {value, color, textColor, label} = props;
    const theme = useTheme();
    const circleColor = value >= 0 ? theme.palette.custom.green : theme.palette.custom.red; 
    return(
        <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{                
                margin:'0 15px'
            }}
        >           
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress 
                    variant="determinate"                 
                    size="140px"
                    value={value}
                    thickness={6}
                    sx={{
                        color: circleColor,
                        borderRadius:'50%',
                        boxShadow: `inset 0 0 0 ${6/48*100}px #dddddd`                        
                    }}
                />
                <Box
                    sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                    }}
                >
                    <Typography 
                        variant='titleSection'
                        sx={{
                            color:textColor ? textColor : theme.palette.custom.black,
                            fontSize:{xs:"24px",md:"24px",sm:"24px"},
                            fontWeight:'600'
                        }}
                    >
                        {value + "%"}
                    </Typography>
                </Box>
            </Box>
            <Typography 
                variant='titleSection'
                sx={{
                    color:textColor ? textColor : theme.palette.custom.black,
                    fontSize:{xs:'16px',md:"16px",sm:'16px'},
                    fontWeight:'600'
                }}
            >
                {label}
            </Typography>      
        </Stack>
    );
};

export default CircularStats;