import React, { Fragment } from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Link from '@mui/material/Link';
import Zoom from '@mui/material/Zoom';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import BarChartIcon from '@mui/icons-material/BarChart';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import HubIcon from '@mui/icons-material/Hub';

const IconText = props => {
    const {label, link, comp} = props
    const {t, i18n} = useTranslation('common');
    const theme = useTheme();
    return(
        <Tooltip title={t(label)} arrow TransitionComponent={Zoom}>
            <IconButton 
                href={link}
                size="large"
                sx={{
                    fontSize:{xs:'14px',md:'14px',sm:'16px'},
                    color:theme.palette.custom.orange,
                }}
            >
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
                    {comp}
                    <Typography 
                        variant='textRound' 
                        sx={{
                            color:theme.palette.custom.orange,
                            fontSize:{xs:'12px',md:'12px',sm:'12px'}
                        }}
                    >
                        {t(label)}
                    </Typography>
                </Stack>
            </IconButton>
        </Tooltip>
    );
};

const IconLink = props => {
    const {label, link, comp} = props;    
    const {t, i18n} = useTranslation('common');
    const theme = useTheme();

    return(                
        <Tooltip title={t(label)} arrow TransitionComponent={Zoom}>
            <Link 
                href={link}
                sx={{
                    "&:hover":{color:theme.palette.custom.white},
                }}
            >
                {comp}
            </Link>
        </Tooltip>        
    );
};

const PlayerIcons = props => {
    const theme = useTheme();       
    const {t, i18n} = useTranslation('common');
    const deviatonLabel = t("global.indexof") + ' ' + t("global.deviation");
    const incidenceLabel = t('global.incidence') + ' in team';
    const icons = [                
        {
            id:'field',
            label:'global.playerpositions',
            link:'#position',
            comp:<PersonPinCircleIcon className='iconLink' />
        },
        {
            id:'trand',
            label:'global.trand',
            link:'#trand',
            comp: <InsightsIcon className='iconLink' />
        },
        {
            id:'coefficient',
            label:'global.coefficients',
            link:'#coefficients',
            comp:<BubbleChartIcon className='iconLink' />
        },
        /* Non inserito perchè da perfezionare
        {
            id:'grow',
            label:'global.growCoefficients',
            link:'#grow',
            comp:<InsightsIcon className='iconLink' />
        },
        */
        {
            id:'incidence',
            label:incidenceLabel,
            link:'#incidence',
            comp:<HubIcon className='iconLink' />
        },
        {
            id:'deviation',
            label:deviatonLabel,
            link:'#deviation',
            comp:<SocialDistanceIcon className='iconLink' />
        },
        {
            id:'overview',
            label:'global.overviewindex',
            link:'#overview',
            comp:<StackedLineChartIcon className='iconLink' />
        },
        {
            id:'evaluation',
            label:'global.calcdata',
            link:'#evaluation',
            comp:<AlignVerticalCenterIcon className='iconLink' />
        },
        {
            id:'stats',
            label:'global.statisticsplayer',
            link:'#stats',
            comp:<BarChartIcon className='iconLink' />
        },
        {   
            id:'back',
            label:'global.players',
            link: '/players',
            comp: <KeyboardBackspaceIcon className='iconLink' />
        }
    ];

    return(
        <Stack
            direction="row"
            justifyContent={{xs:'center',md:'flex-start',sm:'flex-start'}}
            alignItems="center"
            spacing={1}   
        >            
            {icons.map((icon,index) => (
            <Stack
                key={index}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{margin:{xs:'0 8px !important',md:'0 10px !important',sm:'0 10px !important'}}}
            >   
                    <IconLink label={icon.label} comp={icon.comp} link={icon.link} />
                </Stack>
            ))}
        </Stack>
    );
};

export default PlayerIcons;