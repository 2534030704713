import React, { useEffect, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import parse from 'html-react-parser';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Box, Typography, Button, Chip } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CircleIcon from '@mui/icons-material/Circle';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InsightsIcon from '@mui/icons-material/Insights';
import HubIcon from '@mui/icons-material/Hub';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import BarChartIcon from '@mui/icons-material/BarChart';

const Detail = props => {
    const {t, i18n} = useTranslation('common');
    const theme = useTheme(); 

    const scopeMaps = [
        {
            label:'coefficients.score',
            desc:'coefficients.scoredesc',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        },
        {
            label:'coefficients.pass',
            desc:'coefficients.passdesc',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        },
        {
            label:'coefficients.defence',
            desc:'coefficients.defencedesc',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        },
        {
            label:'coefficients.save',
            desc:'coefficients.savedesc',
            icon:<CircleIcon sx={{fontSize:'16px'}} />
        }
    ];

    const complexIndexes1 = [        
        {
            id:'coefficient',
            label:'global.coefficients',
            desc:'global.coefficientsdesc',
            icon:<BubbleChartIcon sx={{fontSize:'16px'}} />
        },
        {
            id:'incidence',
            label:'global.incidenceinteam',
            desc:'global.incidenceinteamdesc',
            icon:<HubIcon sx={{fontSize:'16px'}} />
        }
    ];

    const complexIndexes2 = [
        {
            id:'deviation',
            label:'global.deviationindex',
            desc:'global.deviationindexdesc',
            icon:<SocialDistanceIcon className='iconLink' />
        },
        {
            id:'overview',
            label:'global.overviewindex',
            desc:'global.overviewdesc',
            icon:<StackedLineChartIcon className='iconLink' />
        }
    ];

    const complexIndexes3 = [
        {
            id:'evaluation',
            label:'global.calcdata',
            desc:'global.calcdatadesc',
            icon:<AlignVerticalCenterIcon className='iconLink' />
        },
        {
            id:'stats',
            label:'global.statisticsplayer',
            desc:'global.statisticsplayerdesc',
            icon:<BarChartIcon className='iconLink' />
        }
    ];

    const complexIndexes = [complexIndexes1, complexIndexes2, complexIndexes3];

    return(
        <Stack 
            direction="column" 
            justifyContent="center" 
            alignItems="center" 
            spacing={2}
            sx={{width:'100%',padding:{xs:'20px 20px 40px 20px',md:'20px 90px 70px 90px',sm:'20px 40px 40px 40px'}}}
        >
            {/* HERO */}
            <Stack 
                direction={{xs:'column',md:'row',sm:'row'}} 
                justifyContent="center" 
                alignItems="center" 
                spacing={2}
                sx={{width:'100%'}}
            >
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{
                        width:{xs:'100%',md:'60%',sm:'60%'},
                        paddingRight:{xs:'0',md:'70px',sm:'40px'}
                    }}
                >
                    <Typography variant='titleHero'>{parse(t("pagine.detail.title"))}</Typography>
                    <Typography variant='subtitleHero' sx={{marginTop:'20px !important'}}>
                        {parse(t("pagine.detail.subtitle"))}
                    </Typography>
                    <Button 
                        variant='action'
                        sx={{marginTop:'30px !important'}}
                        endIcon={<DoubleArrowIcon />}
                        href='/players'
                    >
                        {t("pagine.detail.action")}
                    </Button>
                </Stack>
                <Box sx={{width:{xs:'100%',md:'40%',sm:'40%'}}}>
                    <Box 
                        component="img" 
                        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar_valore_calciatori.jpg"}
                        sx={{maxWidth:{xs:'350px',md:'500px',sm:'300px'}}}
                    />
                </Box>
            </Stack>

            {/* Ogni ruolo conta */}
            <Stack 
                direction={{xs:'column-reverse',md:'row',sm:'column-reverse'}}
                justifyContent='flex-start'
                alignItems='center'
                spacing={2}
                sx={{width:'100%',marginTop:{xs:'20px !important',md:'0px !important',sm:'50px !important'}}}
            >
                <Box 
                    sx={{
                        width:{xs:'100%',md:'30%',sm:'100%'},
                        textAlign:{xs:'center',md:'center',sm:'center'},
                        marginTop:{xs:'40px !important',md:'0 !important',sm:'20px !important'}
                    }}
                >
                    <Box 
                        component="img" 
                        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/playerstar_quotazioni_calciatori.jpg"}
                        sx={{
                            maxWidth:{xs:'160px',md:'230px',sm:'150px'}                            
                        }}
                    />
                </Box>
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={3}
                    sx={{width:{xs:'100%',md:'70%',sm:'100%'},paddingRight:{xs:'0',md:'70px',sm:'0'}}}
                >
                    <Typography variant='sectionTitle'>{parse(t("pagine.detail.sezione1.title"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.detail.sezione1.text1"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.detail.sezione1.text2"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.detail.sezione1.text3"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.detail.sezione1.text4"))}</Typography>
                    <Button 
                        variant='action'
                        sx={{marginTop:'20px !important'}}
                        endIcon={<DoubleArrowIcon />}
                        href='/players'
                    >
                        {t("pagine.detail.action")}
                    </Button>
                </Stack>                
            </Stack>

            {/* Come funziona PlayerStar */}
            <Stack 
                direction={{xs:'column',md:'row',sm:'column'}} 
                justifyContent="center" 
                alignItems="center" 
                spacing={2}
                sx={{
                    width:'100%',
                    marginTop:{xs:'20px !important',md:'10px !important',sm:'50px !important'},
                    //paddingRight:{xs:'0',md:'70px',sm:'0'}
                }}
            >                
                <Stack 
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={2}
                    sx={{
                        width:{xs:'100%',md:'60%',sm:'100%'},
                        paddingRight:{xs:'0',md:'70px',sm:'0'}                        
                    }}
                >
                    <Typography variant='sectionTitle'>{parse(t("pagine.detail.sezione2.title"))}</Typography>
                    <Typography variant='text'>{parse(t("pagine.detail.sezione2.text1"))}</Typography>
                    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                    {scopeMaps.map((scope,index) => (
                        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0} key={index}>
                            <Typography 
                                variant='text' 
                                sx={{
                                    color:theme.palette.custom.blueIcon,
                                    fontWeight:'600',
                                    fontSize:'18px',
                                    textTransform:'capitalize'
                                }}
                                >
                                {t(scope.label)}
                            </Typography>
                            <Typography variant='text'>{parse(t(scope.desc))}</Typography>
                        </Stack>                        
                    ))}
                    </Stack>  
                </Stack>  
                <Box sx={{
                    width:{xs:'100%',md:'40%',sm:'100%'},
                    textAlign:{xs:'center',md:'left',sm:'center'},
                    display:{xs:'none',md:'inherit',sm:'none'}
                    }}
                >
                    <Box 
                        component="img" 
                        src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/calciatore playerstar valore.jpg"}
                        sx={{maxWidth:{xs:'350px',md:'600px',sm:'600px'}}}
                    />
                </Box>              
            </Stack>

            {/* Indici complessi */}
            <a name='indici' />
            <Stack 
                direction="column" 
                justifyContent={{xs:'flex-start',md:'center',sm:'flex-start'}}
                alignItems={{xs:'flex-start',md:'center',sm:'flex-start'}}
                spacing={1}
                sx={{
                    margin:{xs:'40px 0 30px 0 !important',md:'70px 0 40px 0 !important',sm:'50px 0 30px 0 !important'},
                    width:'100%'                    
                }}
            >                
                <Typography 
                    variant='sectionTitle' 
                    sx={{textAlign:'center'}}
                >
                    {parse(t("pagine.detail.sezione3.title"))}
                </Typography>
                <Typography 
                    variant='text' 
                    sx={{textAlign:{xs:'left',md:'center',sm:'left'},fontWeight:'600'}}
                >
                    {parse(t("pagine.detail.sezione3.text1"))}
                </Typography>
                <Button 
                    variant='action'
                    sx={{marginTop:'20px !important'}}
                    endIcon={<DoubleArrowIcon />}
                    href='/players'
                >
                    {t("pagine.detail.action")}
                </Button>
            </Stack>
            <Stack 
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                sx={{width:'100%'}}
            >
                {complexIndexes.map((complex,index) => (
                <Stack
                    key={index}
                    direction={{xs:'column',md:'row',sm:'row'}}
                    justifyContent={{xs:'center',md:'flex-start',sm:'center'}}
                    alignItems="center"
                    spacing={{xs:2,md:5,sm:2}}
                >
                    {complex.map((complex,index) => (
                    <Stack 
                        key={index}
                        direction="column" 
                        justifyContent="flex-start" 
                        alignItems="flex-start" 
                        spacing={1}
                        sx={{width:{xs:'100%',md:'50%',sm:'50%'}}}
                    >
                        <Chip 
                            variant='boxLight'
                            icon={complex.icon}                                
                            label={
                                <Typography 
                                    variant='textRound' 
                                    sx={{
                                        fontWeight:'600',
                                        fontSize:{xs:'20px',md:'20px',sm:'20px'}
                                    }}
                                >
                                    {parse(t(complex.label))}
                                </Typography>
                            }
                        />
                        <Typography variant='text'>{parse(t(complex.desc))}</Typography>
                    </Stack>
                    ))}
                </Stack>
                ))}
            </Stack>

        </Stack>    
    );
};

export default Detail;