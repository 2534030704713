export default player => {
    const playedPositions = player.played_positions != null ? player.played_positions : '';
    const positionText = player.position_text !=  null ? player.position_text : '';
    const ruolo = positionText == 'Goalkeeper' || playedPositions.includes('-GK-') ? 'Goalkeeper' :
        (positionText == 'Defender') && (
            playedPositions.includes('-DR-') || playedPositions.includes('-MR-') ||
            playedPositions.includes('-DC-') || playedPositions.includes('-Mc-') ||
            playedPositions.includes('-DL-') || playedPositions.includes('-ML-')
        ) ? 'Defender' :
        (positionText == 'Midfielder') && (
            playedPositions.includes('-DR-') || playedPositions.includes('-MR-') ||
            playedPositions.includes('-DC-') || playedPositions.includes('-MC-') || 
            playedPositions.includes('-DMC-') || playedPositions.includes('-DL-') || 
            playedPositions.includes('-ML-')
        ) ? 'Midfielder' :
        (playedPositions.includes('-AMR-') || playedPositions.includes('-AMC-') || 
            playedPositions.includes('-AML-')) ? 'Attackingmidfielder' :
        (playedPositions.includes('-FW-') || ((positionText == 'Forward') && playedPositions == null)) ? 'Forward' 
        : 
        positionText != '' ? positionText : 'N';
    
    return ruolo;
};