import React from 'react';
import ReactDOM from 'react-dom/client';
import { StoreProvider } from 'easy-peasy';
import {I18nextProvider} from "react-i18next";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import theme from './theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './index.css';
import i18next from "i18next";
import lang_it from './language/it.json';
import lang_en from './language/en.json';

i18next.init({
  interpolation: {escapeValue: false},
  lng: 'it',
  resources:{
    it: {
      common: lang_it
    },
    en:{
      common: lang_en
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreProvider store={store}>
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
