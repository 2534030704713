import React, {useEffect, useState, useMemo, Fragment} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Box, Link } from '@mui/material';

const Banner = props => {
    const {type} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const bannerPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/banner/";
    const noImgBanner = process.env.REACT_APP_PUBLIC_URL + "/assets/images/banner/" + process.env.REACT_APP_BANNER_DEFAULT;
    const noUrl = process.env.REACT_APP_BANNER_URL_DEFAULT;

    const typeMap = [
        {id:'300x300_1',width:'300px',height:'300px',href:'https://www.federicamartini.com/mindfulness-torino/'},
        {id:'300x300_2',width:'300px',height:'300px',href:'https://www.gowebstyle.com/web-agency/'},
        {id:'300x300_3',width:'300px',height:'300px',href:'https://www.federicamartini.com/mbsr/'},
        {id:'300x600_1',width:'300px',height:'600px',href:'https://www.federicamartini.com/mbsr/'}
    ];

    const bannerDefault = {id:'banner_gowebstyle.jpg',width:'300px',height:'300px',href:'https://www.gowebstyle.com'};
    
    let banner = typeMap.filter(t => t.id === type);
    banner = banner && banner[0] ? banner[0] : bannerDefault;

    return(
        <a href={banner.href} target='_blank'>
            <Box 
                component="img"
                src={bannerPath + banner.id + '.jpg'}
                onError={(e)=>{e.target.onError=null; e.target.src=noImgBanner}}
                sx={{width:banner.width,height:banner.height,cursor:'pointer'}}
            />
        </a>
    );
};

export default Banner;