import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import { Stack, Typography } from '@mui/material';
import MessageLogin from '../../components/MessageLogin';

const PlayerValue = props => {
    const {value,hideMio,color} = props;
    const theme = useTheme();
    const user = useStoreState(state => state.user.user);    
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';

    return(
        <Stack 
            direction="column" 
            justifyContent="flex-end" 
            alignItems="flex-end" 
            spacing={0} 
            sx={{width:'100%'}}
        >
            {!userLogged && (<MessageLogin data={""} color={theme.palette.custom.black} />)}
            {userLogged && (
            <Typography
                variant='titleSection'
                sx={{
                    color:color || theme.palette.primary.main,
                    fontSize:{xs:'42px',md:'42px',sm:'42px'},
                    fontWeight:'600' 
                }}
            >
                {value}
            </Typography>
            )}
            {!hideMio && (
            <Typography
                variant='titleSection'
                sx={{
                    color:color || theme.palette.custom.black,
                    fontSize:'18px !important',
                    fontWeight:'500',
                }}
            >
                Mio €
            </Typography>
            )}
        </Stack>
    );
};

export default PlayerValue;