import { action, thunk } from 'easy-peasy';
import utils from '../utils';

export default {
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    loadingTrand: false,
    setLoadingTrand: action((state, payload) => {
        state.loadingTrand = payload;
    }),
    teams: [],
    saveTeams: action((state, payload) => {
        state.teams = [];
        if(!payload)
            return;
        payload.map((team) => {
            state.teams.push(utils.decryptResponse(team));
        });
    }),
    selectedTournament: null, 
    setSelctedTournament: action((state,payload) => {
        state.selectedTournament = payload;
    }),
    teamTrand: null,
    setTeamTrand: action((state, payload) => {
        state.teamTrand = null;
        if(!payload || payload.length == 0)
            return;
        state.teamTrand = payload;  
    }),
    retrieveAllTeams: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.teams.getAllTeams(payload)
            .then(data => {
                actions.saveTeams(data);
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    retrieveTeamTrand:  thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoadingTrand(true);
        services.teams.getTeamTrand(payload)
            .then(data => {
                actions.setTeamTrand(data);
            })
            .catch(e => {
                actions.setTeamTrand(null);
            })
            .finally(_ => {
                actions.setLoadingTrand(false);
            });
    })
};