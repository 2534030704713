import React, {useState} from 'react';
import { useTheme } from "@mui/material/styles";
import { useStoreState, useStoreActions } from 'easy-peasy';
import {useTranslation} from "react-i18next";
import { FormGroup, FormControl, FormControlLabel, Switch, Typography, Stack, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Man4Icon from '@mui/icons-material/Man4';

const SelectRole = props => {        
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const selectedRole = useStoreState(state => state.players.findPlayer.selectedRole);
    const setSelectedRole = useStoreActions(dispatch => dispatch.players.findPlayer.setSelectedRole);

    const [roles, setRoles] = useState([
        {id:'Goalkeeper',color:theme.palette.custom.gk,checked:selectedRole.includes('Goalkeeper')},
        {id:'Defender',color:theme.palette.custom.de,checked:selectedRole.includes('Defender')},
        {id:'Midfielder',color:theme.palette.custom.cc,checked:selectedRole.includes('Midfielder')},
        {id:'Attackingmidfielder',color:theme.palette.custom.co,checked:selectedRole.includes('Attackingmidfielder')},
        {id:'Forward',color:theme.palette.custom.at,checked:selectedRole.includes('Forward')}
    ]);

    const handleChange = (event) => {
        const newRoleSelected = roles.map((role,index) => (
            {...role,checked:role.id !== event.target.name ? role.checked : event.target.checked}
        ));
        setRoles(newRoleSelected);
        setSelectedRole(newRoleSelected.filter(role => role.checked).map(r => r.id));
    };

    return (
        <Stack 
            direction="column" 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={1}
            sx={{padding:'0px'}}
        >
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                {roles.map((role,index) => (
                    <FormControlLabel 
                        key={index} 
                        label={<Typography variant='text'>{t("roles." + role.id)}</Typography>}
                        control={
                            <Switch
                                name={role.id}
                                checked={role.checked}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked':{color:role.color},
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: role.color,
                                      },                                                                        
                                }}
                            />
                        }
                    />
                ))}
                </FormGroup>
            </FormControl>            
        </Stack>
    );
};

export default SelectRole;