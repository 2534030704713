import Players from "../../routes/Players";

const filterByText = (players,value) => {
    if(!value || value === "")
        return players;

    const listWords = value.split(' ');

    let result = [];
    players.forEach(player => {
        listWords.forEach(word => {
            const w = word.replaceAll("(","").replaceAll(")","").trim();            
            if(w.trim() != '' && w.trim().length > 2 && !result.includes(player) && 
                (player.first_name.toLowerCase().includes(w.trim().toLowerCase()) || 
                player.last_name.toLowerCase().includes(w.trim().toLowerCase()) || 
                player.team_name.toLowerCase().includes(w.trim().toLowerCase()))
            )
                result = [...result,player];

        });        
    });
    
    return result;
};

export default filterByText;