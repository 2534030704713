import React, {useEffect, useState, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import {TextField,Autocomplete,CircularProgress,Stack,Typography,
    Pagination,Box,IconButton,Button,Popover,Modal,Backdrop} from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import TuneIcon from '@mui/icons-material/Tune';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import PlayersListTable from '../../components/PlayersListTable.js';
import TeamListTable from '../../components/TeamListTable.js';
import Filters from './Filters.js';
import Banner from '../../components/Banner';
import SocialMediaShare from '../../components/SocialMediaShare/index.js';
import MessageHeaderLogin from '../../components/MessageHeaderLogin/index.js';

const Players = props => {
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);  
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';  
    const showMessageNotLogged = useStoreState(state => state.user.showMessageNotLogged); 
    const loadingSummary = useStoreState(state => state.players.playerSummary.loading); 
    const playerSummaryAll = useStoreState(state => state.players.playerSummary.players);        
    const retrieveAllPlayerSummary = useStoreActions(dispatch => dispatch.players.playerSummary.retrieveAllPlayerSummary); 
    const loadAllPlayers = useStoreActions(dispatch => dispatch.players.findPlayer.loadAllPlayers);
    const loadingFilter = useStoreState(state => state.players.findPlayer.loading);  
    const playerSummary = useStoreState(state => state.players.findPlayer.playersFiltered);
    const findPlayersByFilters = useStoreActions(dispatch => dispatch.players.findPlayer.findPlayersByFilters);
    const findPlayersByParams = useStoreActions(dispatch => dispatch.players.findPlayer.findPlayersByParams);
    const setPlayersFiltered = useStoreActions(dispatch => dispatch.players.findPlayer.setPlayersFiltered);
    const loadingTeams = useStoreState(state => state.teams.getTeams.loading);
    const allTeams = useStoreState(state => state.teams.getTeams.teams);    
    const page = useStoreState(state => state.players.findPlayer.page);
    const setPage = useStoreActions(dispatch => dispatch.players.findPlayer.setPage);
    const text = useStoreState(state => state.players.findPlayer.text);
    const setText = useStoreActions(dispatch => dispatch.players.findPlayer.setText);    
    const selectedRole = useStoreState(state => state.players.findPlayer.selectedRole);        
    const selectedTournament = useStoreState(state => state.players.findPlayer.selectedTournament);
    const selectedTeam = useStoreState(state => state.players.findPlayer.selectedTeam);
    const selectedNation = useStoreState(state => state.players.findPlayer.selectedNation);    
    const selectedPlayer = useStoreState(state => state.players.findPlayer.selectedPlayer);
    const setSelectedPlayer = useStoreActions(dispatch => dispatch.players.findPlayer.setSelectedPlayer);
    const count = useStoreState(state => state.players.findPlayer.count);
    const setCount = useStoreActions(dispatch => dispatch.players.findPlayer.setCount);
    const resetFilter = useStoreActions(dispatch => dispatch.players.findPlayer.resetFilter);
    const lastSearchParams = useStoreState(state => state.players.findPlayer.lastSearchParams);
    const setLastSearchParams = useStoreActions(dispatch => dispatch.players.findPlayer.setLastSearchParams);
    const [listPlayers, setListPlayers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(25);    
    const [openAutocomplete, setOpenAutocomplete] = useState(false);      
    const [listTournament, setListTournament] = useState([]);
    const [listNations, setListNations] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(!userLogged);
    const openFilter = Boolean(anchorEl);
    const existFilters = selectedRole.length != 5 || selectedTournament.length > 0 || selectedTeam.length > 0 || selectedNation.length > 0;
    const indexRandom = Math.floor(Math.random() * 14);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const filterPlayers = (newpage,lista) => {
        setListPlayers(
            lista.filter((player,index) => index >= (newpage - 1) * rowsPerPage && 
                index < ((newpage - 1) * rowsPerPage) + rowsPerPage)
        );
    };

    const filterByText = (players,value) => {
        if(!value || value === "")
            return players;

        const listWords = value.split(' ');
    
        let result = [];
        players.forEach(player => {
            listWords.forEach(word => {
                const w = word.replaceAll("(","").replaceAll(")","").trim();                
                if(w.trim() != '' && w.trim().length > 2 && !result.includes(player) && 
                    (player.first_name.toLowerCase().includes(w.trim().toLowerCase()) || 
                    player.last_name.toLowerCase().includes(w.trim().toLowerCase()) || 
                    player.team_name.toLowerCase().includes(w.trim().toLowerCase()))
                )
                    result = [...result,player];
            });        
        });
        
        return result;
    };

    useEffect(() => {
        loadAllPlayers();
        //retrieveAllPlayerSummary(); -> sostituito per inserire il false sul loading ed evitare che la circularprogess in apertura non si chiuda
    },[]);

    useEffect(() => {        
        //Lista dei tornei
        setListTournament(playerSummaryAll && playerSummaryAll.length > 0 ? 
            [...new Set(playerSummaryAll.map((player,index) => player.tournament_name))].sort() : []);
        //Lista delle nazioni
        setListNations(playerSummaryAll && playerSummaryAll.length > 0 ? 
            [...new Set(playerSummaryAll.map((player,index) => player.region_code))]
            .filter(n => n!='').sort() : []);
        //Se arrivo da una pagina profilo filtro torneo e squadra       
        if(searchParams && (searchParams.get('to') || searchParams.get('te')) && searchParams != lastSearchParams){
            findPlayersByParams({tournament:[searchParams.get('to')],team:[searchParams.get('te')],searchParams:searchParams});            
        }
        //Se c'è un giocatore selezionato filtro per quello
        if(selectedPlayer){      
            findPlayersByFilters({
                "tournament":selectedTournament[0],
                "team":selectedTeam[0],
                "nation":selectedNation[0],
                "selectedRole":selectedRole,
                "text":"",
                "player":selectedPlayer
            });
        } 
        //Se non ci sono filtri imposto la lista a tutti i giocatori (caricamento la prima volta)
        if(!existFilters && text == '' && searchParams.size == 0) {
            setPlayersFiltered(playerSummaryAll);
        }
    },[playerSummaryAll]);


    useEffect(() => {
        setPage(page);                
        if(playerSummary.length == 0 && searchParams.size == 0 && !existFilters && text == '')
            setPlayersFiltered(playerSummaryAll);        
        else
            filterPlayers(page,playerSummary);

  //      setCount(playerSummary.length); -> impostato da setPlayersFiltered richiamato da findPlayersByFilters
        setAnchorEl(null);
    },[playerSummary]);

    useEffect(() => {
		//Scroll top
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [page]);

    const onInputChange = (event, value, reason) => {
        if(!value || value === ''){
            setOpenAutocomplete(false);
            resetSearch();
        }
        else if (value.length >= 3){
            if(value === text)
                setOpenAutocomplete(false);
            else
                setOpenAutocomplete(true);
        }
    }

    const handleNewValue = (event,value) => {        
        //Caso in cui si clicca sulla "X" dell'autocomplete
        if(!value)
        {   
            setText('');
            setSelectedPlayer(null);
            //Applico eventuali filtri           
            findPlayersByFilters({
                "tournament":selectedTournament[0],
                "team":selectedTeam[0],
                "nation":selectedNation[0],
                "selectedRole":selectedRole,
                "text":"",
                "player":null
            });           
            return;              
        }
        //Caso in cui è stato scelto un elemento dall'autocomplete
        if(value.player_id){
            setListPlayers([value]);
            setCount(1);
            setOpenAutocomplete(false);
            setSelectedPlayer(value.player_id);
            setText(value.first_name + " " + value.last_name + " (" + value.team_name + ")")
            return;
        }
        //Caso in cui non è stato scelto un elemento dall'autocomplete (press su Enter)
        else if (value.length > 2){                       
           //Applico eventuali filtri            
           findPlayersByFilters({
                "tournament":selectedTournament[0],
                "team":selectedTeam[0],
                "nation":selectedNation[0],
                "selectedRole":selectedRole,
                "text":value,
                "player":null
            });
            setPage(1);
            setText(value);
            setSelectedPlayer(null);
            setOpenAutocomplete(false);
        }        
    };

    const onClickSearch = _ => {
        const valore = document.getElementById("textfind").value;
        if(!valore || valore === '')
        {
            //Applico eventuali filtri            
            setPage(1);
            filterPlayers(1,playerSummary);
            setText('');
            setCount(playerSummary.length);
            return;
        }
        else if(valore.length > 2){
            //Applico eventuali filtri            
            findPlayersByFilters({
                "tournament":selectedTournament[0],
                "team":selectedTeam[0],
                "nation":selectedNation[0],
                "selectedRole":selectedRole,
                "text":valore,
                "player":selectedPlayer
            });
            setPage(1);
            setText(valore);
            setSelectedPlayer(null);
            setOpenAutocomplete(false);
        }
    };

    const resetSearch = _ => {
        setPage(1);
        filterPlayers(1,playerSummary);
        setText('');
        setSelectedPlayer(null);
        setCount(playerSummary.length);
        return;   
    };

    const handleChangePage = (event, newPage) => {
        //Se c'è un testo di ricerca devo rifiltrare l'elenco dei calciatori
        const newList = text && text !== '' ? filterByText(playerSummary,text) : playerSummary;       
        setPage(newPage);
        filterPlayers(newPage, newList);
    };

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const applyFilters = () => {       
        findPlayersByFilters({
            "tournament":selectedTournament[0],
            "team":selectedTeam[0],
            "nation":selectedNation[0],
            "selectedRole":selectedRole,
            "text":text,
            "player":selectedPlayer
        });
    };

    const resetAllFilters = (event) => {
        //Resetto eventuali parametri di querystring (caso in cui si arriva da una pagina profilo)
        searchParams.set('to', []);
        searchParams.set('te', []);
        setSearchParams(searchParams);
        setOpenAutocomplete(false);
        //Resetto i filtri
        resetFilter(playerSummaryAll);                    
    };

    return(
        <Stack>
            {/* Modale per utente non loggato */}
            <Modal
                open={openModal && showMessageNotLogged}
                onClose={handleCloseModal}
            >
                <Box><MessageHeaderLogin onClose={handleCloseModal} /></Box>
            </Modal>
            {/** Titolo pagina */}
            <Box sx={{
                width:'100%',
                backgroundColor:theme.palette.custom.black,
                color:theme.palette.custom.white,
                padding:{xs:'10px 20px', md:'10px 90px',sm:'10px 40px',tb:'10px 40px'}
            }}>
                <Typography variant='pageTitle'>{t('global.players')}</Typography>
            </Box>        
            <Stack
                direction={{xs:'column',md:'row',sm:'column',tb:'column'}}
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{padding:{xs:'40px 0px', md:'40px 90px',sm:'40px 40px',tb:'40px 40px'}}}
            >                
                {/* Sezione sx */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    sx={{
                        width:{xs:'100%',md:'70%',sm:'100%',tb:'100%'},
                        paddingRight:{xs:'0',md:'30px',sm:'0px'},
                        minHeight:'100vh'
                    }}
                >                         
                    {loadingSummary && (
                    <Box sx={{width:'100%',textAlign:'center'}}>
                        <CircularProgress sx={{color:theme.palette.primary.main}} size={130} />
                    </Box>
                    )}
                    {!loadingSummary && (
                    <Stack 
                        direction="column" 
                        justifyContent="flex-start" 
                        alignItems="flex-start" 
                        spacing={2}
                        sx={{width:'100%'}}
                    >
                        <Stack 
                            direction="row" 
                            justifyContent="flex-start" 
                            alignItems="center" 
                            spacing={0}
                            sx={{paddingLeft:{xs:'20px',md:'0',sm:'0'}}}
                        >
                            <Autocomplete 
                                id="textfind"
                                variant="search"
                                sx={{width:{xs:270,md:550,sm:450}}}
                                freeSolo
                                disabled={!userLogged}
                                value={text}
                                open={openAutocomplete}
                                loading={loadingSummary}
                                options={playerSummary ? playerSummary : null}
                                getOptionLabel={(option) => {
                                    if(option.first_name) 
                                        return option.first_name + " " + option.last_name + " (" + option.team_name + ")";
                                    else
                                        return option;
                                }}
                                onInputChange={onInputChange}
                                onChange={(event, newValue) => {handleNewValue(event, newValue)}}                        
                                renderInput={(params) =>(
                                    <TextField                                        
                                        {...params}                                
                                        label={userLogged ? t("pagine.players.searchtext") : t("messages.logforuse")}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment:(
                                                <Fragment>
                                                    {loadingSummary ? <CircularProgress sx={{color:theme.palette.primary.main}} size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            )
                                        }}                                        
                                    />
                                )}
                            />
                            <IconButton onClick={onClickSearch} disabled={!userLogged}>
                                <PersonSearchIcon sx={{color:theme.palette.custom.blueIcon,fontSize:'50px'}} />
                            </IconButton>
                        </Stack>
                        {/* Filtri */}
                        {!loadingFilter && (
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                            sx={{width:'100%'}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="flex-start" 
                                alignItems="center" 
                                spacing={{xs:4,md:4,sm:4}}
                                sx={{width:{xs:'100%',md:'80%',sm:'100%',tb:'70%'},paddingLeft:{xs:'20px',md:'0',sm:'0'}}}
                            >                                                        
                                {/* Box FIltri */}
                                <Box>
                                    <Button
                                        size='large'
                                        startIcon={<TuneIcon />}
                                        disabled={!userLogged}
                                        onClick={handleFilterClick}
                                        sx={{color:existFilters ? theme.palette.primary.main : theme.palette.custom.blueIcon}}
                                    >
                                        {t("global.filters")}
                                    </Button>
                                    <Popover
                                        anchorEl={anchorEl}
                                        open={openFilter}
                                        onClose={handleFilterClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                    >                                                           
                                        <Filters 
                                            listTournament={listTournament}
                                            listNations={listNations}                                            
                                            applyFilters={applyFilters}
                                            handleFilterClose={handleFilterClose}                                        
                                        />
                                    </Popover>
                                </Box>
                                {/* Reset filtri */}
                                <Button
                                    size='large'
                                    startIcon={<FilterListOffIcon />}
                                    disabled={!userLogged}
                                    onClick={resetAllFilters}
                                    sx={{color:theme.palette.custom.blueIcon}}
                                >
                                    {t('global.resetfilter')}
                                </Button>
                            </Stack>
                            {existFilters && (
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="baseline"
                                    spacing={0.5}        
                                    sx={{paddingLeft:{xs:'20px',md:'0px',sm:'10px'}}}                        
                                >
                                    <SubdirectoryArrowLeftIcon sx={{rotate:'90deg',color:theme.palette.primary.main}} />
                                    <Typography variant='textBase' sx={{color:theme.palette.primary.main}}>
                                        {t("pagine.players.editFilters")}
                                    </Typography>
                                    <SubdirectoryArrowRightIcon sx={{rotate:'270deg',color:theme.palette.primary.main}} />
                                </Stack>
                            )}
                        </Stack>
                        )}
                        {/* Numero giocatori trovati */}
                        {!loadingFilter && (
                        <Stack 
                            spacing={2}
                            sx={{width:'100%',paddingLeft:{xs:'20px',md:'0',sm:'0'}}}
                        >
                            <Typography
                                variant='textBase'
                            >
                                {count === 1 ? 
                                    count + " " + t("pagine.players.playerfound") : 
                                    count + " " + t("pagine.players.playersfound")
                                } 
                            </Typography>
                            <Pagination 
                                count={Math.trunc(count % rowsPerPage === 0 ? (count / rowsPerPage) : (count / rowsPerPage) + 1)} 
                                page={page} 
                                onChange={handleChangePage}
                                variant="outlined" 
                                siblingCount={0}
                                color="primary"
                            />                    
                        </Stack>
                        )}                        
                        <Box sx={{width:{xs:'100%',md:'70%',sm:'80%',tb:'70%'}}}>
                            
                            <Backdrop open={loadingFilter}>
                                <Box sx={{width:'100%',textAlign:'center'}}>
                                    <CircularProgress sx={{color:theme.palette.primary.main}} size={130} />
                                </Box>
                            </Backdrop>
                            
                            {!loadingFilter  && <PlayersListTable players={listPlayers} viewTeam={true}  />}
                        </Box>
                        {!loadingFilter && (
                        <Box sx={{margin:'20px 0 !important'}}>
                            <Pagination 
                                count={Math.trunc(count % rowsPerPage === 0 ? (count / rowsPerPage) : (count / rowsPerPage) + 1)} 
                                page={page} 
                                onChange={handleChangePage}
                                variant="outlined" 
                                siblingCount={0}
                                color="primary"
                            />  
                        </Box>
                        )}
                    </Stack>
                    )}
                </Stack>

                {/* Sezione dx */}
                <Stack
                    direction="column"
                    justifyContent={{xs:'center',md:'center',sm:'flex-start',tb:'flex-start'}}
                    alignItems={{xs:'center',md:'center',sm:'flex-start',tb:'flex-start'}}
                    spacing={1}
                    sx={{width:{xs:'100%',md:'30%',sm:'100%',tb:'100%'}}}
                >
                    {/* Social */}
                    <SocialMediaShare 
                        url={window.location.href} 
                        quote={t("social.share.playernoname")}
                        hashtag={[t("social.hashtag.calciomercato"),t("social.hashtag.calcio")]}
                        propsadd={{marginBottom:'20px'}}
                    />

                    {/* Banner */}
                    <Box 
                        sx={{
                            paddingLeft:{xs:'0 !important',md:'0 !important',sm:'90px !important',tb:'130px !important'},
                            textAlign:{xs:'center',md:'center',sm:'left',tb:'left'},
                            width:'100%'
                        }}
                    >
                        <Banner type={'300x300_3'} />
                    </Box>

                    {/* Altre squadre della stessa lega */}
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            width:{xs:'100%',md:'80%',sm:'70%',tb:'60%'},
                            marginTop:{xs:'40px !important',md:'40px !important',sm:'40px !important'}
                        }}
                    >
                        <Typography variant='titleAside' sx={{fontSize:'21px !important'}}>
                            {parse(t("global.teams"))}
                        </Typography>
                        <Box sx={{width:'100%',textAlign:'right',margin:'-20px 0 !important',marginBottom:'0 !important'}}>
                            <Button 
                                variant='textAction'
                                endIcon={<KeyboardDoubleArrowRightIcon />}
                                href='/teams'
                            >
                                {t("global.veditutte")}
                            </Button>
                        </Box>
                        {loadingTeams && (<CircularProgress size={44} sx={{color:theme.palette.primary.main,marginTop:'20px !important'}} />)}
                        {!loadingTeams && (
                            <TeamListTable 
                                teamsList={
                                    allTeams.filter((t,index) => index >= indexRandom && index <= indexRandom + 4)        
                                } 
                                linkpage={true} 
                            />
                        )}
                    </Stack>

                    {/* Banner */}
                    <Box 
                        sx={{
                            paddingLeft:{xs:'0 !important',md:'0 !important',sm:'90px !important',tb:'130px !important'},
                            textAlign:{xs:'center',md:'center',sm:'left',tb:'left'},
                            width:'100%',
                            marginTop:'20px !important'
                        }}
                    >
                        <Banner type={'300x300_2'} />
                    </Box>

                    {/* Giocatori di maggior valore */}
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{width:{xs:'100%',md:'65%',sm:'70%'},marginTop:'40px !important'}}
                    > 
                        <Typography variant='titleAside'>{parse(t("pagine.players.othersplayers"))}</Typography>                                                                                  
                        <PlayersListTable 
                            players={playerSummaryAll.slice(0,5)} 
                            viewTeam={false} 
                            viewRole={false}
                        />          
                    </Stack>    
                    <Box sx={{marginTop:'30px !important'}}>
                        <Banner type={'300x300_1'} />
                    </Box>
                </Stack>
            </Stack>           
        </Stack>
    );
};

export default Players;