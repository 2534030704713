import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import HeaderPage from '../../components/HeaderPage';

const UserVerify = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const loading = useStoreState(state => state.user.loadingPage);
    const confirmEmail = useStoreActions(dispatch => dispatch.user.confirmEmail);
    const messageVerified = useStoreState(state => state.user.messageVerified);
    const [searchParams, setSearchParams] = useSearchParams();
    const bgImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/ball-bg.jpg";
    const bgPage = process.env.REACT_APP_PUBLIC_URL + "/assets/images/balls_bg.jpg";
    const isVerified = user !== null && user.verified;
    const titlepage = isVerified ? parse(t("formlogin.verifiedemail")) : parse(t("global.oops"));
    const subtitle = isVerified ? parse(t("formlogin.accountactive")) : parse(t("formlogin.accountcreatederror"));
    const icon = isVerified ? 
        <PersonPinIcon sx={{color:theme.palette.success.main, fontSize:'80px'}} /> : 
        <NoAccountsIcon sx={{color:theme.palette.error.main, fontSize:'80px'}} />;

    useEffect(
        _ => {
            confirmEmail(searchParams.get('verify_url'));
        },[]
    );

    return(
        <Stack>
            <HeaderPage title="formlogin.emailverifing" />            
            <Box sx={{
                    //backgroundImage: `url(${bgPage})`,
                    height:'100%',
                    minHeight:'400px'
                }} 
                className='bgFixed'
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        margin:{xs:'20px',md:'40px 90px'},                   
                        padding:'40px',                    
                    }}
                >
                    {loading && (
                        <CircularProgress sx={{color:theme.palette.primary.main}} />
                    )}
                    {!loading && (
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        >
                            {icon}
                            <Typography variant='titleSection'>
                                {titlepage}
                            </Typography>
                            <Typography variant='titleSection' sx={{fontSize:{xs:'18px',md:'20px'}}}>
                                {subtitle}
                            </Typography>                        
                            <Typography sx={{textAlign:'center',marginTop:'20px !important'}}>
                                {parse(t(messageVerified))}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Stack>
    );
};

export default UserVerify;