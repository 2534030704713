import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { CircularProgress, Popover, Chip, Stack, Box, Button, Typography, Paper } from '@mui/material';

const PopoverDesc = props => {
    const {open,anchorEl,handleClose, icon, title, what, desc} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    return(
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            disablePortal={true}
        >
            <Paper 
                elevation={24} 
                sx={{
                    maxWidth:{xs:'340px',md:'500px',sm:'450px'},
                    borderWidth:'1px',
                    borderStyle:'solid',
                    borderColor:theme.palette.custom.blueIcon
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    sx={{
                        padding:'10px 15px'
                    }}
                >
                    <Chip 
                        variant='boxLight'
                        icon={icon}
                        label={
                            <Typography 
                                variant='textRound' 
                                sx={{
                                    fontWeight:'600',
                                    fontSize:{xs:'18px !important',md:'18px !important',sm:'18px !important'}
                                }}
                            >
                                {parse(t("global." + title))}
                            </Typography>
                        }
                    />
                    <Typography 
                        variant='text'
                        sx={{
                            fontSize:{xs:'18px',md:'18px',sm:'18px'},
                            fontWeight:'600'
                        }}
                    >
                        {parse(t("global." + what))}
                    </Typography>                    
                    <Typography variant='text'>
                        {parse(t("global." + desc))}
                    </Typography>
                    <Stack
                        direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}
                        sx={{width:'100%'}}
                    >
                        <Box sx={{width:'50%'}}>
                            <Button 
                                variant='textAction'
                                href='/detail/#indici'
                                sx={{color:theme.palette.custom.orange}}
                            >
                                {t("global.scopri")}
                            </Button>
                        </Box>
                        <Box sx={{width:'50%',textAlign:'right'}}>
                            <Button 
                                variant='textAction' 
                                onClick={handleClose}
                                sx={{color:theme.palette.custom.orange}}
                            >
                                {t("global.close")}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </Paper>
        </Popover>
    );
};

export default PopoverDesc;