import React, {useState} from 'react';
import {Popover,Stack, Badge, Avatar, Box, Typography} from '@mui/material';
import PLayerBox from '../PlayerBox/index.js';
import './styles.css'

const PlayerPin = props => {
    const { player, color, letter, avatarType } = props;    
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/" + player.player_id + ".jpg";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/noImg.jpg";
    const playerName = player.last_name;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? player.player_id : undefined;
    const avatarVariant = avatarType ? avatarType : 'playerAvatar';

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Box variant='playerPin' sx={{cursor:'pointer'}} aria-describedby={id} onClick={handleClick}>
                <Stack direction="column">
                    <Box sx={{display:'inline',textAlign:'center'}}>
                        <Badge                    
                            overlap='circular'
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}                    
                            badgeContent={
                                <Avatar variant='smallAvatar' sx={{ backgroundColor:`${color}`}}>
                                    {letter}
                                </Avatar>
                            }
                        >
                            <Avatar 
                                variant={avatarVariant}
                                alt={playerName} 
                                src={imgPath} 
                                onError={(e)=>{e.target.onError=null; e.target.src=noImg}}                                
                            />
                        </Badge>
                    </Box>
                    <Box sx={{backgroundColor:'#00000070',padding:'2px 5px',maxWidth:'90px'}}>
                        <Typography 
                            variant='playerAvatarText' 
                            sx={{color:'#fff'}}
                            component="p"
                        >
                            {playerName}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            <Popover
                id={player.player_id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',                
                }}
                disablePortal={true}
            >                
                <Box sx={{width:{xs:'340px',md:'380px',sm:'380px'},background:'none'}}>
                    <PLayerBox player={player} />
                </Box>
            </Popover>
        </div>
    );
};

export default PlayerPin;