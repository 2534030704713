import getPlayer from "./get-player";
import playerSummary from "./player_summary";
import getPlayerInteam from "./get-player-inteam";
import getPlayerCalcData from "./get-player-calc-data";
import getPlayerOverview from "./get-player-overview";
import findPlayer from "./find-player";
//import searchPlayers from "./search-players";

export default {
    getPlayer,
    playerSummary,
    getPlayerInteam,
    getPlayerCalcData,
    getPlayerOverview,
    findPlayer
  //  searchPlayers
};
