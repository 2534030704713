import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Box, Typography, LinearProgress, linearProgressClasses } from '@mui/material';

const LinearProgressValue = props => {
    const {LinearProgressProps, value, max, min, viewLimits} = props;
    const theme = useTheme();
    const user = useStoreState(state => state.user.user);
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';

    return (
        <Stack 
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            sx={{marginTop:'10px',width:'100%'}}
        > 
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress 
                    variant="determinate" 
                    value={parseFloat(value)} 
                    {...LinearProgressProps} 
                    sx={{
                        height:'10px',
                        borderRadius:'5px',
                        [`&.${linearProgressClasses.colorPrimary}`]:{
                            backgroundColor:theme.palette.custom.grey
                        }
                    }}
                />
            </Box> 
            {viewLimits && (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{width:'100%'}}
            >
                <Typography
                    variant='textSmall'
                >
                    {min}
                </Typography>
                <Typography
                    variant='textSmall'
                >
                    {max}
                </Typography>
            </Stack>
            )}
        </Stack>
    );
};

export default LinearProgressValue;