import React, {useEffect} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import Typography from '@mui/material/Typography';
import { CircularProgress, Chip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import kriptTeamId from '../Utility/kriptTeamId';
import getRuolo from '../PlayerRole/getRuolo';
import getColor from '../PlayerRole/getColor';

const PositionBox = props => {
    const {view, color, ml, wd} = props;
    const marginLeft = ml ? ml : 0;
    const widht = wd ? wd : 20;
    return(        
        <Box sx={{
            width:{xs:'50px',md:'70px',sm:'55px'},            
            marginLeft:`${marginLeft}px`,
            }}
        >
            {view && (
            <PersonPinCircleIcon 
                sx={{
                    color:'#fff',
                    backgroundColor:[color],
                    borderRadius:'100%',
                    fontSize:{xs:'20px',md:'26px',sm:'20px'},
                }} 
            />
            )}
        </Box>
    );
};

const PositionDesc = props => {
    const {color, label, big} = props;
    const dimensione = big ? '20px' : '10px'
    const size = big ? '18px' : '14px';
    return(
        <Stack 
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >           
            <Avatar variant='square' 
                sx={{ 
                    backgroundColor:`${color}`,
                    width: dimensione,
                    height: dimensione,
                    padding: '9px',
                    fontWeight: '600',
                    fontSize:{xs:'14px',md:'14px',sm:'14px'},
                    borderRadius:'100%'
                }}>
                {label.substring(0,1)}
            </Avatar>
            <Typography 
                variant='textBase'
                sx={{
                    color:'#333',
                    fontSize:size,
                    fontWeight:'600',
                    paddingLeft:'2px',
                    paddingRight:'12px',
                    color:color
                }}
            >
                {label}
            </Typography>
        </Stack>
    );
};

const FieldPosition = props => {
    const {player} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loading = useStoreState(state => state.players.getPlayer.loading);
    const fieldBGImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/field_horizzontal_flat.png";
    const playedPositions = player.played_positions != null ? player.played_positions : '';
    const positionText = player.position_text !=  null ? player.position_text : '';
    const ruolo = getRuolo(player);
    const color = getColor(ruolo);
    const gk = positionText == 'Goalkeeper' || playedPositions.includes('-GK-');
    const dr = (positionText == 'Defender') && (playedPositions.includes('-DR-') || playedPositions.includes('-MR-'));
    const dc = (positionText == 'Defender') && (playedPositions.includes('-DC-') || playedPositions.includes('-Mc-'));
    const dl = (positionText == 'Defender') && (playedPositions.includes('-DL-') || playedPositions.includes('-ML-'));
    const cr = (positionText !== 'Defender') && (playedPositions.includes('-DR-') || playedPositions.includes('-MR-'));
    const cc = (positionText !== 'Defender') && 
        (playedPositions.includes('-DC-') || playedPositions.includes('-MC-') || playedPositions.includes('-DMC-'));
    const cl = (positionText !== 'Defender') && (playedPositions.includes('-DL-') || playedPositions.includes('-ML-'));
    const cor = playedPositions.includes('-AMR-');
    const coc = playedPositions.includes('-AMC-');
    const col = playedPositions.includes('-AML-');
    const ar = positionText == 'Forward' && playedPositions.includes('-AMR-');
    const ac = playedPositions.includes('-FW-') || ((positionText == 'Forward') && (!playedPositions || playedPositions == null));
    const al = positionText == 'Forward' && playedPositions.includes('-AMR-');
    const title = parse(t("global.playerpositions")).split(" ");    
    const imgTeamPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/";
    const noImgTeam = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/noImg.png";
    const teamBaseUrl = '/team/';

    const onClickTeam = (teamId,teamName) => {
        window.location.href = teamBaseUrl + kriptTeamId(teamId) + '/' + teamName;
    };

    return(
        <Stack 
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Stack 
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{marginTop:'10px !important'}}                
            >                                
                <Stack 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    spacing={1}                    
                    sx={{cursor:'pointer',marginBottom:'0px !important',marginTop:'0px !important'}}                    
                >
                    <Box
                        component="img"                            
                        src={imgTeamPath + player.team_id + '.png'}
                        onError={(e)=>{e.target.onError=null; e.target.src=noImgTeam}}
                        sx={{
                            height:{xs:'36px',md:'36px',sm:'36px'},
                            width:{xs:'36px',md:'36px',sm:'36px'}                            
                        }}
                        onClick={_ => onClickTeam(player.team_id,player.team_name)}
                    />
                    <Typography                         
                        variant='textRound' 
                        sx={{
                            fontWeight:'600',
                            fontSize:{xs:'20px',md:'22px',sm:'18px'},
                            color:theme.palette.custom.black
                        }}
                        onClick={_ => onClickTeam(player.team_id,player.team_name)}
                    >
                        {player.team_name}
                    </Typography>
                </Stack>        
                <PositionDesc color={color} label={t("roles." + ruolo)} big={true} />        
            </Stack>
            {loading && (<CircularProgress sx={{color:theme.palette.primary.main}} />)}
            {!loading && (
            <Box
                sx={{
                    backgroundImage: `url(${fieldBGImg})`,
                    backgroundAttachment: 'inherit',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    padding: '0',
                    width: '100%',
                    height:{xs:'100px',md:'110px',sm:'100px'}                    
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{

                    }}
                >
                    <Stack 
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{height:'30px',marginTop:'-10px'}}
                    >
                        <PositionBox view={false} ml="10" />
                        <PositionBox view={dl} color={theme.palette.custom.de} ml="0" />
                        <PositionBox view={cl} color={theme.palette.custom.cc} ml="5" />
                        <PositionBox view={col} color={theme.palette.custom.co} ml="-10" />
                        <PositionBox view={false} color={theme.palette.custom.at} ml="-5" />
                    </Stack>

                    <Stack 
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{height:'30px',marginTop:'2px'}}
                    >
                        <PositionBox view={gk}  color={theme.palette.custom.gk} ml="40" />
                        <PositionBox view={dc} color={theme.palette.custom.de} ml="-10" />
                        <PositionBox view={cc} color={theme.palette.custom.cc} ml="20" />
                        <PositionBox view={coc} color={theme.palette.custom.co} ml="5" />
                        <PositionBox view={ac} color={theme.palette.custom.at} ml="15" />
                    </Stack>

                    <Stack 
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{height:'30px',marginTop:{xs:'5px',md:'15px',sm:'3px'}}}
                    >
                        <PositionBox view={false} ml="10" />
                        <PositionBox view={dr} color={theme.palette.custom.de} ml="0" />
                        <PositionBox view={cr} color={theme.palette.custom.cc} ml="40" />
                        <PositionBox view={cor} color={theme.palette.custom.co} ml="15" />
                        <PositionBox view={false} color={theme.palette.custom.at} ml="5" />
                    </Stack>
                </Stack>
            </Box>
            )}
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                >
                    <PositionDesc color={theme.palette.custom.gk} label={t("roles.Goalkeeper")} />
                    <PositionDesc color={theme.palette.custom.de} label={t("roles.Defender")} />
                    <PositionDesc color={theme.palette.custom.cc} label={t("roles.Midfielder")} />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                >                    
                    <PositionDesc color={theme.palette.custom.co} label={t("roles.Attackingmidfielder")} />
                    <PositionDesc color={theme.palette.custom.at} label={t("roles.Forward")} />
                </Stack>
            </Stack> 
        </Stack>
    );
};

export default FieldPosition;