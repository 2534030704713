import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Button from '@mui/material/Button';
import HeaderPage from '../../components/HeaderPage';


const PasswordReset = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loading = useStoreState(state => state.user.loading); 
    const messageResetPassword = useStoreState(state => state.user.messageResetPassword); 
    const setMessageResetPassword = useStoreActions(dispatch => dispatch.user.setMessageResetPassword);
    const changePassword = useStoreActions(dispatch => dispatch.user.changePassword);
    const [searchParams, setSearchParams] = useSearchParams();
    const bgPage = process.env.REACT_APP_PUBLIC_URL + "/assets/images/balls_bg.jpg";
    const bgImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/ball-bg.jpg";
    const fieldRequired = parse(t("formlogin.fieldrequired"));
    const passwordFormatError = parse(t("formlogin.errorformatpassword"));
    const passwordNotConfirmed = parse(t("formlogin.errorconfirmpassword"));
    
    const[values,setValues] = useState({
        password:'',         
        showPassword: false,
        passwordConfirm: '',
        passwordConfirm:'',
        errorPassword:'',
        errorPasswordConfirm:''
    });

    useEffect(() => {  
        setMessageResetPassword('');
    }, []);

    function isValidPassword(password){
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{8,}/;

        return password.length > 0 && 
            uppercaseRegExp.test(password) &&
            lowercaseRegExp.test(password) &&
            digitsRegExp.test(password) &&
            specialCharRegExp.test(password) &&
            minLengthRegExp.test(password);                
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });                
    };

    const handleSubmitPassword = (event) => {
        event.preventDefault();
        setMessageResetPassword('');
        //Verifico la struttura della password altrimenti bypassa il controllo
        values.errorPassword = !isValidPassword(values.password) ? passwordFormatError : '';
        values.errorPasswordConfirm = '';
        setValues({
            ...values,            
            errorPassword: values.password.trim() === '' ? fieldRequired : 
                !isValidPassword(values.password) ? passwordFormatError : '',
            errorPasswordConfirm: values.passwordConfirm.trim() === '' ? fieldRequired : 
                values.password !== values.passwordConfirm ? passwordNotConfirmed : '',
            errorPrivacy: !values.checked ? fieldRequired : ''
        });   
        if(values.errorPassword == '' && values.errorPasswordConfirm == '' &&
            values.password !== '' && values.passwordConfirm !== '' && 
            values.password === values.passwordConfirm 
        )
        {        
            changePassword({
                email: searchParams.get('email'),
                password: values.password,
                token: searchParams.get('tk'),
                lang: i18n.language
            });
        }
    };

    return(
        <Stack>            
            <Box sx={{                    
                    height:'100%',
                    minHeight:'400px'
                }} 
                className='bgFixed'
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        margin:{xs:'20px',md:'40px 90px'},                   
                        padding:'40px',                        
                    }}
                >
                    <Typography variant='titleSection' sx={{fontSize:{xs:'18px',md:'20px'},textAlign:'center'}}>
                        {parse(t("formlogin.insertnewpassword"))}
                    </Typography>
                    <Typography variant='textBase' sx={{textAlign:'center'}}>
                        {parse(t("formlogin.passwordformat"))}
                    </Typography>
                    <Stack 
                        spacing={1} 
                        sx={{
                                marginTop:'40px !important',
                                width:{xs:'100%',md:'50%'}
                            }}
                        >
                        <FormControl variant='standard'>
                            <Input
                                id="formrecovery-email"                                
                                value={searchParams.get('email')}       
                                disabled={true}                         
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                }
                                variant='formPage'                                
                            />                            
                        </FormControl>
                        <FormControl 
                            variant='standard' 
                            sx={{marginTop:'30px !important',color:`${theme.palette.error.main}`}}
                        >
                            <InputLabel htmlFor='formrecovery-password'>Password</InputLabel>
                            <Input
                                id="formrecovery-password"
                                aria-describedby="formrecovery-password-error"
                                type={'text'}
                                value={values.password}
                                onChange={handleChange('password')}
                                error={false}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <VpnKeyIcon />
                                    </InputAdornment>
                                }                                
                                variant='formPage'
                            />
                            <FormHelperText 
                                id="formrecovery-password-error"
                                variant='formValidate'
                            >
                                {values.errorPassword}
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant='standard' sx={{marginTop:'10px',color:`${theme.palette.error.main}`}}>
                            <InputLabel htmlFor='formrecovery-passwordconfirm'>
                                {parse(t("formlogin.passwordconfirm"))}
                            </InputLabel>
                            <Input
                                id="formrecovery-passwordconfirm"
                                aria-describedby="formrecovery-passwordconfirm-error"
                                type={'text'}
                                value={values.passwordConfirm}
                                onChange={handleChange('passwordConfirm')}
                                error={false}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <VpnKeyIcon />
                                    </InputAdornment>
                                }
                                variant='formPage'      
                            />
                            <FormHelperText 
                                id="formrecovery-passwordconfirm-error"
                                variant='formValidate'
                            >
                                {values.errorPasswordConfirm}
                            </FormHelperText>
                        </FormControl>
                        {loading && (<CircularProgress sx={{margin:'20px auto !important'}} />)}
                        {!loading && (
                            <Button 
                                variant="action" 
                                onClick={handleSubmitPassword}
                                sx={{width:{xs:'100%',md:'50%'},margin:'20px auto !important'}}
                            >
                                {parse(t("global.send"))}
                            </Button>
                        )}
                        <Typography 
                            variant='textBase' 
                            sx={{
                                marginTop:'20px',
                                textAlign:'center',
                                fontSize:'1.1em',
                                fontWeight: '600',
                                lineHeight: '1.2em',
                                color:`${theme.palette.custom.orange}`
                            }}
                        >
                            {messageResetPassword}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    );
};

export default PasswordReset;