import { Box } from "@mui/material";
import Manteinance from "./Manteinance";
import Home from "./Home";
import UserVerify from "./UserVerify";
import PasswordReset from "./PasswordReset";
import PlayerProfile from "./PlayerProfile";
import Players from "./Players";
import About from "./About";
import Detail from "./Detail";
import Teams from "./Teams";
import Team from "./Team";
import Top11 from "./Top11";
import Account from "./Account";
import PrivacyPolicy from "./Home/PrivacyPolicy";
import CookiePolicy from "./Home/CookiePolicy";
import PagesNotFound from "./PagesNotFound";
import Login from "./Login";
import Authorize from "./Authorize";
import EmailVerified from "./EmailVerified";
import Logout from "./Logout";

const manteninaceRoute = [
    {
        id: 'manteinance',
        path: '/',
        component: <Manteinance/>,
        exact: '/'
    }
];

const siteRoutes = [
    {
        id: 'home',
        path: '/',
        component: <Home/>,
        exact: true
    },
    {
        id: 'userVerify',
        path: '/verify',
        component: <UserVerify/>,
        exact: true
    },
    {
        id:'passwordreset',
        path: '/passwordreset',
        component: <PasswordReset />,
        exact: true
    },
    {
        id:'account',
        path:'/account',
        component:<Account />,
        exact: true
    },
    {
        id:'login',
        path:'/login',
        component:<Login />,
        exact: true
    },
    {
        id:'authorize',
        path:'/authorize',
        component:<Authorize />,
        exact:true
    },
    {
        id:'emailverify',
        path:'/emailverify',
        component:<EmailVerified />,
        exact:true
    },
    {
        id:'logout',
        path:'/logout',
        component:<Logout />,
        exact:true
    },
    {
        id: 'player',
        path: '/player/:playerId/:name',
        component:<Box sx={{width:'100%',minHeight:'85vh'}}><PlayerProfile /></Box>,
        exact: true
    },
    {
        id: 'players',
        path: '/players',
        component:<Box sx={{width:'100%',minHeight:'85vh'}}><Players /></Box>,
        exact: true
    },
    {
        id: 'about',
        path: '/about',
        component: <About />,
        exact: true
    },
    {
        id: 'detail',
        path: '/detail',
        component: <Detail />,
        exact: true
    },
    {
        id: 'teams',
        path: '/teams',
        component:<Box sx={{width:'100%',minHeight:'85vh'}}><Teams /></Box>,
        exact: true
    },
    {
        id: 'team',
        path: '/team/:teamId/:name',
        component:<Box sx={{width:'100%',minHeight:'85vh'}}><Team /></Box>,
        exact: true
    },
    {
        id: 'top11',
        path: '/top11',
        component: <Box sx={{width:'100%',minHeight:'85vh'}}><Top11 /></Box>,
        exact: true
    },
    {
        id:'privacypolicy',
        path:'/privacy-policy',
        component: <PrivacyPolicy />,
        exact: true
    },
    {
        id:'cookiepolicy',
        path:'/cookie-policy',
        component: <CookiePolicy />,
        exact: true
    },
    {
        id: 'notfound',
        path: '*',
        component: <PagesNotFound />,
        exact: false
    }
];

export default viewSite => {
    const routes = viewSite ? siteRoutes : manteninaceRoute;
    return routes;
};