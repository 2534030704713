import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { CircularProgress, Popover, Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import CircularStats from '../CircularStats';
import MessageLogin from '../MessageLogin';
import PopoverDesc from '../PopoverDesc';

const PlayerDeviation = props => {
    const {userLogged} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loadingStats = useStoreState(state => state.players.getPlayer.loadingStats);
    const player = useStoreState(state => state.players.getPlayer.playerDetailStats);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <Box>
            <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="center">
                <SocialDistanceIcon sx={{color:theme.palette.primary.main,fontSize:'30px'}} />
                <Box>
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'20px',md:'20px',sm:'20px'},
                            color:theme.palette.custom.black,
                            fontWeight:'600'                   
                        }}
                    >
                        {t("global.indexof") + " " + t("global.deviation")}
                    </Typography>                      
                </Box>
            </Stack>
            <Box sx={{width:'100%'}}>
                <Button 
                    variant='textLink'
                    onClick={handleClick}
                    sx={{fontSize:{xs:'15px',md:'15px',sm:'15px'}}}
                >
                    {parse(t("global.deviationindexwhat"))}
                </Button>
                <PopoverDesc 
                    open={open} 
                    anchorEl={anchorEl} 
                    handleClose={handleClose} 
                    icon={<SocialDistanceIcon sx={{fontSize:'30px !important'}} />}
                    title={"deviation"}
                    what={"deviationindexwhat"}
                    desc={"deviationindexdesc"}
                />
            </Box>
            {loadingStats && (
                <Box sx={{padding:'10px auto',textAlign:'center',background:'#fff'}}>
                    <CircularProgress sx={{color:theme.palette.primary.main}} />
                </Box>
            )}
            {!userLogged && (
                <MessageLogin data={t("messages.values")} color={theme.palette.custom.black} />
            )}
            {!loadingStats && (
            <Stack
                direction={{xs:'column',md:'row',sm:'row'}}
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
                sx={{                    
                    //background:'#fff',
                    padding:'20px 0 0 0'           
                }}
            >
                <Stack 
                    direction="row"
                    spacing={2}
                    justifyContent={{xs:"center",md:"flex-end",sm:"flex-end"}}
                    alignItems="center"
                    sx={{width:{xs:'100%',md:'50%',sm:'50%'}}}
                >
                    <CircularStats 
                        value={userLogged && player ? parseFloat(player.normalized_score_deviation,10) : 0} 
                        label={t("coefficients.score")}
                        textColor={userLogged && player ? theme.palette.custom.black : theme.palette.custom.grey}
                    />
                    <CircularStats 
                        value={userLogged && player ? parseFloat(player.normalized_pass_deviation,10) : 0} 
                        label={t("coefficients.pass")}
                        textColor={userLogged && player ? theme.palette.custom.black : theme.palette.custom.grey}
                    />
                </Stack>
                <Stack 
                    direction="row"
                    spacing={2}
                    justifyContent={{xs:"center",md:"flex-start",sm:"flex-start"}}
                    alignItems="center"
                    sx={{width:{xs:'100%',md:'50%',sm:'50%'}}}
                >
                    <CircularStats 
                        value={userLogged && player ? parseFloat(player.normalized_defence_deviation,10) : 0} 
                        label={t("coefficients.defence")}
                        textColor={userLogged && player ? theme.palette.custom.black : theme.palette.custom.grey}
                    />
                    <CircularStats 
                        value={userLogged && player ? parseFloat(player.normalized_save_deviation,10) : 0} 
                        label={t("coefficients.save")}
                        textColor={userLogged && player ? theme.palette.custom.black : theme.palette.custom.grey}
                    />
                </Stack>
            </Stack>
            )}
        </Box>
    );
};

export default PlayerDeviation;