import { useEffect } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from 'react-router-dom';
import { Stack, CircularProgress } from "@mui/material";

const Logout = props => {
    const theme = useTheme();    
    const user = useStoreState(state => state.user.user);
    const logout = useStoreActions(dispatch => dispatch.user.logout);
    const completeLogout = useStoreActions(dispatch => dispatch.user.completeLogout);
    const domain = process.env.REACT_APP_AUTH_DOMAIN;
    const redirect_user_loggout = process.env.REACT_APP_PUBLIC_URL + '/logout?code=executed';
    const redirect_user_page = process.env.REACT_APP_PUBLIC_URL;
    const client_id = process.env.REACT_APP_AUTH_CLIENT_ID;
    const idToken = user.idToken;
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');

    useEffect(() => {
        //Se l'utente è giù sloggato o se ritorno dal logout di Auth0 ripulisco i dati utente in cache
        if(!user.logged && !user.token && !user.idToken){            
            completeLogout();            
        }
        //Se l'utente è loggato in PlayerStar e in AUth0 sloggo in PlayerStar
        else if (user.logged && user.token && user.idToken) {
            logout();
        }
        //Se ritorno dal logout di AUth0 ripulisco i dati utente
        else if(!user.logged && !user.token && user.idToken && code == 'executed'){
            completeLogout();
        }
    },[]);   

    useEffect(() => {
        //Se l'utente non è loggato in PlayerStar ed è loggato in Auth0 sloggo in Auth0
        if (!user.logged && !user.token && user.idToken){
            window.localStorage.clear();
            const logoutUrl = `${domain}/oidc/logout?post_logout_redirect_uri=${redirect_user_loggout}&client_id=${client_id}&id_token_hint=${idToken}`;
            window.location.href = logoutUrl;              
        }
        //Se ritorno dal completeLogout redirigo
        if(!user.logged && !user.token && !user.idToken){      
            window.location.href = redirect_user_page;
        }
    },[user]);

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            sx={{width:'100%',minHeight:'100vh'}}
        >
            <CircularProgress sx={{color:theme.palette.primary.main}} size="80px" />
        </Stack>
    );
};

export default Logout;