import { action, actionOn, thunk, thunkOn } from 'easy-peasy';

export default {   
    loading:false,
    setLoading:action((state,payload) => {
        state.loading = payload;
    }),
    managementToken:null,
    setManagementToken:action((state,payload) => {
        state.managementToken = payload;
    }),
    sendEmailMessage: '',
    setSendEmailMessage: action((state,payload) => {
        state.sendEmailMessage = payload;
    }),
    lastToken: null,
    setLastToken: action((state,payload) => {
        state.lastToken = payload;
    }),
    requestToken: thunk((actions, payload, { injections, getStoreActions, getState }) => {
        const { services } = injections;        
        return services.auth.requestToken(payload)
            .then(data => {                               
                getStoreActions().user.setToken(data);
                actions.setLastToken(data.access_token);
                //return Promise.resolve(data);
            })
            .catch(e => {
                console.log("LOGIN ERROR",e);
            })
    }),
    requestManagementToken: thunk((actions, payload, { injections, getStoreActions, getState }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.auth.requestManagementToken()
            .then(data => {
                actions.setManagementToken(data.access_token);
            })            
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    sendEmailVerify: thunk((actions, payload, { injections, getStoreActions, getState }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.auth.requestManagementToken()
            .then(data => {                
                services.auth.sendEmailVerifiy({token:data.access_token,userId:payload})
                    .then(data => {
                        actions.setSendEmailMessage('formlogin.emailverifingsended')
                    })
                    .catch(e => {
                        actions.setSendEmailMessage('formlogin.resendemailconfirmerror');
                    })
            })
            .catch(e => {
                actions.setSendEmailMessage('formlogin.resendemailconfirmerror');
            })
            .finally(_ => {
                actions.setLoading(false);
            });        
    })
};