import store from "../../store";

export default _ => {
    const { user: {
            user
        } 
    } = store.getState();

    const token = user != null && user.token !== null ? user.token : '';

    //Per l'autenticazione con Auth0 il bearer token va inserito solo se esiste
    const h =  token != '' ? 
    {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`
        
    } :
    {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    return h;
};