import React from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { FacebookIcon, FacebookShareButton, EmailIcon, EmailShareButton, TwitterIcon, TwitterShareButton,
LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon } from 'react-share';
import { Stack, Typography, Box } from '@mui/material';

const SocialMediaShare = props => {
    const {url, quote, hashtag, media, propsadd} = props;
    const theme = useTheme();
    const {t} = useTranslation('common');
    const size = 42;

    const social = [
        {
            id:'Whatsapp',
            comp: <WhatsappShareButton url={url} title={quote}>
                <WhatsappIcon round size={42} />
            </WhatsappShareButton>
        },
        {
            id:'facebook',
            comp: <FacebookShareButton url={url} quote={quote} hashtag={hashtag[0]}>
                    <FacebookIcon round size={size}/>
                </FacebookShareButton>
        },
        {
            id:'twitter',
            comp: <TwitterShareButton url={url} quote={quote} hashtag={hashtag}>
                    <TwitterIcon round size={size} />
                </TwitterShareButton>
        },
        {
            id:"linkedin",
            comp: <LinkedinShareButton url={url} quote={quote}><LinkedinIcon round size={size}/></LinkedinShareButton>
        },
        {
            id:"telegram",
            comp:<TelegramShareButton url={url} quote={quote}><TelegramIcon round size={size}/></TelegramShareButton>
        },
        {
            id:"email",
            comp: <EmailShareButton url={url} subject={quote}><EmailIcon round size={size} /></EmailShareButton>
        }
    ];

    return (
        <Stack 
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{...propsadd}}
        >                   
            {social.map((el,index) => (
                <Box key={el.id}>{el.comp}</Box>                
            ))}
        </Stack>
    );
};

export default SocialMediaShare;