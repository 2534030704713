import getPlayer from './get-palyer';
import playerSummary from './player_summary';
import getPlayerInTeam from './get-player-inteam';
import getPlayerInfoBase from './get-player-info-base';
import getPlayerStats from './get-player-stats';
import getPlayerDetailStats from './get-player-detail-stats';
import getPlayerCalcData from './get-player-calc-data';
import getPlayerOverview from './get-player-overview';
import findPlayers from './find-players';
import findPlayersByteam from './find-players-byteam';
import getAllPlayersSummary from './get-all-players-summary';
import findPlayersBytournament from './find-players.bytournament';
import getPlayersBytournament from './get-players-bytournament';
import findPlyaresByfilters from './find-plyares-byfilters';
import getPlayersLimits from './get-players-limits';
import getPlayerTrand from './get-player-trand';

export default {
    getPlayer,
    playerSummary,
    getPlayerInTeam,
    getPlayerInfoBase,
    getPlayerStats,
    getPlayerDetailStats,
    getPlayerCalcData,
    getPlayerOverview,
    findPlayers,
    findPlayersByteam,
    getAllPlayersSummary,
    findPlayersBytournament,
    getPlayersBytournament,
    findPlyaresByfilters,
    getPlayersLimits,
    getPlayerTrand
};