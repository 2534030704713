import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme, styled } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { CircularProgress, Popover } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import MessageLogin from '../MessageLogin';
import PopoverDesc from '../PopoverDesc';

const LinearProgressWithLabel = props => {
    const {LinearProgressProps , value, row } = props;
    const theme = useTheme();
    const valueText = value + row.suffix;
    return(
        <Stack 
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            sx={{marginTop:'10px'}}
        >
            <Stack 
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{width:'100%'}}
            >
                <Box 
                    sx={{
                        width:{xs:'80%',md:'90%',sm:'90%'},
                        fontSize:{xs:'17px',md:'16px',sm:'17px'},
                        "&::first-letter":{
                            textTransform:'uppercase'
                        }
                    }}
                >
                    {row.parameter}
                </Box>
                <Box 
                    sx={{
                        width:{xs:'20%',md:'10%',sm:'10%'},
                        fontSize:{xs:'17px',md:'16px',sm:'17px'},
                        textAlign:'right'
                    }}
                >
                    {valueText}
                </Box>
            </Stack>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress 
                    variant="determinate" 
                    value={parseFloat(value)} 
                    {...LinearProgressProps} 
                    sx={{
                        height:'10px',
                        borderRadius:'5px',
                        [`&.${linearProgressClasses.colorPrimary}`]:{
                            backgroundColor:theme.palette.custom.grey
                        }
                    }}
                />
            </Box>  
        </Stack>
    );
};

const PlayerOverview = props => {
    const {userLogged, ruolo} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loadingOverview = useStoreState(state => state.players.getPlayerOverview.loadingOverview);
    const player = useStoreState(state => state.players.getPlayerOverview.playerOverview);
    const titleWords = t("global.overviewindex").split(" ");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const rowsOver = [
        {
            "parameter":t("coefficients.presence"),
            "value":userLogged && player && parseFloat(player.team_mins_played) > 0 ? 
                parseFloat((player.mins_played/player.team_mins_played)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"All"
        },
        {
            "parameter":t("coefficients.saveOutbox"),
            "value":userLogged && player && parseFloat(player.save_total) > 0 ?
                parseFloat((player.save_obox/player.save_total)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"Goalkeeper"
        },
        {
            "parameter":t("coefficients.savePenaltyArea"),
            "value":userLogged && player && parseFloat(player.save_total) > 0 ?
                parseFloat((player.save_penalty_area/player.save_total)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"Goalkeeper"
        },
        {
            "parameter":t("coefficients.saveSixYardBox"),
            "value":userLogged && player && parseFloat(player.save_total) > 0 ?
                parseFloat((player.save_six_yard_box/player.save_total)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"Goalkeeper"
        },
        {
            "parameter":t("coefficients.shhotingeffectiveness"),
            "value":userLogged && player && parseFloat(player.shots_total) > 0 ?
                parseFloat((player.goal_total/player.shots_total)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"All"
        },
        {
            "parameter":t("coefficients.assistfrequency"),
            "value":userLogged && player && parseFloat(player.pass_total) > 0 ?
                parseFloat((player.assist/player.pass_total)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"All"
        },
        {
            "parameter":t("coefficients.passeffectiveness"),
            "value":userLogged && player && parseFloat(player.pass_total) > 0 ?
                parseFloat((player.key_passes_total/player.pass_total)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"All"
        },
        {
            "parameter":t("coefficients.tackleeffectiveness"),
            "value":userLogged && player && parseFloat(player.tackle_total_attempted) > 0 ?
                parseFloat((player.tackle_won_total/player.tackle_total_attempted)*100).toFixed(2) : "0",
            "suffix":"%",
            "role":"All"
        }        
    ];

    const rows = ruolo !== 'Goalkeeper' ? rowsOver.filter(r => r.role !== 'Goalkeeper') : rowsOver;

    return(
        <Box>
            <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="flex-start">
                <StackedLineChartIcon sx={{color:theme.palette.primary.main,fontSize:'30px'}} />
                <Box>
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'22px',md:'22px',sm:'22px'},
                            color:theme.palette.custom.black,
                            fontWeight:'600'                   
                        }}
                    >
                        {t("global.overviewindex")}
                    </Typography>                    
                </Box>
            </Stack>
            <Box sx={{width:'100%'}}>
                <Button 
                    variant='textLink'
                    onClick={handleClick}
                    sx={{fontSize:{xs:'15px',md:'15px',sm:'15px'}}}>
                    {parse(t("global.overviewwhat"))}
                </Button>
                <PopoverDesc 
                    open={open} 
                    anchorEl={anchorEl} 
                    handleClose={handleClose} 
                    icon={<StackedLineChartIcon sx={{fontSize:'30px !important'}} />}
                    title={"overviewindex"}
                    what={"overviewwhat"}
                    desc={"overviewdesc"}
                />
            </Box>
            {loadingOverview && (
                <Box sx={{padding:'10px auto',textAlign:'center'}}>
                    <CircularProgress sx={{color:theme.palette.primary.main}} />
                </Box>
            )}
            {!userLogged && (
                <MessageLogin data={t("messages.values")} color={theme.palette.custom.black} />
            )}
            {!loadingOverview && userLogged && (
            <Box sx={{width:'100%'}}>    
                <Stack 
                    direction="column" 
                    justifyContent="fles-start" 
                    alignItems="fles-start"
                    sx={{
                        fontFamily: ['"Montserrat Alternates",sans-serif'].join(','),
                        fontSize:'14px',
                        width:'100%',
                        color:theme.palette.custom.black,
                        fontWeight:'600'
                    }}
                >
                    {rows.map((row,index) => (
                        <LinearProgressWithLabel key={index} value={row.value} row={row} />
                    ))}
                </Stack>
            </Box>
            )}
        </Box>
    );

};

export default PlayerOverview;