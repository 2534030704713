const pages = [
    {
        id: 'home',
        path: '/',
        label: 'menu.home'
    },
    {
        id: 'about',
        path: '/about',
        label: 'menu.about'
    },
    {
        id: 'detail',
        path: '/detail',
        label: 'menu.detail'
    },
    {
        id: 'Players',
        path: '/players',
        label: 'menu.players'
    },
    {
        id: 'Teams',
        path: '/teams',
        label: 'menu.teams'
    },
    {
        id: 'Top11',
        path: '/top11',
        label: 'menu.top11'
    }
];


export default _ => { return pages; };