import React, { useEffect, useCallback, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {TableContainer,Table,TableHead,TableBody,TableCell,TableRow,
    Box,Typography,Stack,Avatar,Tooltip,Zoom,Popover,IconButton} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PlayerInfoBox from '../PlayerInfoBox';
import PLayerBox from '../PlayerBox';
import getRuolo from '../PlayerRole/getRuolo';
import getColor from '../PlayerRole/getColor';
import kriptPlayerId from '../Utility/kriptPlayerId';

const PlayersListTable = props => {
    const {players,viewTeam,viewRole} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const showRole = viewRole != undefined ? viewRole : true;
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/**.jpg";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/player/noImg.jpg";
    const rows = players.map((player,index) => ({
        id:player.player_id,
        lastname:player.last_name,
        firstname:player.first_name,
        team:player.team_name,
        role:getRuolo(player),   
        color:getColor(getRuolo(player)),
        value:player.player_value
    }));
    const [visibleRows, setVisibleRows] = useState(rows);
    const [playerSelected, setPlayerSelected] = useState(null);    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const newRows = players.map((player,index) => ({
            id:player.player_id,
            lastname:player.last_name,
            firstname:player.first_name,
            team:player.team_name,
            role:getRuolo(player),   
            color:getColor(getRuolo(player)),
            value:player.player_value
        }));
        setVisibleRows(newRows);
    },[players]);

    const headCells = [
        //{id:'id',numeric:true,label:'id'},
        {id:'avatar',numeric:false,label:''},
        {id:'lastname',numeric:false,label:t("global.lastname")},
        //{id:'fistname',numeric:false,label:t("global.firstname")},
        {id:'role',numeric:false,label:t("global.role")},
        {id:'value',numeric:true,label:t("global.value")}
    ];

    const handleClick = (event, id) => {
        setPlayerSelected(players.filter(player => player.player_id == id)[0]);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const gotocard = (id, lastname, firstname) => {
        window.location.href = '/player/' + kriptPlayerId(id) + '/' + lastname + ' ' + firstname;
    }

    return(
        <Box sx={{width:'100%',marginTop:'10px !important'}}>
            <TableContainer sx={{overflowX:'inherit'}} >
                <Table size='small'>
                    <TableBody>
                    {visibleRows.map((row,index) => {
                        return(
                        <TableRow
                            key={row.id}
                            hover
                            //onClick={(event) => handleClick(event, row.id)}
                            onClick={(event) => gotocard(row.id,row.lastname,row.firstname)}                            
                            sx={{ cursor: 'pointer' }}
                        >             
                            <TableCell 
                                align='left' 
                                sx={{padding:{xs:'6px 0 6px 4px',md:'8px',sm:'8px'}}}
                            >
                                <Avatar                                     
                                    alt={row.lastname + " " + row.firstname} 
                                    src={imgPath.replace('**',row.id)} 
                                    onError={(e)=>{e.target.onError=null; e.target.src=noImg}}
                                    sx={{
                                        width:'60px',
                                        height:'60px',
                                        borderWidth:'4px',
                                        borderStyle:'solid',
                                        borderColor:row.color
                                    }}
                                />
                            </TableCell>               
                            <TableCell align='left' sx={{padding:{xs:'6px 10px 6px 12px',md:'6px 16px',sm:'6px 16px'}}}>
                                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                    <Typography 
                                        variant='text' 
                                        sx={{
                                            fontSize:{xs:'16px',md:'16px',sm:'16px'},
                                            fontWeight:'400'
                                        }}
                                    >
                                        {row.firstname}
                                    </Typography>
                                    <Typography 
                                        variant='text' 
                                        sx={{
                                            fontSize:{xs:'18px',md:'18px',sm:'16px'},
                                            fontWeight:'600'
                                        }}
                                    >
                                        {row.lastname}
                                    </Typography>
                                    {viewTeam && (
                                    <Typography 
                                        variant='text' 
                                        sx={{
                                            fontSize:{xs:'16px',md:'16px',sm:'16px'},
                                            fontWeight:'500',
                                            color:theme.palette.custom.blueIcon
                                        }}
                                    >
                                        {row.team}
                                    </Typography>
                                    )}
                                </Stack>
                            </TableCell>
                            {!userLogged && (
                                <TableCell align='right'>
                                    <Stack 
                                        direction="column" 
                                        justifyContent="center" 
                                        alignItems="center" 
                                        spacing={0}
                                    >
                                        <Tooltip title={t("messages.viewvalue")} arrow TransitionComponent={Zoom}>   
                                            <HelpIcon sx={{color:theme.palette.primary.main,fontSize:'36px'}} />
                                        </Tooltip>
                                        <Typography
                                            variant='textRound'
                                            sx={{
                                                fontWeight:'600',
                                                fontSize:{xs:'13px',md:'14px',sm:'13px'},
                                                color:theme.palette.custom.blueIcon
                                            }}
                                        >{t("global.value")}</Typography>
                                    </Stack>
                                </TableCell>
                            )}
                            {userLogged && showRole && (
                                <TableCell align='left' sx={{padding:'6px'}}>
                                    <Tooltip title={t("roles." + row.role)} arrow TransitionComponent={Zoom}>
                                        <Avatar
                                            sx={{
                                                backgroundColor:row.color,
                                                width: {xs:'28px',md:'28px',sm:'28px'},
                                                height: {xs:'28px',md:'28px',sm:'28px'},
                                                padding: {xs:'10px',md:'10px',sm:'10px'},
                                                fontWeight: '700',
                                                fontSize:{xs:'20px',md:'20px',sm:'20px'},
                                                borderRadius:'100%',
                                                color:theme.palette.custom.white
                                            }}
                                        >
                                            {t("roles." + row.role).substring(0,1)}
                                        </Avatar>
                                    </Tooltip>
                                </TableCell>
                            )}
                            {userLogged && (
                                <TableCell align='right' sx={{padding:{xs:'6px 0 6px 6px',md:'6px 16px',sm:'6px 16px'}}}>
                                    <Stack direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={0}>
                                        <Typography 
                                            variant='text'
                                            sx={{
                                                fontSize:'18px !important',
                                                fontWeight:'600',
                                                color:theme.palette.custom.blueIcon
                                            }}
                                        >
                                            {row.value}
                                        </Typography>
                                        <Typography 
                                            variant='text'
                                            sx={{
                                                fontSize:{xs:'14px',md:'16px',sm:'16px'},
                                                fontWeight:'400',
                                                color:theme.palette.custom.black
                                            }}
                                        >
                                            Mio €
                                        </Typography>
                                    </Stack>
                                </TableCell>
                            )}
                            <TableCell align='right'>
                                <Tooltip title={t("global.gotocard")}>
                                    <IconButton
                                        href={'/player/' + kriptPlayerId(row.id) + '/' + row.lastname + ' ' + row.firstname}
                                    >
                                        <DoubleArrowIcon sx={{color:theme.palette.primary.main, fontSize:'24px'}} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        )                
                    })}                    
                    </TableBody>
                </Table>
            </TableContainer>
            <Popover
                id="popover_player"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                transformOrigin={{
                vertical: 'center',
                horizontal: 'center',                
                }}
                disablePortal={true}
            >
                {/*<PlayerInfoBox player={playerSelected}  index={0} limit={{xs:1,md:1,sm:1}} key={0} methodClose={handleClose} />*/}
                <Box sx={{width:{xs:'340px',md:'400px',sm:'400px'},background:'none'}}>
                    <PLayerBox player={playerSelected} />
                </Box>
            </Popover>            
        </Box>
    );
};

export default PlayersListTable;