import kriptKyes from "./kriptKyes";

export default idKript => {
    //funzione di recupero indice
    const geIndex = element => {
        const indexArray = kriptKyes.map((n,index) => n == element ? index : undefined);
    };
    //Conversione id in array
    const idKriptArray = idKript.split('');
    //Chiave di criptazione
    const key = idKriptArray[2];
     //Decripto l'id
     const idArray = idKriptArray.filter((n,index) => index != 2).map((e,index) => kriptKyes.indexOf(e) - key);
     const teamId = idArray.toString().replaceAll(',','');

     return teamId;
};