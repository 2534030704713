import React, { Fragment, useState } from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack,List,ListItem,ListItemAvatar,Avatar,ListItemText,ListItemButton,Typography,Popover,Box } from '@mui/material';
import PlayerInfoBox from './PlayerInfoBox';
import PLayerBox from './PlayerBox';
import getRuolo from './PlayerRole/getRuolo';
import getColor from './PlayerRole/getColor';

const Distinta = props => {
    const {players, modulo} = props;
    const theme = useTheme();
    const {t} = useTranslation('common');
    const [player, setPlayer] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const playerGk = players.filter((player,index) => index >= 0 && index < modulo[0]).map(p => ({...p,role:'gk'}));
    const playerDef = players.filter((player,index) => index >= modulo[0] 
        && index < modulo[0] + modulo[1]).map(p => ({...p,role:'de'}));;
    const playerCc = players.filter((player,index) => index >= modulo[0] + modulo[1] 
        && index < modulo[0] + modulo[1] + modulo[2]).map(p => ({...p,role:'cc'}));
    const playerCo =  players.filter((player,index) => index >= modulo[0] + modulo[1] + modulo[2] 
        && index < modulo[0] + modulo[1] + modulo[2] + modulo[3]).map(p => ({...p,role:'co'}));
    const playerAtt = players.filter((player,index) => index >= modulo[0] + modulo[1] + modulo[2] + modulo[3]
        && index < modulo[0] + modulo[1] + modulo[2] + modulo[3] + modulo[4]).map(p => ({...p,role:'at'}));
 
    const formazione = [...playerGk,...playerDef.reverse(),...playerCc.reverse(),...playerCo.reverse(),...playerAtt.reverse()];

    const infoMap = [
        {role:'gk',color:theme.palette.custom.gk,desc:'Goalkeeper'},
        {role:'de',color:theme.palette.custom.de,desc:'Defender'},
        {role:'cc',color:theme.palette.custom.cc,desc:'Midfielder'},
        {role:'co',color:theme.palette.custom.co,desc:'Attackingmidfielder'},
        {role:'at',color:theme.palette.custom.at,desc:'Forward'},
    ];

    const handleClick = (event, player) => {
        setPlayer(player);
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
    <Fragment>        
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0} sx={{width:'100%'}}>
            <List dense={true} sx={{width:'100%'}}>
                {formazione.map((player,index) => (
                    <ListItem 
                        key={index} 
                        sx={{
                            padding:'0px',
                            borderStyle:'solid',
                            borderWidth:'0 0 1px 0',
                            borderColor:theme.palette.custom.grey
                        }}
                    >
                        <ListItemButton
                            onClick={event => {handleClick(event,player);}}
                        >
                            <ListItemAvatar sx={{minWidth:'30px'}}>
                                <Avatar
                                    variant='smallAvatar'
                                    sx={{
                                        //backgroundColor:infoMap.filter(item => item.role === player.role)[0].color,
                                        backgroundColor:getColor(getRuolo(player)),
                                        padding:'10px'
                                    }}
                                >                                    
                                    {t("roles." + infoMap.filter(item => item.role === player.role)[0].desc).substring(0,1)}                                    
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={
                                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                    <Typography variant='text' sx={{fontWeight:'600',fontSize:'15px'}}>
                                        {player.last_name}
                                    </Typography>
                                    <Typography variant='text' sx={{fontWeight:'500',fontSize:'15px',textAlign:'right'}}>
                                        ({player.player_value + ' Mio€'})
                                    </Typography>
                                </Stack>
                                } 
                            />
                        </ListItemButton>
                    </ListItem>                    
                ))}                
            </List>
        </Stack>

        <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
        }}
        transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',                
        }}
        disablePortal={true}
        >
            {/*<PlayerInfoBox player={player}  index={0} limit={{xs:1,md:1,sm:1}} key={0} /> */}
            <Box sx={{width:{xs:'340px',md:'400px',sm:'400px'},background:'none'}}>
                <PLayerBox player={player} />
            </Box>
        </Popover>
    </Fragment>
    )
};

export default Distinta;