import utils from "../utils";

export default _ => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const urlencoded = new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,        
        audience: process.env.REACT_APP_AUTH_DOMAIN + "/api/v2/"
    });
    const requestOptions = {
		method: 'POST',
		headers: headers,
		body: urlencoded		
	};
    const tokenUrl = process.env.REACT_APP_AUTH_DOMAIN + "/oauth/token";
    return fetch(tokenUrl, requestOptions).then(response => {
        if (response.status < 200 || response.status > 300) {            
            return Promise.reject(utils.createError(response));
        }
        return response.json();
    });
};