import React, {} from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { FormControl,InputLabel,MenuItem,Select} from '@mui/material';

const SelectModulo = props => {
    const {modulo,onChangeModulo,disabled,width} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');  
    const widthProps = width ? width : '300px';
    const modulo_map = [
        {id:13241,modulo:[1,3,2,4,1],label:'3-2-4-1'},
        {id:13403,modulo:[1,3,4,0,3],label:'3-4-3'},
        {id:13412,modulo:[1,3,4,1,2],label:'3-4-1-2'},
        {id:13421,modulo:[1,3,4,2,1],label:'3-4-2-1'},        
        {id:13502,modulo:[1,3,5,0,2],label:'3-5-2'},
        {id:13511,modulo:[1,3,5,1,1],label:'3-5-1-1'},
        {id:14141,modulo:[1,4,1,4,1],label:'4-1-4-1'},
        {id:14231,modulo:[1,4,2,3,1],label:'4-2-3-1'},
        {id:14204,modulo:[1,4,2,0,4],label:'4-2-4'},
        {id:14312,modulo:[1,4,3,1,2],label:'4-3-1-2'},
        {id:14321,modulo:[1,4,3,2,1],label:'4-3-2-1'},
        {id:14303,modulo:[1,4,3,0,3],label:'4-3-3'},
        {id:14411,modulo:[1,4,4,1,1],label:'4-4-1-1'},        
        {id:14402,modulo:[1,4,4,0,2],label:'4-4-2'},
        {id:14501,modulo:[1,4,5,0,1],label:'4-5-1'},
        {id:15311,modulo:[1,5,3,1,1],label:'5-3-1-1'},
        {id:15302,modulo:[1,5,3,0,2],label:'5-3-2'}
    ];
    const moduloSelcted = modulo_map.filter(mod => JSON.stringify(mod.modulo) === JSON.stringify(modulo))[0].id;

    return(
        <FormControl 
            sx={{
                width:widthProps,
                "& .MuiFormLabel-root": {
                    fontFamily: ['"Poppins", sans-serif'].join(","),
                    color: theme.palette.custom.blueIcon + " !important",
                    fontSize:{xs:'1.3rem !important',md:'1.1rem !important',sm:'1.2rem !important'}
                },
                "& .css-178fhwn-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":{
                    fontFamily: ['"Poppins", sans-serif'].join(","),
                    borderColor: theme.palette.custom.blueIcon + " !important"
                },
                "& .css-178fhwn-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                    borderColor: theme.palette.custom.blueIcon + " !important"
                }
            }}
        >
            <InputLabel id="team-modulo">{t("global.modulo")}</InputLabel>
            <Select
                labelId="team-modulo"
                id="team-select-modulo"
                label={t("global.modulo")}
                value={moduloSelcted}
                onChange={onChangeModulo}
                disabled={disabled}
            >
            {modulo_map.map((mod,index) => (
                <MenuItem key={mod.id} value={mod.id}>
                    {mod.label}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    );
};

export default SelectModulo;