import './style.css';


const Manteinance = _ => {
    return(
        <div className='Manteinance'>
            <header className='Manteinance-header'>
                <p>Coming Soon...</p>
            </header>
        </div>
    );
};

export default Manteinance;