import React, { useEffect, useState } from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
/* Eliminato perchè inserito CookieYes -> import CookieConsent from "react-cookie-consent"; */
import parse from 'html-react-parser';
import {Stack, Link, Box, Typography, Button, List, ListItemButton, ListItemText, ListItemIcon} from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import './styles.css';

const Footer = _ => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const logo = process.env.REACT_APP_PUBLIC_URL + "/assets/images/logo_playerstar.png";
    const linkLogo = process.env.REACT_APP_PUBLIC_URL;
    const [scroll, setScroll] = useState(false);

    const pageList = [
        {id:'about',url:'/about'},
        {id:'detail',url:'/detail'}        
    ];

    const linkList = [
        {id:'players',url:'/players'},
        {id:'teams',url:'/teams'}
    ];

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 160);
        });    
    }, []);
    

    return(
        <Stack 
            direction="column" 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={0}
            sx={{/*position:'absolute',bottom:'0',*/width:'100%'}}
        >
            <Stack 
                direction="column" 
                justifyContent="flex-start" 
                alignItems="flex-start" 
                spacing={0}
                sx={{width:'100%',backgroundColor:theme.palette.custom.black}}
            >
                {/* Logo, contatti e contenuti footer */}
                <Stack 
                    direction={{xs:'column',md:'row',sm:'column'}}
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{
                        padding:{xs:'30px 0 10px 20px',md:'50px 90px 10px 90px',sm:'30px 20px 10px 40px'},
                        width:{xs:'90%',md:'100%',sm:'80%'}
                    }}
                >      
                    <Stack 
                        direction={{xs:"column",md:"row",sm:"column"}} 
                        justifyContent="flex-start" 
                        alignItems="flex-start" 
                        spacing={0}
                        sx={{width:'100%'}}
                    >
                        {/* Logo */}
                        <Stack 
                            direction="column" 
                            justifyContent="flex-start" 
                            alignItems="flex-start" 
                            spacing={1}
                            sx={{marginRight:{xs:'0',md:'120px',sm:'0'}}}
                        >
                            <Link href={linkLogo}>
                                <img 
                                    src={logo} 
                                    alt="PlayerStar" 
                                    className='LogoPlayerStar'
                                />
                            </Link>                            
                        </Stack>
                        
                        {/* Quotazioni */}
                        <Stack 
                            direction="column" 
                            justifyContent={{xs:'flex-start',md:'center',sm:'flex-start'}}
                            alignItems={{xs:'flex-start',md:'center',sm:'flex-start'}}
                            spacing={0}
                            sx={{
                                width:{xs:'100%',md:'60%',sm:'100%'},
                                marginTop:{xs:'20px',md:'0',sm:'20px'}
                            }}
                        >
                            <Typography 
                                variant='sectionTitle'
                                sx={{
                                    fontSize:{xs:'16px',md:'18px',ms:'16px'},
                                    color:theme.palette.custom.white,
                                    textTransform:'capitalize',
                                    borderStyle:'solid',
                                    borderWidth:'0 0 1px 0',
                                    borderColor:theme.palette.primary.main,
                                    marginBottom:'-8px',
                                    paddingBottom:'5px'
                                }} 
                            >
                                {parse(t("footer.value"))}
                            </Typography>
                            <List>
                            {linkList.map((page,index) => (
                                <ListItemButton 
                                    key={index}
                                    href={page.url}
                                    variant='textAction'
                                    sx={{paddingTop:'4px',paddingBottom:'4px'}}
                                >
                                    <ListItemIcon sx={{minWidth:'30px'}}>
                                        <ArrowRightIcon sx={{color:theme.palette.primary.main}} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography 
                                                variant='text' 
                                                sx={{color:theme.palette.custom.white,fontSize:'16px'}}
                                            >
                                                {parse(t("menu." +  page.id))}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            ))}
                            </List>
                        </Stack>

                        {/* Pagine */}
                        <Stack 
                            direction="column" 
                            justifyContent={{xs:'flex-start',md:'center',sm:'flex-start'}}
                            alignItems={{xs:'flex-start',md:'center',sm:'flex-start'}}
                            spacing={0}
                            sx={{
                                width:{xs:'100%',md:'60%',sm:'100%'},
                                marginTop:{xs:'20px',md:'0',sm:'20px'}
                            }}
                        >
                            <Typography 
                                variant='sectionTitle'
                                sx={{
                                    fontSize:{xs:'16px',md:'18px',ms:'16px'},
                                    color:theme.palette.custom.white,
                                    textTransform:'capitalize',
                                    borderStyle:'solid',
                                    borderWidth:'0 0 1px 0',
                                    borderColor:theme.palette.primary.main,
                                    marginBottom:'-8px',
                                    paddingBottom:'5px'
                                }} 
                            >
                                {parse(t("footer.playerstar"))}
                            </Typography>
                            <List>
                            {pageList.map((page,index) => (
                                <ListItemButton 
                                    key={index}
                                    href={page.url}
                                    variant='textAction'
                                    sx={{paddingTop:'4px',paddingBottom:'4px'}}
                                >
                                    <ListItemIcon sx={{minWidth:'30px'}}>
                                        <ArrowRightIcon sx={{color:theme.palette.primary.main}} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography 
                                                variant='text' 
                                                sx={{color:theme.palette.custom.white}}
                                            >
                                                {parse(t("menu." +  page.id))}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            ))}
                            </List>
                        </Stack>

                        {/* contatti */}
                        <Stack 
                            direction="column" 
                            justifyContent={{xs:'flex-start',md:'center',sm:'flex-start'}}
                            alignItems={{xs:'flex-start',md:'center',sm:'flex-start'}}
                            spacing={1}
                            sx={{
                                width:{xs:'100%',md:'45%',sm:'100%'},
                                marginTop:{xs:'20px',md:'0',sm:'20px'}
                            }}
                        >
                            <Typography 
                                variant='sectionTitle'
                                sx={{
                                    fontSize:{xs:'16px',md:'18px',ms:'16px'},
                                    color:theme.palette.custom.white,
                                    textTransform:'capitalize',
                                    borderStyle:'solid',
                                    borderWidth:'0 0 1px 0',
                                    borderColor:theme.palette.primary.main,
                                    paddingBottom:'5px'
                                }} 
                            >
                                {parse(t("footer.contact"))}
                            </Typography>
                            <Button
                                href='mailto:info@playerstar.net'
                                variant='textAction'
                                startIcon={<AlternateEmailIcon sx={{color:theme.palette.primary.main}} />}
                                sx={{color:theme.palette.primary.main,textTransform:'lowercase'}}
                            >
                                info@playerstar.net
                            </Button>
                        </Stack>                
                    </Stack>
                </Stack>
                {/* Privacy e developped */}
                <Stack
                    direction={{xs:'column',md:'row',sm:'row'}}
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={{xs:1,md:2,sm:2}}
                    sx={{
                        width:'100%',
                        margin:'5px 0',
                        padding:'5px 10px',
                        borderWidth:'1px 0 0 0',
                        borderStyle:'solid',
                        borderColor:theme.palette.primary.main
                    }}
                >
                    <Box 
                        sx={{
                            width:{xs:'100%',md:'50%',sm:'50%'},
                            textAlign:{xs:'center',md:'right',sm:'center'}
                        }}
                    >
                        <Typography
                            variant='text'
                            sx={{
                                fontSize:{xs:'14px',md:'16px',sm:'14px'},
                                color:theme.palette.primary.main                                
                            }}
                        >
                            {parse(t("footer.privacy"))}
                        </Typography>
                        
                    </Box>
                    <Box 
                        sx={{
                            width:{xs:'100%',md:'50%',sm:'50%'},                            
                            textAlign:{xs:'center',md:'right',sm:'center'}
                        }}
                    >
                        <Typography
                            variant='text'
                            sx={{
                                fontSize:{xs:'14px',md:'16px',sm:'14px'},
                                color:theme.palette.custom.white
                            }}
                        >
                            {parse(t("footer.developed"))}
                        </Typography>
                    </Box>
                </Stack>

            </Stack>
            <Link href="#top">
                <ArrowCircleUpIcon 
                    className={scroll? 'iconTopScroll' : 'iconTop'} 
                    sx={{
                        top:{xs:'94%',md:'90%',sm:'94%'},
                        left:{xs:'86%',md:'95%',sm:'90%'},
                        fontSize: {xs:'46px !important',md:'48px !important',sm:'50px !important'},
                        color:theme.palette.primary.main,                        
                    }}
                />
            </Link>
            {/* Eliminato perchè inserito CookieYes 
            <CookieConsent
                location='bottom'
                acceptOnScroll={true}
                cookieName='PlayerStarCookie'                
                buttonText={t("cookie.buttontext")}
                declineButtonText={t("cookie.declinetext")}
                enableDeclineButton
                flipButtons                
                style={{
                    backgroundColor:theme.palette.custom.black,
                    color:theme.palette.custom.white,
                    borderTop:'1px solid #fff'
                }}
                buttonStyle={{

                }}                
            >
                {parse(t("cookie.text"))}
            </CookieConsent>
            */}
        </Stack>
    );
};

export default Footer;