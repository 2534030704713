import React, { useEffect, useCallback, useState, Fragment } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {TableContainer,Table,TableHead,TableBody,TableCell,TableRow,
    Box,Typography,Stack,Avatar,Tooltip,Zoom,Popover,IconButton} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import HelpIcon from '@mui/icons-material/Help';
import kriptTeamId from '../Utility/kriptTeamId';    

const TeamListTable = props => {
    const {teamsList, linkpage} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const imgPath = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/";
    const noImg = process.env.REACT_APP_PUBLIC_URL + "/assets/images/team/noImg.png";
    const teamBaseUrl = '/team/';

    const onClickAction = team => {
        window.location.href = teamBaseUrl + kriptTeamId(team.team_id) + '/' + team.team_name;
    };

    return(
        <Box sx={{width:'100%',marginTop:'10px !important'}}>
            <TableContainer sx={{overflowX:'inherit'}}>
                <Table size='small'>
                    <TableBody>
                    {teamsList.map((team,index) => (
                        <TableRow
                            key={team.team_id}
                            hover
                            onClick={(event) => onClickAction(team)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell align='left' sx={{padding:'8px',width:'80px'}}>
                                <Avatar 
                                    alt={team.team_name} 
                                    src={imgPath + team.team_id + ".png"}
                                    onError={(e)=>{e.target.onError=null; e.target.src=noImg}}
                                    sx={{                                    
                                        width:{xs:'60px',md:'60px',sm:'60px'},
                                        height:{xs:'60px',md:'60px',sm:'60px'}
                                    }}
                                />
                            </TableCell>
                            <TableCell align='left'>
                                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                                    <Typography 
                                        variant='text' 
                                        sx={{
                                            fontSize:{xs:'18px',md:'18px',sm:'18px'},
                                            fontWeight:'600'
                                        }}
                                    >
                                        {team.team_name}
                                    </Typography>
                                    <Typography 
                                        variant='titleSection'                                    
                                        sx={{
                                            color:theme.palette.custom.blueIcon,
                                            fontSize:{xs:'16px',md:'16px',sm:'16px'},
                                            fontWeight:'600'                                        
                                        }}
                                    >
                                        {team.tournament_name}
                                    </Typography>
                                    <Typography
                                        variant='titleSection'
                                        sx={{
                                            color:theme.palette.custom.black,
                                            fontSize:{xs:'16px',md:'16px',sm:'16px'},
                                            fontWeight:'500',                                        
                                            display:'inline-block',
                                            paddingTop:'5px',
                                        }}
                                    >
                                        {team.team_region_name}
                                    </Typography> 
                                </Stack>
                            </TableCell>
                            {!userLogged && (
                                <TableCell align='right'>
                                    <Stack 
                                        direction="column" 
                                        justifyContent="center" 
                                        alignItems="center" 
                                        spacing={0}
                                    >
                                        <Tooltip title={t("messages.viewvalue")} arrow TransitionComponent={Zoom}>                                            
                                            <HelpIcon sx={{color:theme.palette.primary.main,fontSize:'36px'}} />
                                        </Tooltip>
                                        <Typography
                                            variant='textRound'
                                            sx={{
                                                fontWeight:'600',
                                                fontSize:{xs:'13px',md:'14px',sm:'13px'},
                                                color:theme.palette.custom.blueIcon
                                            }}
                                        >
                                            {t("global.value")}
                                        </Typography>                                                                                    
                                    </Stack>
                                </TableCell>
                            )}
                            {userLogged && (
                                <TableCell align='right' sx={{padding:{xs:'6px 0 6px 6px',md:'6px 16px',sm:'6px 16px'}}}>
                                    <Stack direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={0}>
                                        <Typography 
                                            variant='text'
                                            sx={{
                                                fontSize:'18px !important',
                                                fontWeight:'600',
                                                color:theme.palette.custom.blueIcon
                                            }}
                                        >
                                            {team.team_value}
                                        </Typography>
                                        <Typography 
                                            variant='text'
                                            sx={{
                                                fontSize:{xs:'14px',md:'16px',sm:'16px'},
                                                fontWeight:'400',
                                                color:theme.palette.custom.black
                                            }}
                                        >
                                            Mio €
                                        </Typography>
                                    </Stack>
                                </TableCell>
                            )}

                            <TableCell align='right' sx={{width:'70px'}}>
                                <Tooltip title={t("global.gototeamcard")}>
                                    <IconButton
                                        onClick={(event) => onClickAction(team)}
                                    >
                                        <DoubleArrowIcon sx={{color:theme.palette.primary.main, fontSize:'24px'}} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow> 
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
};

export default TeamListTable;