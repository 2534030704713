import utils from "../utils";

export default words => {
    const requestOptions = {
        method: 'GET',
        headers: utils.createHeaders()
    };
    
    return utils.fetch(`${process.env.REACT_APP_API_ENDPOINT}/players/find?t=${words}`, requestOptions)
        .then(response => response.json());
};