import React from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Typography } from '@mui/material';

const PointsValue = props => {
    const {value} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');

    return(
        <Stack 
            direction="column" 
            justifyContent="flex-end" 
            alignItems="flex-end" 
            spacing={0} 
            sx={{width:'100%'}}
        >            
            <Typography
                variant='titleSection'
                sx={{
                    color:theme.palette.custom.gk,
                    fontSize:{xs:'32px',md:'50px',sm:'42px'},
                    fontWeight:'600' 
                }}
            >
                {value}
            </Typography>
            <Typography
                variant='titleSection'
                sx={{
                    color:theme.palette.custom.black,
                    fontSize:'16px !important',
                    fontWeight:'500'      
                }}
            >
                {t('global.points')}
            </Typography>       
        </Stack>
    );
};

export default PointsValue;