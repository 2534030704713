import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack,Typography,Button,Divider } from '@mui/material';

const SupportBox = props => {
    const {onClose} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{width:'100%',padding:'10px'}}
        >
            <Typography 
                variant='titleAside'
                sx={{
                    width:'100%',
                    borderWidth:'0 0 1px 0',
                    borderStyle:'solid',
                    borderColor:theme.palette.primary.main,
                    paddingBottom:'4px'
                }}
            >
                {t('support.title')}
            </Typography>
            <Typography variant='textSmall'>{t('support.message')}</Typography>
            
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{width:'100%'}}
            >
                <Typography variant='textSmall'>{t('support.writeTo') + ':'}</Typography>        
                <Button 
                    variant='textAction' 
                    href='mailto:info@playerstar.net' 
                    sx={{textTransform:'lowercase',fontSize:'16px !important'}}
                >
                   info@playerstar.net
                </Button>
            </Stack>
            <Stack 
                direction="row" 
                justifyContent="space-between" 
                alignItems="center" 
                spacing={0} 
                sx={{width:'100%',marginTop:'20px !important'}}
            >
                <Button variant='action' href='mailto:info@playerstar.net'>
                    {t('support.write')}
                </Button>
                <Button variant='secondary' onClick={onClose}>
                    {t('global.close')}
                </Button>
            </Stack>
        </Stack>
    );
};

export default SupportBox;