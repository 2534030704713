import React from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Typography, Box } from '@mui/material';

const PrivacyPolicy = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    
    return(
        <Stack 
            direction="column" 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={2}
            sx={{padding:{xs:'40px 20px',md:'20px 150px',sm:'20px 40px'}}}
        >
            {[1,2,3,4,5,6,7,8].map((index) => 
                <Stack 
                    key={index}
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{marginTop:'20px !important'}}
                >
                    <Typography variant='titleSection'>{parse(t("privacypolicy.title" +  index))}</Typography>
                    <Typography variant='text'>{parse(t("privacypolicy.sezione" + index))}</Typography>
                </Stack>
            )}
        </Stack>
    );
};

export default PrivacyPolicy;