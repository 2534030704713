import { action, thunk } from 'easy-peasy';
import utils from '../utils';

export default {
    players: [],
    setPlayers: action((state, payload) => {
        state.players = payload;
    }),
    setPlayersInTeam: action((state, payload) => {
        state.players = [];
        //Decrypt dei valori
        payload.map((player) => {
            //players = utils.decryptResponse(player);
            state.players.push(utils.decryptResponse(player));
        });
    }),
    retrievePlayerInTeam:  thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        services.players.getPlayerInTeam(payload)
            .then(data => {
                actions.setPlayersInTeam(data);             
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                
            });
    })
};