import React from 'react';
import { Stack } from '@mui/material';
import PageNotFound from '../../components/PageNotFound';

const PagesNotFound = props => {
    return(
        <Stack sx={{width:'100%',minHeight:'80vh'}}>
            <PageNotFound />
        </Stack>
    )
};

export default PagesNotFound;