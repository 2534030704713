import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Typography, Avatar, Button, CircularProgress,
    FormControl, FormControlLabel, InputAdornment, Input, InputLabel, FormHelperText, Checkbox
 } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AttributionIcon from '@mui/icons-material/Attribution';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const Account = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const loading = useStoreState(state => state.user.loading);
    const loadingUpdate = useStoreState(state => state.user.loadingUpdate);
    const updateUserInfo = useStoreActions(dispatch => dispatch.user.updateUserInfo);
    const messageUpdateUser = useStoreState(state => state.user.messageUpdateUser);
    const setMessageUpdateUser = useStoreActions(dispatch => dispatch.user.setMessageUpdateUser);
    const [values,setValues] = useState({       
        name:user ? user.name : '',
        errorName:'',
        nickname: user ? user.nickname : '',
        errorNickname: '',
        password:'',
        passwordConfirm:'',
        errorPassword:'',
        errorPasswordConfirm:''
    });
    const fieldRequired = parse(t("formlogin.fieldrequired"));
    const passwordFormat = parse(t("formlogin.passwordformat"));
    const passwordNotConfirmed = parse(t("formlogin.errorconfirmpassword"));

    useEffect(() => {
        //Se l'utente non è connesso redirigo
        if(!user || !user.sub || user.sub === ''){         
            window.location.href = process.env.REACT_APP_PUBLIC_URL;
        }
        setMessageUpdateUser('');
        setValues({       
            name:user ? user.name : '',
            errorName:'',
            nickname: user ? user.nickname : '',
            errorNickname: '',
            password:'',
            passwordConfirm:'',
            errorPassword:'',
            errorPasswordConfirm:''
        });
    },[]);

    useEffect(() => {
        setValues({       
            name:user ? user.name : '',
            errorName:'',
            nickname: user ? user.nickname : '',
            errorNickname: '',
            password:'',
            passwordConfirm:'',
            errorPassword:'',
            errorPasswordConfirm:''
        });
    },[user]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });        
    };

    function isValidPassword(password){
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{8,}/;

        return password.length > 0 && 
            uppercaseRegExp.test(password) &&
            lowercaseRegExp.test(password) &&
            digitsRegExp.test(password) &&
            specialCharRegExp.test(password) &&
            minLengthRegExp.test(password);                
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //setErrorRegistred('')
        values.errorName = '';
        values.errorPassword = '';
        values.errorPasswordConfirm = '';
        values.errorNickname = '';
        setValues({
            ...values,            
            errorName: values.name.trim() === '' ? fieldRequired : '',
            errorPassword: values.password.trim() !== '' && !isValidPassword(values.password) ? passwordFormat :
                values.passwordConfirm.trim() !== '' && values.password !== values.passwordConfirm ? passwordNotConfirmed : '',
            errorPasswordConfirm: (values.passwordConfirm.trim() !== '' || values.password.trim() !== '') && 
                values.password !== values.passwordConfirm ? passwordNotConfirmed : '',
            errorNickname: values.nickname.trim() === '' ? fieldRequired : ''
        });        
        if(values.errorPassword == '' && values.errorName == '' && values.errorNickname == '' && 
                values.errorPasswordConfirm == '' &&  
                values.name !== '' && values.nickname !== '' && values.password === values.passwordConfirm)
        {
            const newUserInfo = values.password == '' ? 
                {name: values.name, nickname: values.nickname} : {name: values.name,nickname: values.nickname,password: values.password};            
            updateUserInfo(newUserInfo);            
        }        
    };

    return (
        <Stack sx={{width:'100%',minHeight:'80vh'}}>
            <Stack 
                direction="column" 
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                sx={{width:'100%'}}
            >
                {/** Titolo pagina */}
                <Stack 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    spacing={1}
                    sx={{
                        width:'100%',
                        backgroundColor:theme.palette.custom.black,
                        color:theme.palette.custom.white,
                        padding:{xs:'10px 20px', md:'10px 90px',sm:'10px 40px',tb:'10px 40px'}
                    }}
                >
                    {user.picture != '' && (
                        <Avatar src={user.picture} sx={{width:'30px',height:'30px'}} />
                    )}
                    {user.picture == '' && (
                        <Avatar sx={{backgroundColor:theme.palette.primary.main}} ><PersonIcon /></Avatar>
                    )}
                    <Typography variant='pageTitle' sx={{fontSize:'18px !important'}}>{user.name}</Typography>
                </Stack>    
                {loading && (<CircularProgress size={36} sx={{color:theme.palette.primary.main}} />)}
                {!loading && (
                    <Stack 
                        direction="column" 
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        sx={{
                            width:'100%',
                            padding:{xs:'10px 20px', md:'10px 90px',sm:'10px 40px',tb:'10px 40px'},
                            marginTop:'10px !important'
                        }}
                    >
                        <Stack 
                            direction="row" 
                            justifyContent="flex-start" 
                            alignItems="center" 
                            spacing={1} 
                            sx={{marginTop:'-10px',marginBottom:'30px'}}
                        >
                            {user.emailVerified && (<HowToRegIcon sx={{color:theme.palette.custom.green}} />)}
                            {!user.emailVerified && (<PersonOffIcon sx={{color:theme.palette.custom.red}} />)}
                            <Typography variant='text' sx={{fontSize:'16px'}}>
                                {user.emailVerified ? t("global.accountverified") : t("global.accountunverified")}
                            </Typography>                        
                        </Stack> 
                        
                        {/* Email */}
                        <FormControl variant='standard'>
                            <InputLabel htmlFor='formsignin-email' sx={{fontSize:'18px'}}>Email</InputLabel>
                            <Input
                                id="formsignin-email"
                                value={user.email}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AlternateEmailIcon sx={{color:theme.palette.primary.main}} />
                                    </InputAdornment>
                                }
                                variant='formLogin'
                                sx={{color:theme.palette.custom.blackPlayer}}
                            />
                            <FormHelperText 
                                id="formsignin-email-user"
                                variant='formValidate'   
                                sx={{color:theme.palette.primary.main}}
                            >
                                {'non modificabile'}
                            </FormHelperText>
                        </FormControl>
                        {/* Nome */}
                        <FormControl variant='standard'>
                            <InputLabel htmlFor='formsignin-name' sx={{fontSize:'18px'}}>{t("global.name")}</InputLabel>
                            <Input
                                id="formsignin-name"
                                aria-describedby="formsignin-name-error"
                                value={values.name}
                                onChange={handleChange('name')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircle sx={{color:theme.palette.primary.main}} />
                                    </InputAdornment>
                                }
                                variant='formLogin'
                            />
                            <FormHelperText 
                                id="formsignin-name-error"
                                variant='formValidate'   
                            >
                                {values.errorName}
                            </FormHelperText>
                        </FormControl>
                        {/* Nickname */}
                        <FormControl variant='standard'>
                            <InputLabel htmlFor='formsignin-name' sx={{fontSize:'18px'}}>Nickname</InputLabel>
                            <Input
                                id="formsignin-name"
                                aria-describedby="formsignin-name-error"
                                value={values.nickname}
                                onChange={handleChange('nickname')}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AttributionIcon sx={{color:theme.palette.primary.main}} />
                                    </InputAdornment>
                                }
                                variant='formLogin'
                            />
                            <FormHelperText 
                                id="formsignin-name-error"
                                variant='formValidate'   
                            >
                                {values.errorNickname}
                            </FormHelperText>
                        </FormControl>
                        {/* Password */}
                        <FormControl variant='standard' sx={{marginTop:'10px',color:`${theme.palette.error.main}`}}>
                            <InputLabel htmlFor='formlogin-password' sx={{fontSize:'18px'}}>
                                {t('formlogin.newpassword')}
                            </InputLabel>
                            <Input
                                id="formsignin-password"
                                aria-describedby="formsignin-password-error"
                                type={'text'}
                                value={values.password}
                                onChange={handleChange('password')}
                                error={false}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <VpnKeyIcon sx={{color:theme.palette.primary.main}} />
                                    </InputAdornment>
                                }
                                variant='formLogin'     
                            />
                            <FormHelperText 
                                id="formsignin-password-error"
                                variant='formValidate'
                            >
                                {values.errorPassword}
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant='standard' sx={{marginTop:'10px',color:`${theme.palette.error.main}`}}>
                            <InputLabel htmlFor='formlogin-passwordconfirm' sx={{fontSize:'18px'}}>
                                {t('formlogin.passwordconfirm')}
                            </InputLabel>
                            <Input
                                id="formsignin-passwordconfirm"
                                aria-describedby="formsignin-passwordconfirm-error"
                                type={'text'}
                                value={values.passwordConfirm}
                                onChange={handleChange('passwordConfirm')}
                                error={false}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <VpnKeyIcon sx={{color:theme.palette.primary.main}} />
                                    </InputAdornment>
                                }
                                variant='formLogin'      
                            />
                            <FormHelperText 
                                id="formsignin-passwordconfirm-error"
                                variant='formValidate'
                            >
                                {values.errorPasswordConfirm}
                            </FormHelperText>
                        </FormControl>
                        {/* Privacy */}
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="formsignin-privacy"
                                        aria-describedby="formsignin-privacy-error"
                                        checked={true}                                        
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={<TaskAltIcon sx={{color:`${theme.palette.custom.green}`}} />}                                
                                    />
                                }
                                label={parse(t("formlogin.privacy"))}
                                variant='formValidate'  
                            />
                            <FormHelperText 
                                id="formsignin-privacy-error"
                                variant='formValidate'
                                sx={{marginTop:'-10px'}}
                            >
                                {''}
                            </FormHelperText>
                        </FormControl>  
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{marginTop:'20px',marginBottom:'30px'}}
                        >
                            {loadingUpdate && (<CircularProgress size={32} />)}
                            {!loadingUpdate && (
                            <Button
                                variant='action'                                
                                endIcon={<DoubleArrowIcon />}
                                disabled={values.name === '' || values.nickname === ''}
                                onClick={handleSubmit}
                            >
                                {t("global.save")}
                            </Button>
                            )}
                            <Typography variant='text' sx={{fontSize:'16px'}}>
                                {t(messageUpdateUser)}
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
    
};

export default Account;