import React, {useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Box, Typography, CircularProgress, Skeleton } from '@mui/material';
import PlayersListTable from '../../components/PlayersListTable.js';
import FieldVeritcalHigh from '../../components/FieldVerticalHigh';
import formazioneByModulo from '../../components/Utility/formazioneByModulo';

const Formazione = props => {
    const {players,league,modulo,field} = props;
    const theme = useTheme();
    const user = useStoreState(state => state.user.user);    
    const userLogged = user == null ? false : user !== null && user.logged && user.token !== '';
    const calculating = useStoreState(state => state.top11.calculating);
    const setCalculating = useStoreActions(dispatch => dispatch.top11.setCalculating); 
    const {t, i18n} = useTranslation('common');    
    const [formazione, setFormazione] = useState([]);
    const [formazioneValue, setFormazioneValue] = useState(0);    
    const [loading, setLoading] = useState(calculating.filter(calc => calc.id == league.id).calculating);

    useEffect(() => {        
        const playersList = league.id == 0 ? players : players.filter(player => player.tournament_id == league.id);
        const formazione = formazioneByModulo(playersList,modulo);
        const valoreFormazione = formazione.reduce((a,v) => a = a + parseFloat(v.player_value,10),0);
        setFormazione(formazione);
        setFormazioneValue(valoreFormazione.toFixed(2));
        setCalculating({id:league.id,calculating:false});
        setLoading(false);
    },[]);

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{width:'100%'}}
        >
            {loading && (
                <Box sx={{width:'70%',textAlign:'center'}}>
                    <CircularProgress size={130} sx={{color:theme.palette.primary.main}} />
                </Box>
            )}
            
            {/* Nome lega e valore */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                sx={{width:'100%',padding:'5px 10px'}}
            >
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'20px',md:'20px',sm:'18px'},
                            color:theme.palette.primary.main,
                            fontWeight:'600'
                        }}
                    >
                        {league.name}
                    </Typography>
                    <Typography
                        variant='titleSection'
                        sx={{
                            color:theme.palette.custom.black,
                            fontSize:'16px !important',
                            fontWeight:'500'      
                        }}
                    >
                        {league.region}
                    </Typography>
                </Stack>
                {/* Valore */}
                <Stack direction="column" justifyContent="flex-end" alignItems="flex-end" spacing={0.5}>
                    {userLogged && (
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'20px',md:'20px',sm:'18px'},
                            color:theme.palette.custom.black,
                            fontWeight:'600'
                        }}
                    >
                        {loading ? "-" : formazioneValue}
                    </Typography>
                    )}
                    {!userLogged && (
                        <Skeleton variant='text' width="100%" height="20px" animation="wave" />
                    )}
                    <Typography
                        variant='titleSection'
                        sx={{
                            color:theme.palette.custom.black,
                            fontSize:'16px !important',
                            fontWeight:'500'      
                        }}
                    >
                        Mio €
                    </Typography>
                </Stack>                
            </Stack>
            
            {/* Utente non loggato */}
            {!userLogged && <Skeleton variant='rectangular' width="90%" height="600px" animation="wave" sx={{marginLeft:'5% !important'}} />}

            {/* Campo */}
            {!field && userLogged && <FieldVeritcalHigh players={formazione} modulo={modulo} />}

            {/* Distinta */}
            {field && userLogged && <PlayersListTable players={formazione} viewTeam={true} viewRole={false} />}
        </Stack>
    );
};

export default Formazione;