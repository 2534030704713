import React, {useState} from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Flags from 'country-flag-icons/react/3x2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import './styles.css';

const Nation = props => {
    const {naz} = props;
    const {t, i18n} = useTranslation('common');
    const Flag = Flags[naz.toUpperCase().substring(0,2)] ? 
        Flags[naz.toUpperCase().substring(0,2)] : null; 

    return(        
        <Stack
            direction="row"
            spacing={0.5}
            justifyContent="flex-start"
            alignItems="center"
        >
            {naz && (<Flag className="flagNation" />)}
            <Typography 
                variant='textBase'
                sx={{
                    fontSize:{xs:'18px',md:'20px',sm:'20px'},
                    color:'#000',
                    fontWeight:'600'
                }}
            >
                {naz ? t("nations." + naz) : '--'}
            </Typography>
        </Stack>
    );    
};


const PlayerInfo = props => {
    const {player} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const [values,setValues] = useState(null);

    return(
        <Stack
            direction={{xs:'row',md:'row',sm:'row'}}
            justifyContent="space-around"
            alignItems="center"
            spacing={{xs:0,md:0,sm:0}}
            sx={{width:{xs:'100%',md:'100%',sm:'100%'}}}
        >
            <Stack 
                direction="row"
                justifyContent={{xs:'flex-end',md:'flex-start',sm:'flex-start'}}
                alignItems="center"
                spacing={{xs:6,md:0,sm:5}}
                sx={{width:'100%'}}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}
                    sx={{width:'100%'}}
                >
                    <Nation naz={(player.region_code).substring(0,2).toUpperCase()} />
                    <Typography variant='textBase' sx={{fontSize:{xs:'14px',md:'14px',sm:'14px'}}}>
                        {t("global.nationality")}
                    </Typography>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}   
                    sx={{width:'30%',margin:'0 !important'}}
                >
                    <Typography 
                        variant='textBase' 
                        sx={{
                            fontSize:{xs:'18px',md:'20px',sm:'20px'},
                            fontWeight:'600'
                        }}
                    >
                        {player.age == '0' ? '--' : player.age}
                    </Typography>
                    <Typography variant='textBase' sx={{fontSize:{xs:'14px',md:'14px',sm:'14px'}}}>
                        {t("global.age")}
                    </Typography>
                </Stack>
            </Stack>
            <Stack 
                direction="row"
                justifyContent={{xs:'flex-end',md:'flex-start',sm:'flex-start'}}
                alignItems="flex-start"
                spacing={{xs:7,md:10,sm:8}}
                sx={{width:'100%'}}
            >
                <Stack
                    direction="column"
                    justifyContent="centert"
                    alignItems="center"
                    spacing={0.5}       
                    sx={{width:'50%'}}
                >
                    <Typography 
                        variant='textBase' 
                        sx={{
                            fontSize:{xs:'18px',md:'20px',sm:'20px'},
                            fontWeight:'600'
                        }}
                    >
                        {player.height == '0' ? '--' : player.height + " cm"}
                    </Typography>
                    <Typography variant='textBase' sx={{fontSize:{xs:'14px',md:'14px',sm:'14px'}}}>
                        {t("global.height")}
                    </Typography>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.5}       
                    sx={{width:'50%',margin:'0 !important'}}
                >
                    <Typography 
                        variant='textBase' 
                        sx={{
                            fontSize:{xs:'18px',md:'20px',sm:'20px'},
                            fontWeight:'600'
                        }}
                    >
                        {player.weight == '0' ? " --" : player.weight + " kg"}
                    </Typography>
                    <Typography variant='textBase' sx={{fontSize:{xs:'14px',md:'14px',sm:'14px'}}}>
                        {t("global.weight")}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default PlayerInfo;