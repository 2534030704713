import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { FormControl,InputLabel,MenuItem,Chip,Select,OutlinedInput,Box,Stack,Button} from '@mui/material';

const SelectNation = props => {
    const {listNations} = props;
    const theme = useTheme(); 
    const {t, i18n} = useTranslation('common');
    const selectedNation = useStoreState(state => state.players.findPlayer.selectedNation);
    const setSelectedNation = useStoreActions(dispatch => dispatch.players.findPlayer.setSelectedNation);

    const handleChangeNation = (event) => {
        const {target: {value}} = event;
        setSelectedNation(typeof value === 'string' && value != '' ? value.split(',') : value);
    };

    return (
        <Stack 
            direction="column" 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={1}
            sx={{padding:'15px 0 0 0'}}
        >
            <FormControl 
                sx={{
                    width:{xs:300,md:300,sm:220,lg:300},
                    "& .MuiFormLabel-root": {
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        color: theme.palette.custom.blueIcon + " !important"
                    },
                    "& .css-86q9nw-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                        fontFamily: ['"Poppins", sans-serif'].join(","),
                        borderColor: theme.palette.custom.blueIcon + " !important"
                    }
                }}
            >
                <InputLabel id="players-find-nation">{t("global.nationality")}</InputLabel>
                <Select
                    labelId='players-find-nation'
                    id='players-find-select-nation'                                       
                    value={selectedNation}
                    onChange={handleChangeNation}
                    input={<OutlinedInput id="select-find-nation" label={t("global.nationality")} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip 
                                key={value} 
                                label={t("nations." + value.toUpperCase())} 
                                sx={{
                                    fontFamily: ['"Poppins", sans-serif'].join(","),
                                    background:'none',
                                    color:theme.palette.custom.blueIcon,
                                    fontSize:'16px'
                                }} 
                            />
                        ))}
                        </Box>
                    )} 
                >
                    <MenuItem key={0} value={''} sx={{fontFamily: ['"Poppins", sans-serif'].join(","),fontStyle:'italic'}}>
                        {t('global.tutte')}
                    </MenuItem>
                    {listNations.map((nation) => (
                        <MenuItem key={nation} value={nation} sx={{fontFamily: ['"Poppins", sans-serif'].join(",")}}>
                            {t("nations." + nation.toUpperCase())}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
};

export default SelectNation;