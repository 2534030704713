import React from 'react';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { Stack, Typography, Box } from '@mui/material';

const CookiePolicy = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');

    return(
        <Stack 
            direction="column" 
            justifyContent="flex-start" 
            alignItems="flex-start" 
            spacing={2}
            sx={{padding:{xs:'20px 20px',md:'20px 150px',sm:'20px 40px'}}}
        >
            {[1,2,3,4,5].map((index) => 
                <Stack 
                    key={index}
                    direction="column" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={1}
                    sx={{marginTop:'20px !important'}}
                >
                    <Typography variant='pageTitle'>{parse(t("cookiepolicy.title" +  index))}</Typography>
                    {index !== 3 && index !== 4 && (
                        <Typography variant='text'>{parse(t("cookiepolicy.sezione" + index))}</Typography>
                    )}
                    {index === 3 && (<div className='cky-audit-table-element'></div>)}
                    {index === 4 && (
                        <a 
                            className='cky-banner-element' 
                            style={{
                                color:theme.palette.primary.main,
                                cursor:'pointer',
                                fontSize:'18px'
                            }}
                        >
                            Cookie Settings
                        </a>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default CookiePolicy;