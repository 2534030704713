import { useEffect } from "react";
import { useStoreState } from 'easy-peasy';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import createAuthState from "../../components/Utility/createAuthState";
import { Stack, CircularProgress } from "@mui/material";

const Login = props => {
    const theme = useTheme();
    const user = useStoreState(state => state.user.user);    
    const [searchParams, setSearchParams] = useSearchParams();
    const screen_hint = searchParams.get('screen_hint');
    //Parametro per indirizzare sulla registrazione (se non c'è indirizza al login)
    const screen_hint_param = screen_hint && screen_hint !== '' ? '&screen_hint=signup' : '';
    const domain = process.env.REACT_APP_AUTH_DOMAIN;
    const client_id = process.env.REACT_APP_AUTH_CLIENT_ID;
    const redirect_uri = process.env.REACT_APP_PUBLIC_URL + "/authorize";    
    const redirect_user_logged = process.env.REACT_APP_PUBLIC_URL + '/acount';
    const scope = "openid profile email offline_access";
    const audience = "http://playerstar/";

    useEffect(() => {

        //Se ho completato la procedura di registrazione dell'utente redirigo l'utente
        if(user.emailVerified && user.logged && user.token !== null){      
            window.location.href = redirect_user_logged;
        }
        else{
            window.localStorage.clear();
            const state = createAuthState();
            window.localStorage.setItem("state",state);
            const loginUrl = `${domain}/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&audience=${audience}&state=${state}` + screen_hint_param;

            window.location.href = loginUrl;
        }            
    },[]);

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            sx={{width:'100%',minHeight:'100vh'}}
        >
            <CircularProgress sx={{color:theme.palette.primary.main}} size="80px" />
        </Stack>
    );
};

export default Login;