import store from "../../store";
import utils from "../utils";

const refreshToken = async () => {
    const state = store.getState();
	const refreshToken = state?.user?.user?.refreshToken;
console.log("REFRESH DEL TOKEN CON ",refreshToken);
    if(refreshToken){
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        const urlencoded = new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,     
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,   
            refresh_token: refreshToken
        });
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: urlencoded.toString(),
            redirect: 'follow'
        };
        const tokenUrl = process.env.REACT_APP_AUTH_DOMAIN + "/oauth/token";
        const response = await fetch(tokenUrl, requestOptions);
        if (response.status < 200 || response.status > 300) {
console.log("RESPONSE REFRESH",response);
            return Promise.reject(utils.createError(response));
        }
        return await response.json();
    }    
    else {
		throw new Error('Refresh token not found in local storage');
	}
};

export default refreshToken;