import utils from "../utils";

export default _ => {
    const requestOptions = {
        method: 'GET',
        headers: utils.createHeaders()
    };
    
    return utils.fetch(`${process.env.REACT_APP_API_ENDPOINT}/public/team/all`, requestOptions)
        .then(response => response.json());    
};

