import React, {useEffect, useState} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme, styled } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import { CircularProgress, Popover } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import BarChartIcon from '@mui/icons-material/BarChart';
import Typography from '@mui/material/Typography';
import MessageLogin from '../MessageLogin';
import PopoverDesc from '../PopoverDesc';

const PlayerStatistics = props => {
    const {userLogged, ruolo} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const loadingOverview = useStoreState(state => state.players.getPlayerOverview.loadingOverview);
    const player = useStoreState(state => state.players.getPlayerOverview.playerOverview);
    const playerInfoStats = useStoreState(state => state.players.getPlayer.playerInfoStats);
    const playerInfoBase = useStoreState(state => state.players.getPlayer.playerInfoBase);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const rows = [
        {"parameter":t("global.apps"),"value":userLogged && playerInfoBase ? playerInfoBase.apps : 0},
        {"parameter":t("global.minsplayed"),"value":userLogged && player ? player.mins_played : 0},
        {
            "parameter":t("global.gameplayedaverage"),
            "value":userLogged && player ? parseFloat((player.mins_played / 90)).toFixed(1) + " / " + (player.team_mins_played / 90) : 0
        },
        {"parameter":t("global.goaltotal"),"value":userLogged && player ? player.goal_total : 0},
        {"parameter":t("global.shotsmaked"),"value":userLogged && player ? player.shots_total : 0},
        {"parameter":t("global.assist"),"value":userLogged && player ? player.assist : 0},
        {"parameter":t("global.keypasses"),"value":userLogged && player  ? player.key_passes_total : 0},
        {
            "parameter":t("global.tackle") + " (" + t("global.wons") + " / " + t("global.total") + ")",
            "value":userLogged && player ? player.tackle_won_total + " / " + player.tackle_total_attempted : 0
        },
        {"parameter":t("coefficients.admonitions"),"value":userLogged && player ? player.yellow_card : 0},
        {"parameter":t("coefficients.expulsions"),"value":userLogged && player ? player.red_card : 0}
    ];

    return(
        <Box sx={{width:'100%'}}>
            <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="center">
                <BarChartIcon sx={{color:theme.palette.primary.main,fontSize:'30px'}} />
                <Box>
                    <Typography 
                        variant='titleSection'
                        sx={{
                            fontSize:{xs:'22px',md:'22px',sm:'22px'},
                            color:theme.palette.custom.black,
                            fontWeight:'600'                   
                        }}
                    >
                        {t("global.statisticsplayer")}
                    </Typography>                        
                </Box>
            </Stack>
            <Box sx={{width:'100%'}}>
                <Button 
                    variant='textLink'
                    onClick={handleClick}
                    sx={{fontSize:{xs:'15px',md:'15px',sm:'15px'}}}
                >
                    {parse(t("global.statisticplayerswhat"))}
                </Button>
                <PopoverDesc 
                    open={open} 
                    anchorEl={anchorEl} 
                    handleClose={handleClose} 
                    icon={<BarChartIcon sx={{fontSize:'30px !important'}} />}
                    title={"statisticsplayer"}
                    what={"statisticplayerswhat"}
                    desc={"statisticsplayerdesc"}
                />
            </Box>
            {loadingOverview && (
                <Box sx={{padding:'10px auto',textAlign:'center'}}>
                    <CircularProgress sx={{color:theme.palette.primary.main}} />
                </Box>
            )}
            {!userLogged && (
                <MessageLogin data={t("messages.values")} color={theme.palette.custom.black} />
            )}
            {!loadingOverview && userLogged && (
                <Stack 
                    direction="column" 
                    justifyContent="fles-start" 
                    alignItems="fles-start"
                    sx={{
                        marginTop:'10px',
                        fontFamily: ['"Montserrat Alternates",sans-serif'].join(','),
                        fontSize:'14px',
                        width:'100%'  
                    }}
                >
                    {rows.map((row,index) => (
                        <Stack 
                            direction="row" 
                            justifyContent="flex-start" 
                            alignItems="center"
                            key={index}
                            sx={{
                                borderBottom:'1px solid #ddd',
                                padding:'7px 0',
                                fontWeight:'600',
                                background:index % 2 === 0 ? theme.palette.custom.white : theme.palette.custom.water
                            }}
                        >
                            <Box
                                sx={{
                                    width:'75%',
                                    paddingLeft:'5px',
                                    fontSize:{xs:'17px',md:'16px',sm:'17px'},
                                    '&::first-letter':{
                                        textTransform:'uppercase'
                                    }
                                }}
                            >
                                {row.parameter}
                            </Box>
                            <Box
                                sx={{
                                    width:'25%',
                                    color: index == 0 ? theme.palette.custom.black :
                                        row.value > 0 ? theme.palette.custom.green :
                                        row.value < 0 ? theme.palette.custom.red : theme.palette.custom.black,
                                    textAlign:'right',
                                    fontSize:{xs:'17px',md:'16px',sm:'17px'},
                                    paddingRight:'5px'                                                                   
                                }}
                            >
                                {row.value}
                            </Box>
                        </Stack>
                    ))}
                </Stack>            
            )}
        </Box>
    );
};

export default PlayerStatistics;