const filterByRoles = (players,roles) => {
    //Se non ci sono filtri per ruolo restituisco la lista non filtrata
    if(!roles || roles.length === 5)
        return players;
    
    //Se ci sono filtri per ruolo filtro la lista
    const result = players.filter(player => 
        (roles.includes('Goalkeeper') && (player.position_text === 'Goalkeeper' || player.played_positions.includes('-GK-'))) ||
        (roles.includes('Defender') && (
            player.position_text === 'Defender' && ( 
            player.played_positions.includes('-DR-') || player.played_positions.includes('-MR-') ||
            player.played_positions.includes('-DC-') || player.played_positions.includes('-Mc-') ||
            player.played_positions.includes('-DL-') || player.played_positions.includes('-ML-'))
        )) ||
        (roles.includes('Midfielder') && (
            player.position_text !== 'Defender' && ( 
            player.played_positions.includes('-DR-') || player.played_positions.includes('-MR-') ||
            player.played_positions.includes('-DC-') || player.played_positions.includes('-Mc-') ||
            player.played_positions.includes('-DL-') || player.played_positions.includes('-ML-') || 
            player.played_positions.includes('-DMC-'))
        )) ||
        (roles.includes('Attackingmidfielder') && (
            player.played_positions.includes('-AMR-') || player.played_positions.includes('-AMC-') ||
            player.played_positions.includes('-AML-'))
        ) ||
        (roles.includes('Forward') && (player.position_text === 'Forward' && (
            (
                player.played_positions.includes('-FW-') && 
                !player.played_positions.includes('-AMR-') &&
                !player.played_positions.includes('-AMC-') &&
                !player.played_positions.includes('-AML-')
            ) || player.played_positions === '')
        ))
    );

    return result;
};

export default filterByRoles;