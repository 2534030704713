import { action, thunk, actionOn } from 'easy-peasy';
import utils from '../utils';
const redirectUrl = process.env.REACT_APP_PUBLIC_URL + "/players";

export default {
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    loadingStats: false,
    setLoadingStats: action((state, payload) => {
        state.loadingStats = payload;
    }),
    loadingTrand: false,
    setLoadingTrand: action((state, payload) => {
        state.loadingTrand = payload;
    }),
    player: null,
    setPlayer: action((state, payload) => {
        state.player = payload;
    }),
    playerInfoBase: null,
    setPlayerInfoBase: action((state, payload) => {    
        state.playerInfoBase = null;
        //Se il giocatore non è più attivo redirigo alla pagina dei giocatori
        if(payload.length == 0)
            window.location.replace(redirectUrl);

        payload.map((player) => {
            state.playerInfoBase = utils.decryptResponse(player);
        });        
    }), 
    playerInfoStats: null,
    setPlayerInfoStats: action((state, payload) => {
        state.playerInfoStats = null;
        if(!payload)
            return;
        payload.map((player) => {
            state.playerInfoStats = utils.decryptResponse(player);
        });
    }),
    playerDetailStats: null,
    setPlayerDetailStats: action((state, payload) => {
        state.playerDetailStats = null;
        if(!payload)
            return;
        payload.map((player) => {
            state.playerDetailStats = utils.decryptResponse(player);
        });
    }),
    playerTrand: null,
    setPlayerTrand: action((state, payload) => {
        state.playerTrand = null;
        if(!payload || payload.length == 0)
            return;
        state.playerTrand = payload[0];  
    }),
    retrievePlayer: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;

        services.players.getPlayer(payload)
            .then(data => {
                actions.setPlayer(data);
            })
            .catch(e => {
                console.log("ERRORE ", e);
            })
            .finally(_ => {
                
            });
    }),
    retrievePlayerBaseInfo: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoading(true);        
        services.players.getPlayerInfoBase(payload)
            .then(data => {                
                actions.setPlayerInfoBase(data);
            })
            .catch(e => {
                actions.setPlayerInfoBase(null);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    retrievePlayerStatsInfo: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoading(true);
        services.players.getPlayerStats(payload)
            .then(data => {                
                actions.setPlayerInfoStats(data.players);
            })
            .catch(e => {
                actions.setPlayerInfoStats(null);
            })
            .finally(_ => {
                actions.setLoading(false);
            });
    }),
    retrievePlayerDetailStats: thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoadingStats(true);
        services.players.getPlayerDetailStats(payload)
            .then(data => {       
                actions.setPlayerDetailStats(data);
            })
            .catch(e => {
                actions.setPlayerDetailStats(null);
            })
            .finally(_ => {
                actions.setLoadingStats(false);
            });
    }),
    retrievePlayerTrand:  thunk((actions, payload, { getStoreState, injections, getStoreActions }) => {
        const { services } = injections;
        actions.setLoadingTrand(true);
        services.players.getPlayerTrand(payload)
            .then(data => {
                actions.setPlayerTrand(data);
            })
            .catch(e => {
                actions.setPlayerTrand(null);
            })
            .finally(_ => {
                actions.setLoadingTrand(false);
            });
    })
};