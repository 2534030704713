import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import parse from 'html-react-parser';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Stack, Typography, Avatar, Button, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LogoutIcon from '@mui/icons-material/Logout';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const UserBox = props => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const user = useStoreState(state => state.user.user);
    const userSignUp = user.email != '' && user.token !== '';
    const userLogged = user.logged && user.token !== '';
    const userVerified = user.email != '' && user.emailVerified;    
    const profile_uri = userVerified && userLogged ? process.env.REACT_APP_PUBLIC_URL + '/account' :
        process.env.REACT_APP_PUBLIC_URL + '/emailverify?success=&code=';    
    const login_url = process.env.REACT_APP_PUBLIC_URL + '/login';
    const redirect_logout_uri = process.env.REACT_APP_PUBLIC_URL + '/logout';
    const [checked, setChecked] = useState(false);
    const [errorPrivacy, setErrorPrivacy] = useState('');

    const handleChecked = (event) => {
        setErrorPrivacy('');
        setChecked(!checked);
    };

    const handleSignIn = (event) => {
        if(checked){
            //Invio alla pagina di login col parametro per indirizzare sulla registrazione
            window.location.href = login_url + '?screen_hint=signup';
        }
        else{
            setErrorPrivacy(parse(t("formlogin.fieldrequired")));
        }
        
    };

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{width:'100%',padding:'10px 16px'}}
        >
            {(!userSignUp || !userLogged) && (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{textAlign:'center',marginBottom:'10px !important'}}
                >
                    <Typography 
                        variant='textSmall' 
                        sx={{
                            fontWeight:'600',
                            borderBottomWidth:'2px',
                            borderBottomStyle:'solid',
                            borderBottomColor:theme.palette.primary.main,
                            paddingBottom:'10px',
                            lineHeight:'1.3em !important',
                            width:'100%'
                        }}
                    >
                        {t('global.reservedArea')}                        
                    </Typography>
                    <Typography variant='textSmall' sx={{fontWeight:'600',fontSize:'15px'}}>{t('messages.notloggedtitle')}</Typography>
                    <Typography variant='textSmall' sx={{fontSize:'15px'}}>
                        {parse(t('messages.notloggedsubTitle'))}
                    </Typography>                    
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        sx={{
                            marginBottom:'8px !important',
                            borderBottomWidth:'1px',
                            borderBottomStyle:'solid',
                            borderBottomColor:theme.palette.primary.main,
                            paddingBottom:'13px'
                        }}
                    >
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="formsignin-privacy"
                                        aria-describedby="formsignin-privacy-error"
                                        checked={checked}
                                        onChange={handleChecked}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={<TaskAltIcon sx={{color:`${theme.palette.custom.green}`}} />}
                                    />
                                }
                                label={parse(t("formlogin.privacy"))}
                                variant='formValidate'
                            />
                            <FormHelperText 
                                id="formsignin-privacy-error"
                                variant='formValidate'
                                sx={{marginTop:'-10px',textAlign:'left'}}
                            >
                                {errorPrivacy}
                            </FormHelperText>
                        </FormControl>
                        <Button variant='action' onClick={handleSignIn} sx={{marginTop:'0px'}}>
                            {t('formlogin.signin')}
                        </Button>                        
                    </Stack>
                    <Typography variant='textSmall' sx={{fontSize:'15px'}}>
                        {parse(t('messages.notloggedLogin').replaceAll('#',login_url))}
                    </Typography>
                </Stack>
            )}
            {userSignUp && userLogged && (
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    sx={{textAlign:'center',width:'100%'}}
                >
                    {/* Avatar e email */}
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            width:'100%',                            
                            borderBottomWidth:'1px',
                            borderBottomStyle:'solid',
                            borderBottomColor:theme.palette.custom.greyhight,
                            paddingBottom:'10px'
                        }}
                    >
                        {user.picture != '' && (
                            <Avatar src={user.picture} sx={{width:'32px',height:'32px'}} />
                        )}
                        {user.picture == '' && (
                        <Avatar 
                            sx={{backgroundColor:userLogged ? theme.palette.primary.main: '#bdbdbd'}}
                        >                            
                            <PersonIcon />
                        </Avatar>
                        )}
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0.5}
                        >
                            <Typography variant='textSmall' sx={{fontWeight:'600',color:theme.palette.custom.blackPlayer}}>
                                {user.email}
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0}
                            >
                                {userVerified && (
                                    <CheckCircleIcon sx={{fontSize:'16px',color:theme.palette.custom.green}} />                                    
                                )}
                                {!userVerified && (
                                    <CancelIcon sx={{fontSize:'16px',color:theme.palette.custom.red}} />                                    
                                )}
                                <Typography 
                                    variant='textSmall' 
                                    sx={{
                                        fontSize:'14px !important',
                                        color:userVerified ? theme.palette.custom.green : theme.palette.custom.red                                        
                                    }}
                                >
                                    {userVerified ? t('global.accountverified') : t('global.accountunverified')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>

                    {/* Verifica account / Profilo */}                    
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            width:'100%',                            
                            borderBottomWidth:userVerified ? '1px' : '0px',
                            borderBottomStyle:'solid',
                            borderBottomColor:theme.palette.custom.greyhight,
                            paddingBottom:'0px',
                            marginTop:'0px !important'                                                     
                        }}
                    >
                        <Button 
                            variant='secondary'
                            href={profile_uri} 
                            startIcon={<PersonIcon />}
                            sx={{
                                borderWidth:'0 !important',
                                color: theme.palette.custom.blackPlayer,
                                "&:hover":{
                                    backgroundColor: '#fff !important',                                    
                                    color: theme.palette.primary.main
                                }
                            }}
                        >
                            {userVerified ? t('global.profile') : t('formlogin.emailverifing')}
                        </Button> 
                    </Stack>       

                    {/* Logout */}
                    {userVerified && userLogged && (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                width:'100%',                           
                                paddingBottom:'0px',
                                marginTop:'0px !important'                                                     
                            }}
                        >
                            <Button 
                                variant='secondary'
                                href={redirect_logout_uri}
                                startIcon={<LogoutIcon />}
                                sx={{
                                    borderWidth:'0 !important',
                                    color: theme.palette.custom.blackPlayer,
                                    "&:hover":{
                                        backgroundColor: '#fff !important',                                    
                                        color: theme.palette.primary.main
                                    }
                                }}
                            >
                                Log Out
                            </Button> 
                        </Stack>   
                    )}            
                </Stack>
            )}
        </Stack>
    );
};

export default UserBox;