import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTheme } from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const HeaderPage = props => {
    const {bgImg, title} = props;
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const titlePage = parse(t(title));

    return(
        <Box sx={{textAlign:'center',marginTop:'10px !important'}}>
            <Typography 
                variant='titleSection' 
                sx={{color:theme.palette.custom.black}}
            >
                {titlePage}
            </Typography>
        </Box>
    );
};

export default HeaderPage;